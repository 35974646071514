import { main } from '../service/actionType'

const initialState = {
    couponList: {
        data: [],
        meta: {
            page: 1,
            totalPages:0,
        }
    },
    
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        
        case main.COUPON_FORM_CREATE:
            return {
                ...state,
                couponDetails: {
                    ...payload.formatedDetail,
                }
            }
        
        case main.COUPON_LIST:
            return {
                ...state,
                couponList: {
                    data: payload.data,
                    meta: payload.meta
                }
            }
            
        default:
            return state
    }
}