import React, { Component } from 'react'
import {  Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import ReactSelect from 'react-select';
import { bindActionCreators } from "redux";
import * as orders from "actions/orders";
import * as shops from "actions/shops";
import * as customers from 'actions/customer';

import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { Toast } from "helpers/Toast";
import { API, API_BOOK } from "service";
import Loader from 'components/loader/loader';
import BackBtn from "components/Tabs/backBtn"
import Topbar from 'components/topbar';
import CustomReactSelect from 'components/inputs/ReactSelect';

import { CustomDatePicker } from 'components/inputs/Datepicker';
import ButtonType1 from 'components/buttons/ButtonType1';
import { MdClose, MdRemove } from 'react-icons/md';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';
import { PAYMENT_METHODS, PAYMENT_STATUS } from 'helpers/variables';
import { getValidValue } from 'helpers/utils';

const defaultCustomerPayload = {
    name: '',
    mobileNumber: '',
    address: '',
    subLocality: ''
}

const defaultOrderDetail = {
    _id: "",
    name: "",
    mobileNumber: "",
    address: "",
    userLatitude: null,
    userLongitude: null,
    paymentMethod: null,
    paymentStatus: null,
    subLocality: "",
    orderAmount: 0,
    deliveryCharge: 0,
    shop_Id: "",
    createdAt: "",
    orderItems: []
}

class OrderDetailClass extends Component {
    state = {
        orderDetail: {
            _id: null,
            name: null,
            mobileNumber: null,
            address: null,
            userLatitude: null,
            userLongitude: null,
            subLocality: null,
            orderAmount: 0,
            deliveryCharge: 0,
            paymentMethod: null,
            paymentStatus: null,
            shop_Id: null,
            createdAt: null,
            orderItems: []
        },
        search: null,
        isEdit: false,
        errors: {},
        btnLoading: false,
        isLoading: false,
        categoryDropdown: [],
        productsDropdown: [],
        paymentMethodList: PAYMENT_METHODS,
        paymentStatusList: PAYMENT_STATUS
    }

    componentDidMount() {
        const { _id } = this.props.match.params
        this.initialize()
        this.props.shopAction.getShopsDropDownList()
        if (_id != undefined) {
            this.getOrder(_id)
        }
    }

    initialize = () => {
        let { orderDetail } = this.state
        orderDetail.orderItems.push({
            "_id": '',
            "description": '',
            "noOfItemsAdded": 0,
            "itemTotal": 0,
            "prName": '',
        })
        this.setState({ orderDetail })
    }

    searchCustomer = async () => {
        let { search, orderDetail } = this.state
        try {
            this.setState({ isSearchLoading: true, orderDetail: { ...orderDetail, ...defaultCustomerPayload } })
            let { SEARCH_CUSTOMER_API } = API_BOOK.MAIN_APP
            let requestPayload = {
                ...SEARCH_CUSTOMER_API,
                params: {
                    search: search
                }
            }
            let response = await API(requestPayload)
            if (response.status == 200) {
                const result = response.data.data
                const updatedOrderDetail = {
                    ...orderDetail,
                    user_Id: result._id,
                    name: result.name,
                    mobileNumber: result.mobileNumber,
                    address: result.addressDetail?.userAddress,
                    userLatitude: result.addressDetail?.userLatitude || 0,
                    userLongitude: result.addressDetail?.userLongitude || 0,
                    subLocality: result.addressDetail?.subLocality || ''
                }
                this.setState({
                    orderDetail: updatedOrderDetail,
                    isSearchLoading: false
                })
            } else {
                Toast({ type: 'error', message: response?.data?.message })
                this.setState({
                    isSearchLoading: false
                })
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
        }
    }

    getProductsDropdown = async (shop_Id) => {
        try {
            let { GET_PRODUCTS_DROPDOWN_API } = API_BOOK.MAIN_APP
            let requestPayload = {
                ...GET_PRODUCTS_DROPDOWN_API,
                params: {
                    shop_Id: shop_Id
                }
            }
            let response = await API(requestPayload)
            if (response.status == 200) {
                this.setState({
                    productsDropdown: response.data.data
                })
            } else {
                Toast({ type: 'error', message: response?.data?.message })
                this.setState({
                    productsDropdown: []
                })
            }
        } catch (error) {
            Toast("error", error?.data?.message)
            Toast({ type: 'error', message: error?.data?.message })
            this.setState({ productsDropdown: [] })
        }
    }

    getOrder = async (_id) => {
        try {
            this.setState({ isLoading: true, isEdit: true })
            const { GET_BOOKING_DETAIL_BY_ID_API } = API_BOOK.MAIN_APP
            const requestPayload = {
                ...GET_BOOKING_DETAIL_BY_ID_API,
                params: {
                    _id: _id
                }
            }
            const response = await API(requestPayload)
            if (response.status == 200) {
                let detail = response.data.data
                const updatedDetail = {
                    _id: detail._id,
                    name: detail?.customerName,
                    mobileNumber: detail?.customerMobileNumber,
                    address: detail?.addressDetail?.address,
                    subLocality: detail?.addressDetail?.subLocality,
                    orderItems: detail?.orderDetails,
                    comments: detail?.comments,
                    deliveryCharge: detail?.deliveryCharge,
                    createdAt: new Date(detail?.createdAt),
                    orderAmount: detail?.orderAmount,
                    shop_Id: { label: detail?.shopDetail?.shopName, value: detail?.shopDetail?.shop_Id },
                    paymentMethod: PAYMENT_METHODS.find(pm => pm.value === detail.paymentMethod) || null,
                    paymentStatus: PAYMENT_STATUS.find(pm => pm.value === detail.paymentStatus) || null
                }
                this.setState({ orderDetail: updatedDetail, isLoading: false }, () => this.getProductsDropdown(detail?.shopDetail?.shop_Id))
            } else {
                Toast("error", response?.data?.message)
                this.setState({ isLoading: false })
            }
        } catch (error) {
            Toast("error", error?.data?.message)
            this.setState({ isLoading: false })
        }
    }

    onChange = (name, value) => {
        let { orderDetail, errors } = this.state
        orderDetail[name] = value
        errors[name] = undefined
        this.setState({ orderDetail: orderDetail, errors: errors }, () => this.checkForApiTrigger(name, value))
    };

    onChangeProductDetail = (name, value, index) => {
        let { orderDetail } = this.state
        orderDetail.orderItems[index][name] = value
        let item = orderDetail.orderItems[index]
        if (name == 'noOfItemsAdded') {
            item["itemTotal"] = parseFloat(item?.productPrice) * parseFloat(value)
            const totals = this.getTotals(orderDetail.orderItems)
            orderDetail["orderAmount"] = totals.orderAmount
        
        } else if(name === "prName"){
            item["_id"] = value.value
            item["productQuantity"] = value.productQuantity
            item["quantityType"] = value.quantityType
            item["productPrice"] = value.productPrice
        }
        this.setState({ orderDetail }, () => this.checkForApiTrigger(name, value))
    }

    checkForApiTrigger = (name, value) => {
        if (name === "shop_Id") {
            this.getProductsDropdown(value?.value)
            this.resetProductDetails()
        }
    }
    resetProductDetails = () => {
        let { orderDetail } = this.state
        orderDetail.orderItems = [
            {
                "_id": '',
                "noOfItemsAdded": 0,
                "itemTotal": 0,
                "prName": '',
            }
        ]
        this.setState({ orderDetail })
    }
    onChangeSearch = (name, value) => {
        this.setState({ search: value })
    };

    onSubmit = async () => {
        try {
            const { isEdit, orderDetail } = this.state

            let validation = {
                ...inputValidations
            }
            const notValid = validate(orderDetail, validation);
            if (notValid) {
                this.setState({
                    errors: notValid
                })
            } else {
                this.setState({
                    btnLoading: true
                })
                let payload = {
                    shop_Id: orderDetail?.shop_Id?.value,
                    deliveryCharge: orderDetail.deliveryCharge,
                    orderItems: orderDetail.orderItems.map(item => ({
                        _id: item.prName.value,
                        itemTotal: item.itemTotal,
                        noOfItemsAdded: parseFloat(item.noOfItemsAdded)
                    })),
                    deliveryAddress: {
                        userAddress: orderDetail.address,
                        userLatitude: orderDetail.userLatitude,
                        userLongitude: orderDetail.userLongitude
                    },
                    user_Id: orderDetail.user_Id,
                    comments: orderDetail.comments,
                    createdAt: orderDetail.createdAt,
                    paymentMethod: getValidValue(orderDetail?.paymentMethod, 'select'),
                    paymentStatus: getValidValue(orderDetail?.paymentStatus, 'select')
                }
                let response;
                if (isEdit) {
                    payload["_id"] = orderDetail._id
                    response = await this.props.ordersAction.editOrder(payload)
                } else {
                    response = await this.props.ordersAction.createOrder(payload)
                }
                if (response.status === 200) {
                    this.setState({
                        btnLoading: false,
                        orderDetail: { ...defaultOrderDetail }
                    })
                    Toast({ type: 'success', message: response?.data?.message })
                } else {
                    Toast({ type: 'error', message: response?.data?.message })
                    this.setState({
                        btnLoading: false
                    })
                }

            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
            this.setState({
                btnLoading: false
            })
        }
    }

    removeRow = (index) => {
        let { orderDetail } = this.state
        orderDetail.orderItems.splice(index, 1)
        const totals = this.getTotals(orderDetail.orderItems)
        orderDetail["orderAmount"] = totals.orderAmount
        this.setState({ orderDetail })
    }

    getTotals = (items) => {
        const totalData = items.reduce((accumulator, cv) => ({orderAmount : accumulator.orderAmount + parseFloat(cv.itemTotal), totalNoOfItems: accumulator.totalNoOfItems + parseFloat(cv.quantity) }), {orderAmount: 0, totalNoOfItems: 0})
        return totalData
    }

    render() {
        const { orderDetail, errors, search, isLoading, isEdit, isSearchLoading, productsDropdown,btnLoading, paymentMethodList, paymentStatusList } = this.state
        const { shops } = this.props
        return (
            <>
                <Topbar
                    pageName={`${isEdit ? 'Edit' : 'Create'} Order`}
                    showBtn={false}
                />
                {isLoading
                    ?
                    <Loader className="h-100" />
                    :
                    <>
                        <Row className="no-gutters bg-white mt-2">
                            <Col className="col-12 p-3">
                                {!isEdit ?
                                    <Row className='align-items-center'>
                                        <Col className="col-lg-4 col-7">
                                            <FormInput
                                                type="text"
                                                label={``}
                                                name="search"
                                                placeholder="Search by customer mobile number"
                                                onChange={this.onChangeSearch}
                                                value={search}
                                                disabled={false}
                                            />
                                        </Col>
                                        <Col className="col-lg-2 col-5 pt-2">
                                            <ButtonType1
                                                onClick={() => this.searchCustomer()}
                                                btnName="Search"
                                                btnClassName="apply_filter"
                                                isLoading={isSearchLoading}
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                                }
                                <Row className='pt-2'>
                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="text"
                                            label={`Customer Name`}
                                            name="name"
                                            onChange={this.onChange}
                                            value={orderDetail["name"]}
                                            error={errors["name"]}
                                            disabled={true}
                                        />
                                    </Col>

                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="text"
                                            label={`Customer Mobile Number`}
                                            name="mobileNumber"
                                            onChange={this.onChange}
                                            value={orderDetail["mobileNumber"]}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="text"
                                            label={`Sub Locality`}
                                            name="subLocality"
                                            onChange={this.onChange}
                                            value={orderDetail["subLocality"]}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="textarea"
                                            label={`Customer Address`}
                                            name="address"
                                            onChange={this.onChange}
                                            value={orderDetail["address"]}
                                            error={errors["address"]}
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col className="col-lg-3 col-12 custom-reselect">
                                        <CustomReactSelect
                                            onChange={(...params) => this.onChange(...params)}
                                            options={shops?.shopsDropdownList}
                                            name={"shop_Id"}
                                            label="Select Shop"
                                            value={orderDetail["shop_Id"]}
                                            error={errors['shop_Id']}
                                            isMulti={false}
                                            isClearable={false}
                                            placeholder={"Select Shop"}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12">
                                        <CustomDatePicker
                                            name="createdAt"
                                            label="Choose Date"
                                            placeholder="Choose Date"
                                            onChange={(...params) => this.onChange(...params)}
                                            value={orderDetail["createdAt"]}
                                            error={errors["createdAt"]}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="text"
                                            label={`Delivery Charge`}
                                            name="deliveryCharge"
                                            onChange={this.onChange}
                                            value={orderDetail["deliveryCharge"]}
                                            error={errors["deliveryCharge"]}
                                            disabled={false}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12">
                                        <FormInput
                                            type="textarea"
                                            label={`Comments`}
                                            name="comments"
                                            onChange={this.onChange}
                                            value={orderDetail["comments"]}
                                            error={errors["comments"]}
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col className='col-12'>
                                        <Row className='align-items-center'>
                                            <Col>
                                                <h4>PRODUCT DETAILS</h4>
                                            </Col>
                                            <Col className='col-lg-2 col-md-2 '>
                                                <ButtonType1
                                                    onClick={() => this.initialize()}
                                                    btnName="Add Row"
                                                    btnClassName="apply_filter"
                                                    isLoading={false}
                                                />
                                            </Col>
                                        </Row>
                                        <table className="pos_invoice_table mt-2">
                                            <thead>
                                                <tr className="invoice_header">
                                                    <th>S.No.</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity & Product Price</th>
                                                    <th>No Of Items</th>
                                                    <th>Item Total</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderDetail.orderItems.map((inv, i) => (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                <div className="pos_invoice_react_select">
                                                                    <ReactSelect
                                                                        name="prName"
                                                                        onChange={(val) => this.onChangeProductDetail("prName", val, i)}
                                                                        value={inv.prName}
                                                                        options={productsDropdown}
                                                                        classNamePrefix="pos_invoice_selection"
                                                                        menuPlacement="auto"
                                                                        menuPosition="fixed"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p>{inv?.productQuantity} {inv?.quantityType} - {inv?.productPrice}</p>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    name="noOfItemsAdded"
                                                                    type='number'
                                                                    value={inv['noOfItemsAdded']}
                                                                    min={0}
                                                                    onChange={(e) => this.onChangeProductDetail("noOfItemsAdded", e.target.value, i)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <p>{inv.itemTotal ? `Rs. ${inv.itemTotal}` : ''}</p>
                                                            </td>
                                                            <td className='text-center'>
                                                                {i > 0
                                                                    ?
                                                                    <MdClose
                                                                        size={20}
                                                                        className='clear_filter br-10'
                                                                        color='#fff'
                                                                        onClick={() => this.removeRow(i)}
                                                                    />
                                                                    :
                                                                    <>-</>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row className='mt-5'>
                                    <Col className="col-lg-3 col-12 custom-reselect">
                                        <CustomReactSelect
                                            onChange={(...params) => this.onChange(...params)}
                                            options={paymentMethodList}
                                            name={"paymentMethod"}
                                            label="Select Payment Method"
                                            value={orderDetail["paymentMethod"]}
                                            isMulti={false}
                                            isClearable={false}
                                            placeholder={"Select Payment Method"}
                                        />
                                    </Col>
                                    <Col className="col-lg-3 col-12 custom-reselect">
                                        <CustomReactSelect
                                            onChange={(...params) => this.onChange(...params)}
                                            options={paymentStatusList}
                                            name={"paymentStatus"}
                                            label="Select Payment Status"
                                            value={orderDetail["paymentStatus"]}
                                            isMulti={false}
                                            isClearable={false}
                                            placeholder={"Select Payment Status"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className='order_detail_total_container'>
                            <div className="pos_invoice_total_section">
                                <div className="pos_invoice_total_inner">
                                    <p className="key">Sub Total  :</p>
                                    <p className="value">Rs. {orderDetail.orderAmount}</p>
                                </div>
                                <div className="pos_invoice_total_inner">
                                    <p className="key">Delivery Charge :</p>
                                    <p className="value">Rs. {orderDetail.deliveryCharge}</p>
                                </div>
                                <div className="pos_invoice_total_inner">
                                    <p className="key">Total  :</p>
                                    <p className="value">Rs. {parseFloat(orderDetail.orderAmount) + parseFloat(orderDetail.deliveryCharge)}</p>
                                </div>
                                <div className='d-flex justify-flex-end cg-10 mt-3'>
                                    <div
                                        className={`buttonType1 cancel_btn mn-130`}
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        <span className="ml-2">Cancel</span>
                                    </div>
                                    {btnLoading
                                        ?
                                        <div className={`buttonType1 mn-130 apply_filter`}>
                                            <Spinner
                                                size= "sm"
                                                color={"#ffffff"}
                                            />
                                        </div>
                                        :
                                        <div
                                            className={`buttonType1 apply_filter mn-130`}
                                            onClick={() => this.onSubmit()}
                                        >
                                            <span className="ml-2">Confirm</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </>
                }
            </>
        );
    }
}

const mapStateToProps = ({ customers, profile, loader, shops }) => {
    return {
        shops,
        customers,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerAction: bindActionCreators(customers, dispatch),
        ordersAction: bindActionCreators(orders, dispatch),
        shopAction: bindActionCreators(shops, dispatch)
    }
};

const OrderDetail = connect(mapStateToProps, mapDispatchToProps)(OrderDetailClass);

export { OrderDetail }


let inputValidations = {
    shop_Id: {
        presence: {
            allowEmpty: false,
            message: "^Shop is required"
        }
    },
    createdAt: {
        presence: {
            allowEmpty: false,
            message: "^Date is required"
        }
    },
    name: {
        presence: {
            allowEmpty: false,
            message: "^Customer Name is required"
        }
    },
    deliveryCharge: {
        presence: {
            allowEmpty: false,
            message: "^Delivery charge is required"
        }
    },
    address: {
        presence: {
            allowEmpty: false,
            message: "^Customer Address is required"
        }
    }
}