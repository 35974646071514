import React from 'react';
import { Spinner } from 'reactstrap';

const ButtonType1 = ({onClick, btnName, isLoading, btnClassName}) => {
    return (
        <>
            {isLoading 
                ?
                    <div className={`buttonType1 ${btnClassName}`}>
                        <Spinner 
                            size = "sm"
                            color={"#ffffff"}
                        />
                    </div>
                :
                    <div
                        className={`buttonType1 ${btnClassName}`}
                        onClick={() => onClick()}
                    >   
                        <span>{btnName}</span>
                    </div>
            }
        </>
     );
}

export default ButtonType1;