import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import * as deliveryBoyAction from "actions/deliveryboy";
import "assets/css/main.scss";
import { DeliveryPersonPastOrdersColumns } from 'helpers/tableDataMapper'
import { SearchWrapper } from "components/Table/SearchWrapper";
import { CustomDatePicker } from 'components/inputs/Datepicker';
import FormInput from 'components/inputs/FormInput';
import { TableWrapper } from 'components/TableWrapper';
import { formatDate, handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';
import { ORDER_STATUS, TABLE_PAYMENT_METHOD } from 'helpers/variables';

const defaultQueryPayload = {
    search: '',
    startDate: '',
    endDate: '',
    page: 1,
    limit: 50,
}

class AppListClass extends Component {
    state = {
        queryPayload: {
            search: null,
            startDate: '',
            endDate: '',
            page: 1,
            limit: 50,
        },
    }
    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload },() => this.getApplicationList(page))
    };

    getApplicationList = async (page) => {
        let { queryPayload } = this.state
        const payload = {
            ...queryPayload,
            page : page
        }
        
        if(queryPayload.startDate){
            payload["startDate"] = moment(queryPayload.startDate).format('YYYY-MM-DD')
        }

        if(queryPayload.startDate){
            payload["endDate"] = moment(queryPayload.endDate).format('YYYY-MM-DD')
        }
        await this.props.getPastOrders(payload);
    };

    clearFilters = () => {
        this.setState({
            queryPayload: { ...defaultQueryPayload }
        }, () => this.getApplicationList(1))
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState({
            queryPayload
        })
    };

    render() {
        let { deliveryBoy, loader } = this.props
        let { queryPayload } = this.state
        return (
            <>
                <SearchWrapper
                    applyFilter={() => this.getApplicationList(1)}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="startDate"
                                label="Choose Start Date"
                                placeholder="Choose Start Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["startDate"]}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="endDate"
                                label="Choose End Date"
                                placeholder="Choose End Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["endDate"]}
                            />
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-4 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div>
                    </div>
                </SearchWrapper>
                <TableWrapper
                    headerDetails={DeliveryPersonPastOrdersColumns}
                    pageMeta={deliveryBoy?.pastOrders?.pageMeta}
                    listLength={deliveryBoy?.pastOrders?.list || []}
                    isLoading={loader.isDeliveryPersonPastOrdersFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {deliveryBoy?.pastOrders?.list?.map((item, index) => {
                        const { name = '', total = "", shopName = '', paymentMethod = "", updatedAt = '', status = '', paymentStatus = '' } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        deliveryBoy?.pastOrders?.pageMeta?.page,
                                        deliveryBoy?.pastOrders?.pageMeta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(name, '-', 'text-center')}
                                {handleTableCellData(total, '-', 'text-center')}
                                {handleTableCellData(shopName, '-', 'text-center')}
                                <td>
                                    {
                                        status === ORDER_STATUS.DELIVERED ? <p className='table_order_status active_item'>Delivered</p> :
                                            status === ORDER_STATUS.CANCELLED ? <p className='table_order_status cancelled_item'>Cancelled</p> :
                                                <p>-</p>
                                    }
                                </td>
                                <td>
                                    {
                                        paymentMethod === TABLE_PAYMENT_METHOD.CASH ?
                                            <p className='table_order_status cash'>CASH</p>
                                            :
                                        paymentMethod === TABLE_PAYMENT_METHOD.ONLINE ?
                                            <p className='table_order_status online'>ONLINE</p>
                                            :
                                        <p>-</p>
                                    }
                                </td>
                                <td>
                                    {status !== ORDER_STATUS.CANCELLED ?
                                            paymentStatus === 1 ?
                                                <p className='table_order_status paid'>PAID</p>
                                                :
                                            paymentStatus === 2 ?
                                                <p className='table_order_status not_paid'>NOT PAID</p>
                                                :
                                            <p>-</p>
                                        :
                                        <p>-</p>
                                    }
                                </td>
                                {handleTableCellData(formatDate(updatedAt), '-', 'text-center')}

                            </tr>
                        );
                    })}
                </TableWrapper>
            </>
        );
    }
}

const mapStateToProps = ({ bookings, profile, loader, deliveryBoy }) => {
    return {
        bookings,
        profile,
        loader,
        deliveryBoy
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(deliveryBoyAction, dispatch);
};

const DeliveredOrders = connect(mapStateToProps, mapDispatchToProps)(AppListClass);

export default DeliveredOrders