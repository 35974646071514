import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from '../pagination';
import './table.scss'

export class TableWrapper extends Component {

  handleRenderData = () => {
    const { children, listLength = [], headerDetails = [] } = this.props;
    return listLength.length !== 0 ?
      children
      : (
        <tr>
          <td colSpan={headerDetails.length}>
            <p className="py-5 fs-16 text-center text-primary line-height-23 fw-400">
              No records found !!!
            </p>
          </td>
        </tr>
      );
  };

  render() {
    const {
      headerDetails = [],
      pageMeta = {},
      handlePageChange = {},
      isLoading = false,
      highlightRows = false,
      tableClassName = ''
    } = this.props;
    return (
      <div className={`maintableStriped table-container ${highlightRows ? 'highlight' : ''} ${tableClassName}`}>
        <div className="maintable-content table-responsive">
          <table className="table">
            <thead>
              <tr>
                {headerDetails.map(({ label }) => (
                  <th>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td className="py-3 fs-18 text-center empty" colSpan="100">
                    Loading...
                  </td>
                </tr>
              ) : (
                this.handleRenderData()
              )}
            </tbody>
          </table>
        </div>

        <Pagination pageMeta={pageMeta} handlePageChange={handlePageChange} />
      </div>
    );
  }
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  headerDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  listLength: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  ),
  pageMeta: PropTypes.node,
  highlightRows: PropTypes.bool,
  handlePageChange: PropTypes.func,
};
TableWrapper.defaultProps = {
  isLoading: false,
  headerDetails: [],
  listLength: [],
  pageMeta: {},
  highlightRows: false,
  handlePageChange: () => null,
}
