import React, { useState,useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import AuthCard from 'components/cards/AuthCard'
import { API, API_BOOK } from 'service'
import useValidationResolver from 'hooks/useValidationResolver';
import { NormalInput } from 'components/inputs/NormalInput/normalInput';
import { PasswordInput } from 'components/inputs/PasswordInput/passwordInput';
import { loginSchema } from 'helpers/validationSchema/auth.schema';
import { login } from 'service/actionType';
import { Toast } from 'helpers/Toast';
import { connect } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { requestForToken } from '../../firebase/firebase';

const { SESSION_API } = API_BOOK.ADMIN_MANAGEMENT

const LoginFunc = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const resolver = useValidationResolver(loginSchema);
  const [firebaseToken, setFirebaseToken] = useState(null);

  useEffect(() => {
    const getFirebaseToken = async () => {
      if (!firebaseToken) {
        const token = await requestForToken();
        console.log('token',token)
        if (token) {
          setFirebaseToken(token);
        }
      }
    };
    getFirebaseToken();
  }, [firebaseToken]);

  const methods = useForm({
    defaultValues: { emailId: '', password: '' },
    resolver
  });

  const onSubmit = async (formValues) => {
    try {
      let requestPayload = {
        ...SESSION_API.LOGIN_API,
        data: {
          ...formValues, 
          webFcmToken: firebaseToken
        }
      }
      setIsLoading(true)
      let response = await API(requestPayload)
      if (response.status === 200) {
        props.dispatch({ type: login.ADMIN_LOGIN, payload: response.data })
        localStorage.setItem('token', response.data.data.token)
        setIsLoading(false)
        const pathname = `/staff/dashboard`
        props.history.push({
          pathname
        })
      } else {
        setIsLoading(false)
        Toast({ type: 'error', message: response?.data?.message })

      }
    } catch (error) {
      setIsLoading(false)
      Toast({ type: 'error', message: error?.data?.message })
    }
  }
  return (
    <AuthCard heading={'Sign Into Your Account'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <NormalInput
            bgColor
            helperIcon=""
            placeholder="Email Id"
            className="mb-1"
            name="emailId"
          />
          <PasswordInput
            placeholder="Password"
            bgColor
            name="password"
            className="mb-1"
          />
          {/* <div className="w-100 d-flex align-items-center justify-content-end mb-2">
                <Link
                  to="/auth/forgot"
                  className="text-black text-right forgot-link "
                >
                  Forgot Password?
                </Link>
              </div> */}
          <div className='mt-3'>
          {isLoading
            ?
            <div className={`buttonType1 primary submit-btn btn-theme w-100 mt-1 fw-500`}>
              <Spinner
                size= "sm"
                color={"#ffffff"}
              />
            </div>
            :
            <Button
              className={`buttonType1 primary fw-500 w-100 mt-1  submit-btn btn-theme`}
              type='submit'
            >
              <span className="ml-2">Login</span>
            </Button>
          }
          </div>
        </form>
      </FormProvider>
    </AuthCard>
  );
}

const mapStateToProps = ({ profile, loader }) => {
  return {
    profile,
    loader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch
  }
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginFunc);

export { Login }