import { main } from '../service/actionType'

const initialState = {
    ordersList: [],
    deliveredList: [],
    paymentList: [],
    meta: {
        count: 0,
        limit: 10,
        page: 1,
        totalPages: 0
    },
    pastOrders: {
        list : [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    },
    allDeliveryBoys: []
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.GET_PAST_DELIVERY_PERSON_ORDERS_LIST:
            return {
                ...state,
                pastOrders: payload
            }
        case main.GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt:
            return {
                ...state,
                ordersList: payload
            }
        case main.GET_DELIVERY_BOYS_PAYMENT:
            return {
                ...state,
                paymentList: payload.data,
                meta: payload.meta
            }
        case main.GET_DELIVERED_LIST: {
            return {
                ...state,
                deliveredList: payload.data
            }
        }
        case main.GET_ALL_DELIVERY_BOYS: {
            return {
                ...state,
                allDeliveryBoys: payload
            }
        }
        default:
            return state
    }

}