const routers = [
    {
        path: '/',
        redirect: '/auth',
        auth: false
    },
    {
        component: 'StaticLayout',
        path: '/auth',
        auth: false,
        exact: false,
        redirect: '/auth/login',
        childrens: [
            {
                component: 'Login',
                path: '/login',
                auth: false,
                exact: true
            },
            {
                component: 'ForgotPassword',
                path: '/forgot',
                auth: false,
                exact: true
            },
            {
                component: 'SetPassword',
                path: '/set-password',
                auth: false,
                exact: true
            },
        ]
    },
    {
        component: 'AdminLayout',
        path: '/staff',
        auth: false,
        exact: false,
        childrens: [
            {
                component: 'Dashboard',
                path: '/dashboard',
                auth: true,
                exact: false
            },
            {
                component: 'viewCustomers',
                path: '/customers',
                auth: true,
                exact: true
            },
            // {
            //     component: 'DeliveryBoyPayment',
            //     path: '/deliveryboy/payment',
            //     auth: true,
            //     exact: true
            // },
            {
                component: 'Orders',
                path: '/orders',
                auth: true,
                exact: true
            },
            {
                component: 'OrderDetail',
                path: '/orders/detail',
                auth: true,
                exact: true
            },
            {
                component: 'OrderDetail',
                path: '/orders/detail/:_id',
                auth: true,
                exact: true
            },
            {
                component: 'viewShop',
                path: '/shop',
                auth: true,
                exact: true
            },
            {
                component: 'AddShop',
                path: '/shop/createshop',
                auth: true,
                exact: true
            },
            {
                component: 'AddShop',
                path: '/shop/createshop/:shopId',
                auth: true,
                exact: true
            },
            {
                component: 'AddCategory',
                path: '/category',
                auth: true,
                exact: true
            },
            {
                component: 'AddProduct',
                path: '/product',
                auth: true,
                exact: true
            },
            // {
            //     component: 'DeliveryBoy',
            //     path: '/assigned/orders',
            //     auth: true,
            //     exact: true
            // },
            {
                component: 'viewCustomers',
                path: '/customer',
                auth: true,
                exact: true
            },
            {
                component: 'CustomerHistory',
                path: '/customer/history/:_id',
                auth: true,
                exact: true
            },
            {
                component: 'CustomerDetail',
                path: '/customer/detail',
                auth: true,
                exact: true
            },
            {
                component: 'CustomerDetail',
                path: '/customer/detail/:_id',
                auth: true,
                exact: true
            },
            {
                component: 'Reports',
                path: '/reports',
                auth: true,
                exact: true
            },
            {
                component: 'CustomerTemplate',
                path: '/customer/edit/:customerId',
                auth: true,
                exact: true
            },
            
            {
                component: 'viewAdmins',
                path: '/users',
                auth: true,
                exact: true
            },
            {
                component: 'CreateAdmin',
                path: '/users/create/:userMasterId',
                auth: true,
                exact: true
            },
            {
                component: 'CreateAdmin',
                path: '/users/create',
                auth: true,
                exact: true
            },
            {
                component: 'bannerTemplate',
                path: '/banner',
                auth: true,
                exact: true
            },
            // {
            //     component: 'ProductsProfitList',
            //     path: '/product/profits/list',
            //     auth: true,
            //     exact: true
            // }
        ]
    }
    
]

export default routers 
