
import React  from "react";
import CreateApplication from 'components/buttons/createApplicationBtn'
import "../../assets/css/main.scss";

const Topbar = ({ pageName, createBtnLabel, onPressCreateBtn, showBtn }) => {

  return (
    <>
      <div className='top-nav'>
        <div className="top-nav-content">
          <h3 className="title">{pageName}</h3>
          {showBtn &&
            <div className='create_app_btn '>
              <CreateApplication
                onCreateApplicationBtnClick={onPressCreateBtn}
                btnName={createBtnLabel}
                disableCreateApp={false}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default Topbar