let roleBasedAllLinks = {
    1 : [
      {
        name: "Dashboard",
        iconClass: "icon-dashboard",
        routeName: "/dashboard",
        pathname: 'dashboard'
      },
      {
        name: "Customers",
        iconClass: "icon-customers",
        routeName: "/customers",
        pathname: 'customers'
      },
      {
        name: "Orders",
        iconClass: "icon-orders",
        routeName: "/orders",
        pathname: 'orders'
      },
      {
        name: "Shops",
        iconClass: "icon-stores",
        routeName: "/shop",
        pathname: 'shop'
      },
      {
        name: "Category",
        routeName: "/category",
        iconClass: "icon-menu",
        pathname: 'category',
      },
      {
        name: "Products",
        iconClass: "icon-food-menu",
        routeName: "/product",
        pathname: 'product',
      },
      {
        name: "User Management",
        iconClass: "icon-delivery-boy",
        routeName: "/users",
        pathname: 'users'
      },
      {
        name: "Reports",
        iconClass: "icon-menu",
        routeName: "/reports",
        pathname: 'reports'
      },
    ],
    3 : [
        {
          name: "Orders",
          iconClass: "icon-orders",
          routeName: "/assigned/orders",
          pathname: 'assigned'
        },
        
    ],
      get : function(roleId) {
        if(this[roleId])
            return this[roleId]
        else return []
    }
}
 
export const getLinksBasedOnRoleId = (roleId = 1) => {
  // let path = history.location.pathname.split('/').pop()
    let filteredLinks
    if(roleBasedAllLinks.get(roleId).length){
          filteredLinks = roleBasedAllLinks.get(roleId)
          // let checkPathInLinks = filteredLinks.findIndex(data => data.pathname === path)
    }
    return filteredLinks
}