import PropTypes from 'prop-types';

import BootstrapButton from 'react-bootstrap/Button';

import 'assets/css/main.scss';

/**
 * Button: The Common Re-usable Button across website.
 * @return {JSX.Element} The JSX Code for Button
 */

export function NormalButton({ children, ...restProps }) {
  return <BootstrapButton {...restProps}>{children}</BootstrapButton>;
}

NormalButton.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.string
};

NormalButton.defaultProps = {
  children: 'Hello',
  variant: 'primary'
};
