import React from "react";
import Paginate from "react-paginate";

const Pagination = ({pageMeta, handlePageChange}) => {
  return (
    <Paginate
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      activeClassName={"active"}
      page={pageMeta?.page}
      initialPage={0}
      disableInitialCallback={ true }
      pageCount={pageMeta?.totalPages}
      forcePage={pageMeta?.page ? parseInt(pageMeta?.page, 10) - 1 : 0}
      onPageChange={pagination => handlePageChange(pagination.selected + 1)}
    />
  );
};

export default Pagination;
