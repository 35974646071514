
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

export const getOrdersDetails = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: true })
        let { GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LIST_API,
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: main.GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt, payload: response.data.data })
            dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: false })
        } else {
            dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: false })
            dispatch({ type: main.GET_DELIVERY_BOY_ASSIGNED_BOOKINGS_LISt, payload: [] })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const getPastOrders = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: true })
        let { GET_PAST_DELIVERY_PERSON_ORDERS_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_PAST_DELIVERY_PERSON_ORDERS_LIST_API,
            params: payload
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: main.GET_PAST_DELIVERY_PERSON_ORDERS_LIST, payload: response.data.data })
            dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: false })
        } else {
            dispatch({ type: loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING, payload: false })
            dispatch({ type: main.GET_PAST_DELIVERY_PERSON_ORDERS_LIST, payload: [] })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const updateOrderStatus = (payload) => async (dispatch, getState) => {
    try {
        let { UPDATE_DELIVERY_BOY_ORDER_STATUS_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_DELIVERY_BOY_ORDER_STATUS_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            return {
                response: response
            }
        } else {
            Toast({ type: 'error', message: response?.data?.message })
            return {
                response: null
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            response: null
        }
    }
}


export const getDeliveryBoysPayments = (queryPayload) => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_ORDERS_LIST_FETCHING, payload: true })
    let { GET_DELIVERY_BOYS_PAYMENT_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_DELIVERY_BOYS_PAYMENT_API,
        params: queryPayload
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        dispatch({ type: main.GET_DELIVERY_BOYS_PAYMENT, payload: response.data.data })
        dispatch({ type: loaders.IS_ORDERS_LIST_FETCHING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const updateDeliveryBoyPayment = (payload) => async (dispatch, getState) => {
    let { UPDATE_PAYMENT_SENT_TO_DELIVERYBOY_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...UPDATE_PAYMENT_SENT_TO_DELIVERYBOY_API,
        data: {
            ...payload
        }
    }
    let response = await API(requestPayload)
    if (response.status === 200) {
        let queryPayload = {
            page: 1,
            limit: 10
        }
        getDeliveryBoysPayments(queryPayload)(dispatch, getState)
        return {
            status: true
        }
    } else {
        Toast({ type: 'error', message: response.data.message })
        return {
            status: false
        }
    }
}


export const getAllDeliveryBoys = () => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
    let { GET_AVAILABLE_DELIVERY_BOYS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_AVAILABLE_DELIVERY_BOYS_API
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        let deliveryBoysList = response.data.data.map(dl => ({ label: dl.name, value: dl._id }))
        dispatch({ type: main.GET_ALL_DELIVERY_BOYS, payload: deliveryBoysList })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    }
}