
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from '../helpers/Toast'


export const getCustomerList = (payload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  true })
        let { CUSTOMER_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CUSTOMER_LIST_API,
            params: {
                ...payload
            }
        }
        let response = await API(requestPayload)
        if(response.status === 200){
            dispatch({ type: main.GET_CUSTOMER_LIST, payload: response.data.data })
            dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
        } else {
            dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
        }
    } catch(error){
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    }
}

export const createCustomer = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_CUSTOMER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_CUSTOMER_API,
            data: payload
        }
        const response = await API(requestPayload)
        return response
    } catch(error){
        Toast({ type: 'error', message: error?.data?.message})
        return null
    }
}

export const editCustomer = (payload) => async (dispatch, getState) => {
    try {
        let { EDIT_CUSTOMER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...EDIT_CUSTOMER_API,
            data: payload
        }
        const response = await API(requestPayload)
        return response
    } catch(error){
        Toast({ type: 'error', message: error?.data?.message})
        return null
    }
}

export const getCustomerHistory = (payload) => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  true })
    let { CUSTOMER_HISTORY_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...CUSTOMER_HISTORY_API,
        params: {
            ...payload
        }
    }
    let response = await API(requestPayload)
    if(response.status == 200){
        dispatch({ type: main.GET_CUSTOMER_HISTORY, payload: response.data.data })
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    }
}

export const getCustomersDropdownList = () => async (dispatch, getState) => {
    try {
        let { GET_CUSTOMER_DROPDOWN_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_CUSTOMER_DROPDOWN_API
        }
        let response = await API(requestPayload)
        if(response.status === 200){
            dispatch({ type: main.GET_CUSTOMER_DROPDOWN_LIST, payload: response.data.data })
        } else {
            dispatch({ type: main.GET_CUSTOMER_DROPDOWN_LIST, payload: [] })
        }
    } catch(error){
        Toast({ type: 'error', message: error?.data?.message})
    }
}