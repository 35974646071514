import React, { useEffect, Suspense } from 'react';
import { Route, Router } from "react-router-dom";
import routers from "./routes"
import { history , ThemeContext} from "../helpers"
import * as Layout from "../layout";
import * as Pages from "../pages"
import { connect } from 'react-redux';
import { ToastBar, Toaster } from 'react-hot-toast';
import { onMessageListener } from '../firebase/firebase';

const RoutesFunc = () => {
    
    onMessageListener().then(payload => {
        console.log('payload', payload)


    }).catch(err => {
        console.log('err', err)
    })

    return (
            <Router history={history} >
                <Suspense fallback={<div>Loading...</div>}>
                    {routers.map(({ component, redirect, path, exact = false, auth = true, childrens = [] }, index) => {
                        if (childrens.length > 0) {
                            return (
                                <Route
                                    path={path}
                                    exact={exact}
                                    key={index}
                                    render={(props) => {

                                        if (redirect) {
                                            if (props.location.pathname === path) {
                                                props.history.push(redirect)
                                            }
                                        }
                                        const LayoutComponent = Layout[component]
                                        return (

                                            <LayoutComponent {...props} >
                                                
                                                {childrens.map(({ component: ChildrenComponent, path: childrenPath, exact = false, auth = true }) => {
                                                    return <Route
                                                        path={path + childrenPath}
                                                        exact={exact}
                                                        key={path + childrenPath}
                                                        render={(props) => {

                                                            let PageComponent = Pages[ChildrenComponent]
                                                            return <PageComponent {...props} />
                                                        }}

                                                    />

                                                })}
                                            </LayoutComponent>
                                        )

                                    }}

                                />
                            )
                        }
                        return (
                            <Route
                              path={path}
                              exact={exact}
                              key={component}
                              render={(props) => {
                                if (component) {
                                  const PageComponent = Pages[component]
                                  return <PageComponent />;
                                }
            
                                if (redirect) {
                                  if (props.location.pathname === path) {
                                        props.history.push(redirect)
                                  }
                                }
            
                                return <div />;
                              }}
                            />
                        );
                    })}
                </Suspense> 
                <Toaster
                    position="top-right"
                    gutter={8}
                    reverseOrder
                    containerClassName="custom-toast"
                    containerStyle={{ right: '40px', bottom: '50px' }}
                >
                    {(t) => (
                        <ToastBar toast={t} style={{ padding: 0, overflow: 'hidden' }}>
                        {({ icon, message }) => {
                            return (
                            <div
                                className={`custom-toast-${t.type} d-flex align-items-center custom-toast-item`}
                            >
                                {icon}
                                {message}
                            </div>
                            );
                        }}
                        </ToastBar>
                    )}
                </Toaster>
            </Router>
    )
}

export default connect()(RoutesFunc)