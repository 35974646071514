import React, { Component,Fragment,useEffect } from 'react'
import {
    Row,
    Col,
    Progress,
    Container,
    Card,
    CardBody,
} from "reactstrap";
import { BiBookAlt,BiUser,BiBookBookmark, BiCart, BiBasket, BiRupee, BiShoppingBag } from "react-icons/bi";
import ShopWiseSalesAnalytics from 'components/graphs/shopWiseSalesAnalytics';
import { OverallOrdersGraph } from 'components/graphs/OverallOrdersGraph';
import { MonthWiseOrdersGraph } from 'components/graphs/MonthWiseOrdersGraph';
import { SearchWrapper } from 'components/Table/SearchWrapper';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as dashboard from "actions/dashboard";
import { TopCustomersColumns, TopCustomersTable } from 'helpers/tableDataMapper'
import { ShopRatings } from 'components/graphs/dashboardShopRating';
import moment from 'moment';
import { TableWrapper } from 'components/TableWrapper';
import { handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';
import Topbar from 'components/topbar';

const DashboardView = (props) => {

    const fetchData = () => {
        props.getDashboardCounts()
        props.getYearlyOrderStats(moment().format('YYYY-MM-DD'))
        props.getMonthlyOrderStats(moment().format('YYYY-MM-DD'))
        props.shopAnalytics()
        props.topCustomers()
    }

    useEffect(() => {
        fetchData()
    }, [])
    

    const onYearChange = async (value) => {
        await props.getYearlyOrderStats(value)
    }
    
    const onMonthChange = async (value) => {
        await props.getMonthlyOrderStats(value)
    }
    const handleSelectRow = (data) => {
        props.history.push(`/staff/customer/history/${data.userId}`)
    }
    return (
        <Container fluid className="p-0 mt-10">
            <Topbar 
                pageName="Dashboard"
                createBtnLabel=""
                showBtn={false}
            />
            <Row className='mt-3'>
                <Col xl="8">
                    <Row>
                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Total Orders
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.totalNoOfOrders}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiBasket size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Delivered   Orders  
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.totalDeliveredOrders}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiShoppingBag size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Cancelled Orders
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.totalCancelledOrders}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiBookAlt size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <OverallOrdersGraph 
                        ordersStats={props.dashboard.ordersStats} 
                        onYearChange={onYearChange}
                    />
                </Col>
                <Col xl="4">
                    <Row>
                        <Col md="6">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Today Orders
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.noOfOrdersToday}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiCart size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Today Sales
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.todaysTotal}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiRupee size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Month Sales
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.monthTotal}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiRupee size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Year sales
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.yearTotal}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiRupee size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Total Number Of Customers
                                            </p>
                                            <h4 className="mb-0">{props.dashboard?.dashboardCounts?.customersCount}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-avatar align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-avatar">
                                                <BiUser size={20} />
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
            <Row>
                {/* <Col xl="7">
                    <ShopWiseSalesAnalytics shopAnalytics={props.dashboard.shopAnalytics} />
                </Col>
                <Col xl="5">
                    <ShopRatings shopAnalytics={props.dashboard.shopAnalytics} />
                </Col> */}
            </Row>
            <Row>
                <Col xl="12">
                    <MonthWiseOrdersGraph 
                        monthStats={props.dashboard.monthStats} 
                        onMonthChange={onMonthChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <div className="mb-4 h4 card-title">Top Customers</div>
                            <TableWrapper
                                headerDetails={TopCustomersColumns}
                                listLength={props.dashboard?.topCustomers || []}
                                isLoading={props.loader.isTableDetailsFetching}
                                highlightRows
                                tableClassName='mt-3'
                            >
                                {props.dashboard?.topCustomers?.map((item, index) => {
                                    const { name = '', mobileNumber = "", orders = '' } = item;
                                    return (
                                        <tr>
                                            {handleTableCellData(
                                                getSNo(
                                                    index
                                                ),
                                                '-',
                                                'text-center'
                                            )}
                                            {handleTableCellData(name, '-', 'text-center')}
                                            {handleTableCellData(mobileNumber, '-', 'text-center')}
                                            {handleTableCellData(orders, '-', 'text-center')}
                                        </tr>
                                    );
                                })}
                            </TableWrapper>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </Container>
    );
}

const mapStateToProps = ({ bookings, dashboard,profile,loader }) => {
    return {
        bookings,
        dashboard,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(dashboard, dispatch);
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardView);

export {Dashboard}