import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from 'moment';
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";

import * as customer from "actions/customer";

import { CUSTOMER_STATUS } from 'helpers/variables.js'
import { CustomerColumns } from 'helpers/tableDataMapper'
import { formatDate, handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';

import { SearchWrapper } from "components/Table/SearchWrapper";
import Topbar from 'components/topbar';
import { TableWrapper } from 'components/TableWrapper';
import { CustomDatePicker } from 'components/inputs/Datepicker';
import FormInput from 'components/inputs/FormInput';
import { TableActionbtns } from 'components/TableWrapper/tableActionBtns';

const defaultPayload = {
    search: '',
    startDate: '',
    endDate: '',
    page: 1,
    limit: 50
}
class viewCustomersClass extends Component {
    state = {
        queryPayload: {
            search: null,
            startDate: null,
            endDate: null,
            page: 1,
            limit: 50
        }
    }

    componentDidMount() {
        this.getApplicationList()
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState({
            queryPayload
        })
    };

    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload },() => this.triggerApi(page))
    };

    getApplicationList = async () => {
        let { queryPayload } = this.state
        await this.props.getCustomerList(queryPayload);
    };

    triggerApi = async (page) => {
        let { queryPayload } = this.state
        const payload = {
            ...queryPayload,
            page : page
        }
        
        if(queryPayload.startDate){
            payload["startDate"] = moment(queryPayload.startDate).format('YYYY-MM-DD')
        }

        if(queryPayload.startDate){
            payload["endDate"] = moment(queryPayload.endDate).format('YYYY-MM-DD')
        }

        await this.props.getCustomerList(payload);
    }

    clearFilters = () => {
        this.setState({
            queryPayload: { ...defaultPayload }
        }, this.getApplicationList)
    }

    render() {
        const { loader, customers } = this.props
        const { queryPayload } = this.state
        return (
            <>
                <Topbar
                    pageName="Customers"
                    showBtn={false}
                />
                <SearchWrapper
                    applyFilter={() => this.triggerApi(1)}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="startDate"
                                label="Choose Start Date"
                                placeholder="Choose Start Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["startDate"]}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="endDate"
                                label="Choose End Date"
                                placeholder="Choose End Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["endDate"]}
                            />
                        </div>
                        <div className='col-lg-3 col-xl-3 col-md-4 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div>
                    </div>
                    
                </SearchWrapper>
                <TableWrapper
                    headerDetails={CustomerColumns}
                    pageMeta={customers?.customerList?.pageMeta}
                    listLength={customers?.customerList?.list || []}
                    isLoading={loader.isTableDetailsFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {customers?.customerList?.list?.map((item, index) => {
                        const { name = '', mobileNumber = "", emailId = '', _id = "", createdAt = '', status = '' } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        customers?.customerList?.pageMeta?.page,
                                        customers?.customerList?.pageMeta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(_id.slice(_id.length - 6), '-', 'text-center')}
                                {handleTableCellData(name, '-', 'text-center')}
                                {handleTableCellData(mobileNumber, '-', 'text-center')}
                                {handleTableCellData(emailId, '-', 'text-center')}
                                {/* {handleTableCellData(addressDetail?.userAddress, '-', 'text-center')} */}
                                {handleTableCellData(formatDate(createdAt), '-', 'text-center')}
                                <td>
                                    {
                                        status === CUSTOMER_STATUS.ACTIVE ?
                                            <p className='table_order_status active_item'>Verified</p>
                                            :
                                            status === CUSTOMER_STATUS.UN_APPROVED ?
                                                <p className='table_order_status unapproved_item'>Un Approved</p>
                                                :
                                                <p>--</p>
                                    }
                                </td>
                                <td>
                                    <TableActionbtns
                                        item={item}
                                        showDelete={false}
                                        showView = {true}
                                        viewItem = {() => this.props.history.push(`/staff/customer/history/${_id}`)}
                                        handleSelectRow={() => this.props.history.push(`/staff/customer/detail/${_id}`)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </TableWrapper>
            </>
        );
    }
}

const mapStateToProps = ({ customers, profile, loader }) => {
    return {
        customers,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(customer, dispatch);
};

const viewCustomers = connect(mapStateToProps, mapDispatchToProps)(viewCustomersClass);

export { viewCustomers }