import React, { Component, Fragment } from 'react'
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notification from "actions/notification";
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { Toast } from "helpers/Toast";
import { axios, API, API_BOOK } from "service";
import Uploadfile from 'components/inputs/Uploadfile'
import Loader from 'components/loader/loader';
import BackBtn from "components/Tabs/backBtn"
import Topbar from 'components/topbar';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';

const defaultNotificationPayload = {
    _id: "",
    title: "",
    description: "",
    imageUrl: "",
}
class NotificationDetailClass extends Component {
    state = {
        activeTab: [1],
        notificationDetail: {
            _id: null,
            title: null,
            description: null,
            imageUrl: null,
        },
        isUploadingImage: false,
        errors: {},
        btnLoading: false,
        isLoading: false,
        isEdit: false,

    }
    componentDidMount() {
        let _id = this.props.match.params._id
        if (_id != undefined) {
            this.getNotificationFromId(_id)
        }
    }

    getNotificationFromId = async (_id) => {
        try {
            let { GET_NOTIFICATION_DETAIL_API } = API_BOOK.MAIN_APP
            this.setState({ isLoading: true, isEdit: true })
            let requestPayload = {
                ...GET_NOTIFICATION_DETAIL_API,
                params: {
                    _id: _id
                }
            }
            let response = await API(requestPayload)
            if (response.status == 200) {
                this.setState({ notificationDetail: response.data.data, isLoading: false })
            } else {
                this.setState({ isLoading: false })
            }
        } catch (error) {
            Toast("error", error?.data?.message)
            this.setState({ isLoading: false })
        }
    }

    onChange = (name, value) => {
        let { notificationDetail, errors } = this.state
        notificationDetail[name] = value
        errors[name] = undefined
        this.setState({ notificationDetail: notificationDetail, errors: errors })
    };
    onSubmit = async () => {
        let {
            notificationDetail,
            isEdit
        } = this.state
        let validation = {
            ...inputValidations
        }
        const notValid = validate(notificationDetail, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            let payload = {
                title: notificationDetail.title,
                description: notificationDetail.description,
                imageUrl: notificationDetail.imageUrl
            }
            let response;
            if (isEdit) {
                payload["_id"] = notificationDetail._id

                response = await this.props.editNotification(payload)
            } else {

                response = await this.props.createNotification(payload)
            }

            if (response.status === 200) {
                this.setState({
                    btnLoading: false,
                    notificationDetail: { ...defaultNotificationPayload }
                })
                Toast({ type: 'success', message: response?.data?.message })
            } else {
                Toast({ type: 'error', message: response?.data?.message })
                this.setState({
                    btnLoading: false
                })
            }

        }
    }
    onUpload = async (name, files, id) => {
        let { errors, notificationDetail } = this.state;
        errors[name] = undefined
        let formData = new FormData()
        formData.append('image', files[0])

        this.setState({ isUploadingImage: true })
        const { UPLOAD_DOC_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPLOAD_DOC_API,
            data: formData,
        };
        let response = await API(requestPayload);
        if (response.status === 200) {
            let newDetail = {
                ...notificationDetail,
                imageUrl: response.data.data.imageUrl,
            }
            this.setState({
                notificationDetail: newDetail,
                isUploadingImage: false
            })
        } else {
            Toast({ type: 'error', message: response.data.message })

        }
        this.setState({ isUploadingImage: false })

    }

    render() {
        let { notificationDetail, errors, btnLoading, isUploadingImage, isLoading, isEdit } = this.state
        return (
            <>
                <Topbar
                    pageName={`${isEdit ? 'Edit' : 'Create'} Notification`}
                    showBtn={false}
                />
                {isLoading
                    ?
                    <Loader className="h-100" />
                    :
                    <>
                        <div className='mt-3'>
                            <Card>
                                <CardBody>
                                    <Row className="no-gutters mb-4">
                                        <Col className="col-lg-6">
                                            <Row>
                                                <Col className="col-lg-10">
                                                    <FormInput
                                                        type="text"
                                                        label={`Notification Title`}
                                                        name="title"
                                                        onChange={this.onChange}
                                                        value={notificationDetail["title"]}
                                                        error={errors["title"]}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-lg-10">
                                                    <FormInput
                                                        type="textarea"
                                                        label={`Notification Description`}
                                                        name="description"
                                                        onChange={this.onChange}
                                                        value={notificationDetail["description"]}
                                                        error={errors["description"]}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="col-lg-10  mt-4">
                                                    <Uploadfile
                                                        label={"Image"}
                                                        id="imageUrl"
                                                        name="imageUrl"
                                                        uploadedFileName={notificationDetail['imageFileName']}
                                                        uploadedImageUrl={notificationDetail['imageUrl']}
                                                        isUploading={isUploadingImage}
                                                        onChange={this.onUpload}
                                                        isMult={false}
                                                        error={errors['imageUrl']}
                                                    ></Uploadfile>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <FormSubmissionBtns
                                            onSubmit={this.onSubmit}
                                            onCancel={() => this.props.history.goBack()}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = ({ notification, profile, loader }) => {
    return {
        notification,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(notification, dispatch);
};

const NotificationDetail = connect(mapStateToProps, mapDispatchToProps)(NotificationDetailClass);

export { NotificationDetail }


let inputValidations = {
    title: {
        presence: {
            allowEmpty: false,
            message: "^Title can't be blank"
        }
    }

}