import React, { Component,Fragment } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as deliveryBoyAction from "actions/deliveryboy";
import ApplicationList from './ApplicationList'

class DeliveryBoyClass extends Component {
    state = {
        showDetails: false
    }
    static getDerivedStateFromProps(nextProps,prevState){
        if(nextProps.loader.showStaffDetails !== prevState.showDetails)
            return {
                showDetails : nextProps.loader.showStaffDetails,
                showEdit : false
            }
        return null
    }
    render() {
        let {showDetails} = this.state
        return (
            <>
                <ApplicationList 
                    toggle={() => this.props.toggleStaffDetails()}
                    isDetailMode={ showDetails } {...this.props}
                />
            </>
        );
    }
}

const mapStateToProps = ({ bookings, profile,loader, deliveryBoy }) => {
    return {
        bookings,
        profile,
        loader,
        deliveryBoy
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(deliveryBoyAction, dispatch);
};

const DeliveryBoy = connect(mapStateToProps, mapDispatchToProps)(DeliveryBoyClass);

export {DeliveryBoy}