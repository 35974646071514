import { main } from '../service/actionType'

const initialState = {
    ordersStats: {
        revenue: [],
        orders: []
    },
    dashboardCounts: {
        totalNoOfOrders: 0,
        customersCount: 0,
        noOfOrdersToday: 0,
        totalDeliveredOrders: 0,
        totalCancelledOrders: 0,
        todaysTotal:0,
        monthTotal:0,
        yearTotal:0
    },
    shopAnalytics: [],
    topCustomers: [],
    monthStats: {
        labels: [],
        monthTotalSales: [],
        noOfOrdersInMonth: [],
        totalNoOfOrders: 0,
        totalRevenue: 0
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.ORDER_STATS:
            return {
                ...state,
                ordersStats: payload
            }
        case main.MONTHLY_ORDER_STATS:
            return {
                ...state,
                monthStats: {
                    ...state.monthStats,
                    labels: payload.labels,
                    monthTotalSales: payload.monthTotalSales,
                    noOfOrdersInMonth: payload.noOfOrdersInMonth,
                    totalNoOfOrders: payload?.totalNoOfOrders,
                    totalRevenue:payload.totalRevenue
                }
            }
        case main.SHOP_ANALYTICS:
            return {
                ...state,
                shopAnalytics: payload,
            }
        case main.TOP_CUSTOMERS:
            return {
                ...state,
                topCustomers: payload,
            }
        case main.DASHBOARD_COUNT: 
            return {
                ...state,
                dashboardCounts: {
                    ...state.dashboardCounts,
                    customersCount: payload.customersCount,
                    noOfOrdersToday: payload.noOfOrdersToday,
                    totalNoOfOrders: payload.totalNoOfOrders,
                    totalDeliveredOrders: payload.totalDeliveredOrders,
                    totalCancelledOrders: payload.totalCancelledOrders,
                    todaysTotal: payload.todaysTotal,
                    monthTotal: payload.monthTotal,
                    yearTotal: payload.yearTotal
                },
            }
        default:
            return state
    }

}