/* eslint-disable no-console */
import moment from 'moment';
// import Cookies from 'js-cookie';

import { Toast } from './Toast';

export const logout = () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.pathname = '/auth/login';
  }, 200);
};

export const getSafe = (fn, defaultValue = '') => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};
export const ternaryCondition = (val1, val2 = '', defaultValue = '') => {
  return val1 ? val2 : defaultValue;
};

export const getSNo = (index, page = 1, pageCount = 10) => {
  return parseInt(((page - 1) * pageCount), 10) + index + 1;
};

export const replaceEmpty = (val, defaultValue = null) => {
  if (val) return val;

  return defaultValue;
};

export const dateFormatter = (date, format = 'DD/MM/YYYY') => {
  if (!date) return '';

  const finalDate = moment(date).format(format);
  return finalDate === 'Invalid date' ? '' : finalDate;
};

export const convertDate = (date, inputFormat = '', format = 'DD/MM/YYYY') => {
  if (!date) return '';

  const finalDate = moment(date, inputFormat).format(format);
  return finalDate === 'Invalid date' ? '' : finalDate;
};

// export const addCookie = (key, value, options = {}) => {
//   Cookies.set(key, value, options);
// };

// export const removeCookie = (key, options = {}) => {
//   Cookies.remove(key, options);
// };

// export const getCookie = (key) => Cookies.get(key);

export const getValidValue = (data, type = 'normal', replaceValue = null) => {
  if (type === 'select') return data && data.label && data.value ? data.value : replaceValue;

  if (typeof data === 'boolean') return data;

  if (Array.isArray(data) && data?.length === 0) return replaceValue;

  if (typeof data === 'object' && Object.keys(data || {})?.length === 0)
    return replaceValue;

  if (typeof data === 'undefined') return replaceValue;

  return data;
};

/**
 * Displays the short number count (eg:) 325K,2.4M,...etc
 *
 * @param {number | string} val the number which needs to be displayed for short count.
 * @returns {string} returns the short count (e.g) if 3000 is the input then it returns 3K as output
 */

export const countHelper = (val) => {
  let displayCount = '';

  if (Number.isNaN(val)) return displayCount;

  displayCount = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(val);

  return displayCount;
};

/**
 * Extract the right error message from react-hook-form error object
 *
 * @param {object} errors Errors object from react-hook-form
 * @param {object} fieldArrayError  { index, fieldName: "date", arrayName: "callLogs", }
 * @param {string} name
 */

export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return (
    !!errors[fieldArrayError.arrayName] &&
    errors[fieldArrayError.arrayName][fieldArrayError.index] &&
    typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ] !== 'undefined' &&
    errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ]
  );
};

function fallbackCopyTextToClipboard(text, message) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    Toast({ type: 'success', message });
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text, message) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, message);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      Toast({ type: 'success', message });
      //   console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      Toast({ type: 'error', message: err.message || 'Error' });
    }
  );
}

export const convertToValidObject = (obj) => {
  const currentObj = { ...obj };

  Object.keys(currentObj)
    .filter((key) => typeof currentObj[key] !== 'boolean')
    .forEach((key) => {
      if (!currentObj[key]) {
        delete currentObj[key];
      }
    });

  return { ...currentObj };
};

export const addressFormatter = (deliveryAddress) => {
  const { addressLine1, addressLine2, city, state, country, pincode } =
    deliveryAddress || {};

  const addressArray = [
    getValidValue(addressLine1),
    getValidValue(addressLine2),
    getValidValue(city),
    getValidValue(state),
    getValidValue(country)
  ].filter((data) => data !== getValidValue(undefined));

  return `${addressArray.join(' , ')} - ${pincode}`;
};

export const addPaymentDuration = (paymentData) => {
  const { responseTimestamp = 1649396506275, txnToken } = paymentData || {};

  if (responseTimestamp) {
    const isoTime = new Date(+responseTimestamp).toISOString();

    const endTime = moment(isoTime).add(15, 'minutes').toDate();
    console.log(endTime);
    // addCookie('txnToken', txnToken, { expires: endTime });
    // addCookie('responseTimestamp', responseTimestamp, { expires: endTime });
    // addCookie('paymentData', JSON.stringify(paymentData), { expires: endTime });
  }
};

export const isPaymentDurationValid = () => {
  // const responseTimestamp = getCookie('responseTimestamp');

  // if (!responseTimestamp) return false;

  // const isoTime = new Date(+responseTimestamp).toISOString();

  // return moment(isoTime).add(15, 'minutes').toDate();
};

export const urlCombiner = (url) => {
  const protocol = ['h', 't', 't', 'p'].join('');

  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: 'error', message: err.Error || err.message || 'Error' });
};

export const generateQuery = (query) => {
  const params = new URLSearchParams();
  Object.keys(query).forEach((param) => params.append(param, query[param]));
  return Object.keys(query).length > 0 ? `?${params?.toString()}` : '';
};
