
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import { getLinksBasedOnRoleId } from 'helpers/roleHandler'
import logo from "assets/images/logo.png";
import "../../../assets/css/main.scss";
import useMediaQuery from "hooks/useMediaQuery";
import { MdLogout } from "react-icons/md";
import { Logout } from "components/Logout";


let prefix = "/staff"

const Sidebar = ({ location, history, profile, isOpen, isMobile, toggle, handleClose }) => {
  const [showLogout, toggleLogout] = useState(false)
  const [linkCollapse, setLinkCollapse] = useState(false)
  const [filteredLinks, setFilteredLinks] = useState([])

  const isMobileSize = useMediaQuery('(max-width: 768px)');
  const toggleClass = isOpen ? 'open-sidebar' : 'closed-sidebar';
  const mobileClassName = isMobile
    ? 'd-lg-block mobile-sidebar'
    : 'd-sm-block';

  const redirectTo = (pathName) => {
    
    history.push(prefix + pathName)

    if (isMobile) handleClose();
  }
  useEffect(() => {
    let newfilteredLinks = getLinksBasedOnRoleId()
    setFilteredLinks(newfilteredLinks)
  }, [])

  const handleLinkClick = (data, index) => {
    if (data.showSubContent !== undefined) {
      filteredLinks.map((link, i) => {
        if (index === i) { link.showSubContent = !data.showSubContent }
      })
      setFilteredLinks([].concat(filteredLinks))
    } else {
      redirectTo(data.routeName)
    }
  }

  const handleSubLinkClick = (data) => {
    redirectTo(data.routeName)
  }

  return (
    <>
      <div className={`side-nav ${toggleClass} ${mobileClassName} custom-scrollbar`}>
          <div className={`${isMobile ? "w-100 justify-content-center mxw-92" : ""} sidebar_logo`}>
          {!isMobile ?
            toggleClass === 'closed-sidebar'
              ?
              <FaAngleDoubleRight color="#fff" size={40} className="doubleArrow" onClick={toggle} />
              :
              <FaAngleDoubleLeft color="#fff" size={40} className="doubleArrow" onClick={toggle} />
              :
              <></>
            }
            <img src={logo} alt="logo" className="img-fluid" />
          </div>
        <ul className="staff-sidebar">
          {filteredLinks.map((data, i) => (
            <li
              id={data.togglerName}
              className={`nav-list-menu ${location.pathname.includes(prefix + data?.routeName) ? "active" : ''} `}
            >
              {data.subContent !== undefined && data.subContent.length > 0
                ?
                <div
                  onClick={() => handleLinkClick(data, i)}
                  className={`nav-list-wrapper ${location.pathname.includes(data.togglerName) ? "bg-primary text-white br-0" : "br-0"}`}
                  data-toggle={data.showSubContent}
                >
                  <div className="nav-view-icon">
                    <i className={data.iconClass} ></i>
                  </div>
                  <div className="nav-view-content">
                    <h5 className="name">{data.name}</h5>
                  </div>
                  {
                    data.subContent ? <i className={`ml-3 fa arrow fa-chevron-${data.showSubContent ? "up" : "down"} small`}></i> : ''
                  }
                </div>
                :
                <div
                  onClick={() => handleLinkClick(data)}
                  className={`nav-list-wrapper ${location.pathname.includes(data.togglerName) ? "bg-primary text-white br-0" : "br-0"}`}
                >
                  <div className="nav-view-icon">
                    <i className={data.iconClass} ></i>
                  </div>
                  <div className="nav-view-content">
                    <h5 className="name">{data.name}</h5>
                  </div>
                  {
                    data.subContent ? <i className={`ml-3 fa arrow fa-chevron-${location.pathname.includes(data.togglerName) ? "up" : "down"} small`}></i> : ''
                  }
                </div>
              }
              {data.subContent ? (
                <Collapse isOpen={data.showSubContent}>
                  <ul className={`list-unstyled sub-content`}>
                    {data.subContent.map((subData) => {
                      return (
                        <li
                          className={`${location.pathname.includes(subData.routeName) ? 'active' : ''} nav-list-menu`}
                          onClick={() => redirectTo(subData.routeName)}
                        >
                          {subData.subContent !== undefined && subData.subContent.length > 0
                            ?
                            <div>
                              <div
                                onClick={() => handleSubLinkClick(data)}
                                className={`nav-list-wrapper ${location.pathname.includes(data.togglerName) ? "bg-primary text-white br-0" : "br-0"}`}
                                data-toggle={linkCollapse}
                              >
                                <div className="nav-view-icon">
                                  <i className={subData.iconClass} ></i>
                                </div>
                                <div className="nav-view-content">
                                  <h5 className="name">{subData.name}</h5>
                                </div>
                              </div>
                            </div>
                            :
                            <div className={`nav-list-wrapper`}>
                              <div className="nav-view-icon">
                                <i className={subData.iconClass}></i>
                              </div>
                              <div className="nav-view-content">
                                <h5 className="name">
                                  {subData.name}
                                </h5>
                              </div>
                            </div>
                          }
                        </li>
                      );
                    })}
                  </ul>
                </Collapse>
              ) : (
                ""
              )}


            </li>

          ))}

        </ul>
        {!(isMobile && isMobileSize) ?
          <div className="sidebar_logout staff-sidebar">
            <div
              onClick={() => toggleLogout(!showLogout)}
              className={`nav-list-menu`}
            >
              <div className="nav-list-wrapper">
                <div className="nav-view-icon">
                  <MdLogout size={20} />
                </div>
                <div className="nav-view-content">
                  <h5 className="name">LOGOUT</h5>
                </div>
              </div>

            </div>
          </div>
          :
          <></>
        }

      </div>
      {isMobile && isMobileSize && (
        <div className="sidebar-backdrop" onClick={handleClose} />
      )}
      {showLogout && <Logout />}
    </>
  );
}


export default Sidebar