import PropTypes from 'prop-types';
import 'assets/css/main.scss';
import '../NormalInput/normalinput.scss';
import './passwordinput.scss';
import { Form, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { ConnectForm } from 'context/ConnectForm';
import { FormErrorMessage } from 'components/common/FormErrorMessage';
import { getFormErrorMessage } from 'helpers/index';
import { Controller } from 'react-hook-form';

/**
 * Password Input: The Common Re-usable password Input button across website.
 * @return {JSX.Element} The JSX Code for Input
 */

export function PasswordInput({
  label,
  bgColor,
  className,
  rules,
  name,
  fieldArrayName,
  ...restProps
}) {
  const [isPassword, setPassword] = useState(true);

  return (
    <ConnectForm>
      {({ errors, control }) => (
        <div
          className={`custom-normalinput custom-passwordinput ${
            bgColor ? 'input-bg' : ''
          } ${className}`}
        >
          <Form.Group className="">
            {label && (
              <Form.Label className="form-label" title={label} htmlFor={name}>
                {label}
              </Form.Label>
            )}
            <div className="input-block">
              <Controller
                name={name}
                control={control}
                rules={{ ...rules }}
                render={({
                  field: { onChange, value: fieldValue, ref, props }
                }) => (
                  <InputGroup>
                    {isPassword ? (
                      <input
                        type="password"
                        {...props}
                        onChange={onChange}
                        value={fieldValue || ''}
                        ref={ref}
                        placeholder=""
                        id={name}
                        {...restProps}
                      />
                    ) : (
                      <input
                        {...props}
                        onChange={onChange}
                        value={fieldValue || ''}
                        ref={ref}
                        type={isPassword ? 'password' : 'text'}
                        placeholder=""
                        id={name}
                        {...restProps}
                      />
                    )}

                    <button
                      onClick={() => setPassword(!isPassword)}
                      className="helper-icon"
                      type="button"
                    >
                      <i className={`icon-eye${isPassword ? '-off' : ''}`} />
                    </button>
                  </InputGroup>
                )}
              />

              <FormErrorMessage
                error={getFormErrorMessage(errors, fieldArrayName, name)}
              />
            </div>
          </Form.Group>
        </div>
      )}
    </ConnectForm>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.bool,
  rules: PropTypes.any,
  fieldArrayName: PropTypes.any,
  name: PropTypes.string
};

PasswordInput.defaultProps = {
  label: '',
  className: '',
  name: '',
  bgColor: false,
  rules: null,
  fieldArrayName: null
};
