import { main } from '../service/actionType'

const initialState = {
    shopPaymentList: [],
    customerPaymentList: {
        data: [],
        meta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        },
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.GET_SHOP_PAYMENT_LIST:
            return {
                ...state,
                shopPaymentList: payload.data
            }
        case main.GET_CUSTOMER_PAYMENT_LIST: {
            return {
                ...state,
                customerPaymentList: {
                    data: payload.data,
                    meta: payload.meta
                }
            }
        }
       
        default:
            return state
    }

}