/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { ConnectForm } from 'context/ConnectForm';

import 'assets/css/main.scss';
import './normalinput.scss';
import { Form, InputGroup } from 'react-bootstrap';
import { FormErrorMessage } from 'components/common/FormErrorMessage';
import { getFormErrorMessage } from 'helpers/utils';
import { Controller } from 'react-hook-form';

/**
 * Normal Input: The Common Re-usable Input button across website.
 * @return {JSX.Element} The JSX Code for Input
 */

export function NormalInput({
  label,
  type,
  bgColor,
  helperIcon,
  name,
  rules,
  viewOnly,
  fieldArrayName,
  ...restProps
}) {
  const inputProps = {};

  if (type === 'textarea') {
    inputProps.as = 'textarea';
  }
  return (
    <ConnectForm>
      {({ errors, control, watch }) => (
        <div className={`custom-normalinput ${bgColor ? 'input-bg' : ''}`}>
          <Form.Group>
            {label && (
              <Form.Label className="form-label" title={label} htmlFor={name}>
                {label}
              </Form.Label>
            )}
            {viewOnly && <p>{name && watch(name)}</p>}

            {!viewOnly && (
              <div className="input-block">
                <Controller
                  name={name}
                  control={control}
                  rules={{ ...rules }}
                  render={({
                    field: { onChange, value: fieldValue, ref, props }
                  }) => (
                    <InputGroup>
                      <Form.Control
                        {...props}
                        onChange={onChange}
                        value={fieldValue || ''}
                        ref={ref}
                        type={type}
                        placeholder=""
                        id={name}
                        {...restProps}
                        {...inputProps}
                      />
                      {helperIcon && (
                        <button className="helper-icon" type="button">
                          <i className={`icon-${helperIcon}`} />
                        </button>
                      )}
                    </InputGroup>
                  )}
                />

                <FormErrorMessage
                  error={getFormErrorMessage(errors, fieldArrayName, name)}
                />
              </div>
            )}
          </Form.Group>
        </div>
      )}
    </ConnectForm>
  );
}

NormalInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  bgColor: PropTypes.bool,
  viewOnly: PropTypes.bool,
  rules: PropTypes.any,
  helperIcon: PropTypes.string,
  fieldArrayName: PropTypes.any
};

NormalInput.defaultProps = {
  label: '',
  type: 'text',
  name: 'text',
  bgColor: false,
  viewOnly: false,
  helperIcon: '',
  fieldArrayName: null,
  rules: null
};
