import { loaders } from '../service/actionType'

const initialState = {
    isAppFetching : false,
    isOrdersListFetching: false,
    isFetchingProfileDetails: false,
    isTableDetailsFetching: false,
    showStaffDetails: false,
    isShopDetailFetching: false,
    isOrdersListLoading: false,
    isSelectedBookingLoading:false,
    isProductsListFetching: false,

    isDashboardCountFetching: false,
    isTopCustomerFetching: false,
    isShopAnalyticsFetching: false,
    isOrdersStatsFetching: false,

    isDeliveryPersonPastOrdersFetching: false,
    isProductProfitListFetching: false

}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case loaders.IS_PRODUCT_PROFIT_LIST_FETCHING: 
            return {
                ...state,
                isProductProfitListFetching: payload
            }
        case loaders.IS_APP_FETCHING:
            return {
                ...state,
                isAppFetching: payload
            }
        case loaders.IS_ORDERS_LIST_FETCHING:
            return {
                ...state,
                isOrdersListFetching: payload
            }
        case loaders.IS_PRODUCTS_LIST_FETCHING:
            return {
                ...state,
                isProductsListFetching: payload
            }
        case loaders.IS_FETCHING_PROFILE_DETAILS:
            return {
                ...state,
                isFetchingProfileDetails : payload
            }
        case loaders.IS_TABLE_DETAIL_FETCHING:
            return {
                ...state,
                isTableDetailsFetching : payload
            }
        case loaders.GET_SELECTED_BOOKING_LOADING: 
            return {
                ...state,
                isSelectedBookingLoading : payload
            }
        case loaders.IS_SHOP_DETAIL_FETCHING:
            return {
                ...state,
                isShopDetailFetching : payload
            }
        case loaders.TOGGLE_STAFF_DETAILS:
            return {
                ...state,
                showStaffDetails : !state.showStaffDetails
            }
        case loaders.IS_ORDERS_LIST_FETCHING:
            return {
                ...state,
                isOrdersListLoading : payload
            }
        case loaders.DASHBOARD_COUNTS_FETCHING:
            return {
                ...state,
                isDashboardCountFetching : payload
            }
        case loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING:
            return {
                ...state,
                isTopCustomerFetching : payload
            }
        case loaders.DASHBOARD_SHOP_ANALYTICS_FETCHING:
            return {
                ...state,
                isShopAnalyticsFetching : payload
            }
        case loaders.DASHBOARD_ORDER_STATS_FETCHING:
            return {
                ...state,
                isOrdersStatsFetching : payload
            }
        case loaders.GET_PAST_DELIVERY_PERSON_ORDERS_LIST_FETCHING:
            return {
                ...state,
                isDeliveryPersonPastOrdersFetching: payload
            }
            default:
                return state
        }
    }

