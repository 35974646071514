import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useState} from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export const MonthWiseOrdersGraph = props => {
  const [value, setValue] = useState(new Date())
    
  const onChange = (value) => {
    setValue(value)
    props.onMonthChange(moment(value).format('YYYY-MM-DD'))
  }
    return (
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Orders Analytics</h5>
              <div className="ml-auto">
                <div className="toolbar d-flex flex-wrap gap-2 text-end">
                  <DatePicker 
                    type="string"
                    showMonthYearPicker={true}
                    onChange={onChange} 
                    selected={value} 
                    dateFormat="yyyy/MM"
                    placeholderText="select month"
                  />
                </div>
              </div>
            </div>
            <Row className="text-center">
              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted">No Of Orders</p>
                  <h5>{props?.monthStats?.totalNoOfOrders}</h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted ">{moment(value).format('MMM')} Month Revenue</p>
                  <h5>
                    {props?.monthStats?.totalRevenue}
                  </h5>
                </div>
              </Col>
            </Row>
            <div id="area-chart" dir="ltr" className="mt-2">
              <ReactApexChart
                options={{
                  chart: {
                    height: 350,
                    type: "area",
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [ "#f1b44c","#556ee6"],
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "smooth",
                    width: 2,
                  },
                  fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: 1,
                      inverseColors: false,
                      opacityFrom: 0.45,
                      opacityTo: 0.05,
                      stops: [20, 100, 100, 100],
                    },
                  },
                  xaxis: {
                    categories: props?.monthStats?.labels,
                  },
                
                  markers: {
                    size: 3,
                    strokeWidth: 3,
                
                    hover: {
                      size: 4,
                      sizeOffset: 2,
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                  },
                }}
                series={[
                  {
                    name: "No Of Orders",
                    data: props?.monthStats?.noOfOrdersInMonth,
                  },
                  {
                    name: "Revenue",
                    data: props?.monthStats?.monthTotalSales,
                  },
                ]}
                type="area"
                height={350}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
    )
}