import React, { Component } from 'react'
import { Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';

import nobooking from 'assets/images/nobooking.png'
import * as deliveryBoyAction from "actions/deliveryboy";
import "assets/css/main.scss";
import { CreateModel } from "components/Model"
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";

class AppListClass extends Component {
    state = {
        statusList: [
            {
                label: "pickup",
                value: "PICKEDUP",
                disabled: false
            },
            {
                label: "Delivered",
                value: "DELIVERED",
                disabled: false
            }
        ],
        paymentMethodOptions: [
            {
                label: "Cash",
                value: "CASH",
                disabled: false
            },
            {
                label: "Online",
                value: "ONLINE",
                disabled: false
            }
        ],
        paymentStatusOptions: [
            {
                label: "Paid",
                value: 1,
                disabled: false
            },
            {
                label: "Not Paid",
                value: 2,
                disabled: false
            }
        ],
        selectedStatus: null,
        paymentMethod: null,
        paymentStatus: null,
        errors: {
            selectedStatus: null
        },
        isPopupOpen: false,
        btnLoading: false,
        selectedorder: null
    }

    handlePopup = (order) => {
        this.setState({
            isPopupOpen: true,
            selectedStatus: null,
            paymentMethod: null,
            selectedorder: order
        })
    }

    toggle = () => {
        this.setState({
            isPopupOpen: !this.state.isPopupOpen,
        })
    }
    onChange = (name, value) => {
        let errorDetail = { ...this.state.errors }
        errorDetail[name] = undefined
        this.setState({
            [name]: value,
            errors: errorDetail
        })
    }
    onSubmit = async (e) => {
        e.preventDefault()
        let {
            selectedStatus,
            selectedorder,
            paymentMethod,
            paymentStatus
        } = this.state
        let validation = {
            ...inputValidations,
            ...(
                selectedStatus === 'PICKEDUP' ? { paymentMethod: undefined } : ''
            ),
            ...(
                selectedStatus === 'PICKEDUP' ? { paymentStatus: undefined } : ''
            ),
        }
        let detail = {
            selectedStatus: selectedStatus,
            paymentMethod: paymentMethod,
            paymentStatus: paymentStatus
        }
        const notValid = validate(detail, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            let payload = {
                order_Id: selectedorder._id,
                orderStatus: selectedStatus,
            }
            if (selectedStatus === "DELIVERED") {
                payload['paymentMethod'] = paymentMethod
                payload['paymentStatus'] = paymentStatus
            }
            const res = await this.props.updateOrderStatus(payload)
            if(res.response != null ){
                this.setState({
                    btnLoading: false,
                    isPopupOpen: false,
                }, () => this.props.getOrdersDetails())
            }
            
        }
    }
    render() {
        let { deliveryBoy } = this.props
        let ordersList = deliveryBoy.ordersList
        let { paymentMethodOptions, statusList, isPopupOpen, errors, selectedStatus, btnLoading, paymentMethod,paymentStatus, paymentStatusOptions } = this.state
        return (
            <>
                {ordersList.length === 0 ?
                    <Container>
                        <Row >
                            <Col md='6' lg='12' className='mt-5'>
                                <img src={nobooking} className='mx-w-100' />
                            </Col>
                        </Row>
                    </Container>
                    :
                    <>
                        <CreateModel
                            isOpen={isPopupOpen}
                            toggle={this.toggle}
                            title="Update Status"
                            className="mx-500"
                            onSubmit={this.onSubmit}
                        >
                            <form onSubmit={this.onSubmit}>
                                <Row>
                                    <Col className="col-lg-6 col-12">
                                        <FormInput
                                            type="select"
                                            label="Select Status"
                                            name="selectedStatus"
                                            options={statusList}
                                            error={errors['selectedStatus']}
                                            value={selectedStatus}
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                    {selectedStatus != null && selectedStatus === 'DELIVERED' &&
                                        <>
                                            <Col className="col-lg-6 col-12">
                                                <FormInput
                                                    type="select"
                                                    label="Select Payment Method"
                                                    name="paymentMethod"
                                                    options={paymentMethodOptions}
                                                    error={errors['paymentMethod']}
                                                    value={paymentMethod}
                                                    onChange={this.onChange}
                                                />
                                            </Col>
                                            <Col className="col-lg-6 col-12">
                                                <FormInput
                                                    type="select"
                                                    label="Select Payment Status"
                                                    name="paymentStatus"
                                                    options={paymentStatusOptions}
                                                    error={errors['paymentStatus']}
                                                    value={paymentStatus}
                                                    onChange={this.onChange}
                                                />
                                            </Col>
                                        </>
                                    }
                                </Row>
                                <Row>
                                    <Col className='col-lg-6 mx-auto'>
                                        <Button
                                            color="primary w-100 border-none"
                                            className="fw-600"
                                            type="submit"
                                            disabled={btnLoading}
                                        >
                                            update
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CreateModel>
                        {ordersList && ordersList.map((order, i) => (
                            <Container fluid className="p-0 mt-10">
                                <div className="bg-white mt-3 view-main p-4 ">
                                    <p className='mb-2'>{i + 1}.</p>
                                    <Row className="border-bottom no-gutters justify-content-between pb-2">
                                        <Col className="p-0">
                                            <h2 className="fw-700 m-0">{order?.status}</h2>
                                        </Col>
                                        <Col className="p-0">
                                            <div style={{ width: '150px', marginLeft: 'auto' }}>
                                                <Button
                                                    color="primary w-100 border-none"
                                                    className="fw-600"
                                                    type="button"
                                                    onClick={() => this.handlePopup(order)}
                                                >
                                                    Update status
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="application-detail">
                                        <h3 className="pt-3 py-2"> Order Details</h3>
                                        <Row lassName='mt-2'>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>order Id</p>
                                                <a># {order?._id?.slice(order?._id.length - 5)}</a>
                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Product Name</p>
                                                {order?.productDetails.map((pr, i) => (
                                                    <>
                                                        <Row className="no-gutters">
                                                            <a>{i + 1}. {pr.productName} x {pr.noOfItemsAdded} {pr.quantityType}</a>
                                                        </Row>
                                                        {pr.description != undefined &&
                                                            <Row className="no-gutters">
                                                                <p style={{ color: "#7e7777 !important" }}>( description - {pr.description} )</p>
                                                            </Row>
                                                        }
                                                    </>
                                                ))}

                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Total Amount</p>
                                                <a>{order?.total}</a>
                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Total Items</p>
                                                <a>{order?.totalItems}</a>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            {/* <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Delivery Charge</p>
                                                <a>{order?.deliveryCharge}</a>
                                            </Col> */}
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Ordered Received Date</p>
                                                <a>{moment(order?.createdAt).format('YYYY-MM-DD hh:mm:ss a')}</a>
                                            </Col>
                                        </Row>
                                        <h3 className="pt-3 py-2"> Shop Details</h3>
                                        <Row >
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>shop Name</p>
                                                <a>{order?.shopDetail?.shopName}</a>
                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>shop Address</p>
                                                <a>{order?.shopDetail?.shopAddress}</a>
                                                <div><a className="maplink" href={order?.shopmapLink} target="_blank">Click Here</a></div>
                                            </Col>

                                        </Row>
                                        <h3 className="pt-3 py-2"> Customer Details</h3>
                                        <Row >
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Customer Name</p>
                                                <a>{order?.name}</a>
                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Customer Mobile Number</p>
                                                <a>{order?.mobileNumber}</a>
                                            </Col>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Customer Address</p>
                                                <a>{order?.userAddress?.address}</a>
                                                <div><a className="maplink" href={order?.usermapLink} target="_blank" rel='noopener noreferrer'>Click Here</a></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-3 col-md-3 col-6">
                                                <p>Comments</p>
                                                <a>{order?.comments ? order?.comments : '---'}</a>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Container>
                        ))}
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = ({ bookings, profile, loader, deliveryBoy }) => {
    return {
        bookings,
        profile,
        loader,
        deliveryBoy
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(deliveryBoyAction, dispatch);
};

const OpenOrders = connect(mapStateToProps, mapDispatchToProps)(AppListClass);

export default OpenOrders


let inputValidations = {
    selectedStatus: {
        presence: {
            allowEmpty: false,
            message: "^status can't be blank"
        }
    },
    paymentMethod: {
        presence: {
            allowEmpty: false,
            message: "^Payment Method can't be blank"
        }
    },
    paymentStatus: {
        presence: {
            allowEmpty: false,
            message: "^Payment Status can't be blank"
        }
    },
}