import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const firebaseConfig = {
    apiKey: "AIzaSyCmW7sGU2xMejtQc4ENdzuW7gu5PCM7gyQ",
    authDomain: "ninansburger.firebaseapp.com",
    projectId: "ninansburger",
    storageBucket: "ninansburger.firebasestorage.app",
    messagingSenderId: "102256302059",
    appId: "1:102256302059:web:c97fe7b1e19c48559bebae",
    measurementId: "G-W08C9J4V1Y"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);



export const requestForToken = async () => {
    try {
        const isGranted = await Notification.requestPermission()
        console.log('isGranted', isGranted)
        if (isGranted === "granted") {
            const currentToken = await getToken(messaging, { vapidKey: "BEJxghMNPLyvWhnhznQgnMF2YkW1vNlv85_Vdg6Xka0Ls-_92mlTwSNxsGY_ds8QHB4zdKpRpb3xEbPOm0N159k" });
            console.log('currentToken,', currentToken)
            if (currentToken) {
                return currentToken;
            } else {
                console.error('No registration token available.');
            }
        }
    } catch (error) {
        console.error('An error occurred while retrieving token.', error);
    }
};

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("--onMessage--", messaging)
            const audio = new Audio('/audio/vendor_notify.wav');
            audio.play();
            const notificationOptions = {
                body: payload.notification.body,
                icon: payload.notification.icon,
                sound: "/audio/vendor_notify.wav",  // Optional sound
            };

            // Show browser notification
            if (Notification.permission === "granted") {
                new Notification(payload.notification.title, notificationOptions);
            }

            resolve(payload)
        });
    });
}

export default firebaseApp;