import { useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Avatar } from 'components/Avatar';
import useMediaQuery from 'hooks/useMediaQuery';
import { logout ,ternaryCondition} from 'helpers/utils';
import styles from './ProfileDropdown.module.scss';
import { MdLogout } from 'react-icons/md';
import { Logout } from 'components/Logout';

export const ProfileDropdown = (props) => {
  const menuRef = useRef(null);
  const [showLogout, toggleLogout] = useState(false)

  const isMobile = useMediaQuery('(max-width: 550px)');

  const handleClose = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        if (menuRef.current.style.opacity === '') {
          menuRef.current.style.opacity = 1;
        }
      }, 200);
    }
  };

  const getFirstName = () => {
    return props?.profile?.name ? props?.profile?.name.charAt(0).toUpperCase() + props?.profile?.name.slice(1) : ''
  }
  const getLastName = () => {
    const lastName = props?.profile?.name?.split(' ')
    return lastName ? ternaryCondition(lastName?.length > 1, lastName[lastName.length - 1], '') : ''
  }
  return (
    <div className={`${styles.Wrapper} ${props?.className}`}>
      <Dropdown className={styles.Block} onToggle={handleClose}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className={`${styles.Toggle} position-relative`}
          title="My Profile"
          aria-label="Profile Icon"
        >
          <Avatar firstName={getFirstName()} lastName={getLastName()} isSmall={isMobile} />
          <span className={styles.PersonName}>{getFirstName()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.Menu} align="end" ref={menuRef}>
          <div className={styles.Logout_Container}>
            <button
              className="btn no-style"
              onClick={() => toggleLogout(!showLogout)}
              title="Logout"
              type="button"
            >
              <MdLogout name="logout" className="me-2" />
              Logout
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showLogout && <Logout />}
    </div>
  );
};

ProfileDropdown.propTypes = {
  className: PropTypes.string
};

ProfileDropdown.defaultProps = {
  className: ''
};
