import { main } from '../service/actionType'

const initialState = {
    adminsList: {
        list: [],
        pageMeta: {
            page: 1,
            limit: 10,
            totalPages: 1
        }
    },
    rolesList: []
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.GET_ADMINS_LIST:
            return {
                ...state,
                adminsList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.ROLES_LIST:
            return {
                ...state,
                rolesList: payload.data
            }
        default:
            return state
    }
}