import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import * as reports from "actions/reports";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSelect from 'components/inputs/ReactSelect';
import Topbar from 'components/topbar';
import { CustomDatePicker } from 'components/inputs/Datepicker';
import { ReportWrapper } from 'components/reportWrapper';
import axios from 'axios';
import moment from 'moment'
import { Toast } from 'helpers/Toast';
import config from '../../config';

const ReportsFunc = (props) => {
    const [reportsList, setReports] = useState([])
    const [mastersList, setMasters] = useState({})

    const onChange = (name, value, index) => {
        let reports = [...reportsList]
        let report = reports[index]
        const filterIndex = report.filters.findIndex(fl => fl.value === name)
        reports[index]["filters"][filterIndex][name] = value
        setReports(reports)
    };

    useEffect(() => {
        getReportsList()
    }, [])

    const getReportsList = async () => {
        let response = await props.reportAction.getReportsList()
        console.log('response',response)
        setReports(response.reports)
        setMasters(response)
    }

    const validateReport = (filters) => {
        let isValid = true;
        let errorMessage = '';

        filters.forEach((obj) => {
          if (obj.required) {
                if (obj[obj.value] === null || obj[obj.value] === '') {
                    errorMessage += `${obj.label}, `;
                    isValid = false;
                }
            }
        });

        if (errorMessage !== "") {
            Toast({
                type: 'error',
                message: `${errorMessage.slice(0, -2)} is required`,
            });
        }
        return isValid;
    }

    const download = async (report) => {
        const valid = validateReport(report.filters)
        if (valid) {
            const queryParams = {}
            report.filters.forEach(obj => {
                if(obj.value === "startDate" || obj.value === 'endDate'){
                    queryParams[obj.value] = moment(obj[obj.value]).format('YYYY-MM-DD')
                } else {
                    queryParams[obj.value] = obj[obj.value]?.value
                }
            })
            try {
                const response = await axios.get(`${config.api.BASE_URL}/api/admin/reports${report.apiUrl}?${queryString.stringify(queryParams)}`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: localStorage.getItem('token'),
                    },
                });
                const blob = new Blob([response.data])
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${report.title} ${moment(queryParams.startDate).format('YYYY-MM-DD')} to ${moment(queryParams.endDate).format('YYYY-MM-DD hh:mm:ss')}.xlsx`; // Use the extracted filename
                link.click();

                URL.revokeObjectURL(blobUrl);
            } catch (error) {
                Toast({
                    type: 'error',
                    message: 'No data found',
                });
            }
        }
    }

    return (
        <>
            <Topbar
                pageName="Reports"
                createBtnLabel=""
                showBtn={false}
            />
            <div className='mt-3'>
                {reportsList.map((report, i) => (
                    <div className='mb-4'>
                        <ReportWrapper
                            reportTitle={report?.title}
                            download={() => download(report)}
                        >
                            <div className='row'>
                                {report.filters.map((filter) => (
                                    <>
                                        {filter.value === "startDate" &&
                                            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect mb-3">
                                                <CustomDatePicker
                                                    name="startDate"
                                                    label="Choose Start Date"
                                                    placeholder="Choose Start Date"
                                                    onChange={(...params) => onChange(...params, i)}
                                                    value={filter["startDate"]}
                                                />
                                            </div>
                                        }
                                        {filter.value === "endDate" &&
                                            <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect mb-3">
                                                <CustomDatePicker
                                                    name="endDate"
                                                    label="Choose End Date"
                                                    placeholder="Choose End Date"
                                                    onChange={(...params) => onChange(...params, i)}
                                                    value={filter["endDate"]}
                                                />
                                            </div>
                                        }
                                        {filter.value === "shopId" &&
                                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect mb-3">
                                            <ReactSelect
                                                onChange={(...params) => onChange(...params, i)}
                                                options={mastersList?.shopsMaster}
                                                name={"shopId"}
                                                label="Select Outlet"
                                                value={filter["shopId"]}
                                                isMulti={false}
                                                isClearable={true}
                                                placeholder={"Select Shop"}
                                            />
                                        </div>
                                        }
                                        
                                    </>
                                ))}
                            </div>
                        </ReportWrapper>
                    </div>
                ))}
            </div>
        </>
    );
};


const mapStateToProps = ({ loader }) => {
    return {
        loader,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reportAction: bindActionCreators(reports, dispatch),
    }
};
const Reports = connect(mapStateToProps, mapDispatchToProps)(ReportsFunc)

export { Reports }