import React, { Component } from 'react'
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as admins from "actions/admins";
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { API, API_BOOK } from "service";
import ReactSelect from 'components/inputs/ReactSelect'
import { history } from 'helpers';
import Topbar from 'components/topbar';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';
import { Toast } from 'helpers/Toast'
import Loader from 'components/loader/loader';

const defaultUserDetail = {
    _id: "",
    userName: "",
    mobileNumber: "",
    password: "",
    emailId: "",
    roleId: "",
    shopId: ""
}
class CreateAdminClass extends Component {

    state = {
        activeTab: [1],
        adminDetail: {
            _id: null,
            userName: null,
            mobileNumber: null,
            password: null,
            roleId: null,
            shopId: null
        },
        isUploadingImage: false,
        errors: {},
        btnLoading: false,
        rolesList: [],
        shopsDropdown: [],
        isEdit: false
    }
    async componentDidMount() {
        const _id = this.props.match.params.userMasterId
        if (_id != undefined) {
            this.getUserById(_id)
        }
        await this.getRolesList()
        await this.getShopsDropdown()
    }
    getRolesList = async () => {
        try {
            const { ROLES_LIST_API } = API_BOOK.MAIN_APP
            const requestPayload = {
                ...ROLES_LIST_API
            }
            const response = await API(requestPayload)
            if (response.status == 200) {
                this.setState({
                    rolesList: response.data.data
                })
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
        }
    }

    async getUserById(_id) {
        try {
            this.setState({ isLoading: true })
            let { GET_ADMIN_BY_ID } = API_BOOK.MAIN_APP
            let requestPayload = {
                ...GET_ADMIN_BY_ID,
                params: {
                    _id: _id
                }
            }
            let response = await API(requestPayload)
            if (response.status === 200) {
                let result = response.data.data
                const formatedDetail = {
                    ...result,
                    // roleId: { label: result?.roleDetail?.roleName, value: result?.roleDetail?._id },
                    // shopId: { label: result?.shopDetail?.shopName, value: result?.shopDetail?.shopId }
                }
                this.setState({ adminDetail: formatedDetail })
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
        } finally {
            this.setState({isEdit: true, isLoading: false})
        }
    }
    getShopsDropdown = async () => {
        try {
            const { SHOPS_DROPDOWN_LIST_API } = API_BOOK.MAIN_APP
            this.setState({ btnLoading: true })
            const requestPayload = {
                ...SHOPS_DROPDOWN_LIST_API
            }
            const response = await API(requestPayload)
            if (response.status === 200) {
                this.setState({
                    shopsDropdown: response.data.data,
                    btnLoading: false
                })
            } else {
                this.setState({ btnLoading: false, shopsDropdown: [] })
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
        }
    }
    onChange = (name, value) => {
        let { adminDetail, errors } = this.state
        adminDetail[name] = value
        errors[name] = undefined
        this.setState({ adminDetail: adminDetail, errors: errors })
    };

    onSubmit = async () => {
        let {
            adminDetail,
            isEdit
        } = this.state
        let validation = {
            ...inputValidations,
            ...(
                isEdit ? {password: undefined} : { password: inputValidations.password }
            )
        }
        console.log('validation',validation)
        const notValid = validate(adminDetail, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            console.log('adminDetail', adminDetail)
            let payload = {
                "userName": adminDetail.userName,
                mobileNumber: adminDetail.mobileNumber,
                password: adminDetail.password,
                roleId: adminDetail.roleId?.value,
                emailId: adminDetail?.emailId,
            }
            if (adminDetail.roleId?.data?.roleId === 2) {
                payload.shopId = adminDetail.shopId?.value
            }
            let response = null;
            if(isEdit){
                payload._id = adminDetail?._id
                response = await this.props.updateUser(payload)
            } else {
                response = await this.props.createUser(payload)
            }
            if (response.status) {
                this.setState({
                    adminDetail: {...defaultUserDetail}
                })
            }

        }
    }

    onSelect = (name, value) => {
        this.setState(
            {
                [name]: value,
                page: 1,
            },
            this.getApplicationList
        );
    }




    render() {
        let { adminDetail, errors, isLoading, rolesList, shopsDropdown, isEdit } = this.state
        return (
            <>
                <Topbar
                    pageName={`${isEdit ? 'Edit' : 'Create'} User`}
                    showBtn={false}
                    createBtnLabel="Create User"
                    onPressCreateBtn={() => null}
                />
                {isLoading
                    ?
                    <Loader className="h-100" />
                    :
                    <div className='mt-3'>
                        <Card>
                            <CardBody>
                                <Row className="no-gutters mb-4 mt-2">
                                    <Col className="col-lg-12">
                                        <Row>
                                            <Col className="col-lg-4 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Name`}
                                                    name="userName"
                                                    onChange={this.onChange}
                                                    value={adminDetail["userName"]}
                                                    error={errors["userName"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-4 col-12">
                                                <FormInput
                                                    type="mobile"
                                                    label={`Mobile Number`}
                                                    name="mobileNumber"
                                                    onChange={this.onChange}
                                                    value={adminDetail["mobileNumber"]}
                                                    error={errors["mobileNumber"]}
                                                    max={10}
                                                />
                                            </Col>
                                            <Col className="col-lg-4 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Email Id`}
                                                    name="emailId"
                                                    onChange={this.onChange}
                                                    value={adminDetail["emailId"]}
                                                    error={errors["emailId"]}
                                                />
                                            </Col>
                                            {!isEdit &&
                                                <Col className="col-lg-4 col-12">
                                                    <FormInput
                                                        type="text"
                                                        label={`Password`}
                                                        name="password"
                                                        onChange={this.onChange}
                                                        value={adminDetail['password']}
                                                        error={errors["password"]}
                                                    />
                                                </Col>
                                            }
                                            <Col className="col-lg-4 col-12 custom-reselect">
                                                <ReactSelect
                                                    type="select"
                                                    label="Select Role"
                                                    name="roleId"
                                                    options={rolesList}
                                                    value={adminDetail["roleId"]}
                                                    onChange={this.onChange}
                                                    error={errors["roleId"]}
                                                />
                                            </Col>
                                            {adminDetail["roleId"]?.data?.roleId === 2 &&
                                                <Col className="col-lg-4 col-12 custom-reselect">
                                                    <ReactSelect
                                                        type="select"
                                                        label="Select Shop"
                                                        name="shopId"
                                                        options={shopsDropdown}
                                                        value={adminDetail["shopId"]}
                                                        onChange={this.onChange}
                                                        error={errors["shopId"]}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <FormSubmissionBtns
                                    onSubmit={this.onSubmit}
                                    onCancel={() => history.goBack()}
                                />
                            </CardBody>
                        </Card>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = ({ admins, profile, loader }) => {
    return {
        admins,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(admins, dispatch);
};

const CreateAdmin = connect(mapStateToProps, mapDispatchToProps)(CreateAdminClass);

export { CreateAdmin }


let inputValidations = {
    roleId: {
        presence: {
            allowEmpty: false,
            message: "^Role can't be blank"
        }
    },
    userName: {
        presence: {
            allowEmpty: false,
            message: "^Name can't be blank"
        }
    },
    password: {
        presence: {
            allowEmpty: false,
            message: "^password can't be blank"
        }
    },
    emailId: {
        presence: {
            allowEmpty: false,
            message: "^Email Id can't be blank"
        }
    },
    mobileNumber: {
        presence: {
            allowEmpty: false,
            message: "^ Mobile Number can't be blank"
        },
        numericality: {
            onlyInteger: true
        },
    }


}