import { createStore, applyMiddleware, compose } from "redux";
import { reducers } from "../reducer";
import thunk from 'redux-thunk';
import { API, API_BOOK } from 'service'
import {Toast} from 'helpers/Toast';
import moment from 'moment';
import { ternaryCondition } from "./utils";

export const store = createStore(reducers, compose(
    applyMiddleware(
        thunk.withExtraArgument({
            API,
            API_BOOK,
            Toast
        })
    ),
    window.devToolsExtension ? window.devToolsExtension() : f => f
));

export const history = require("history").createBrowserHistory()


export const ThemeContext = require('react').createContext(
    
);

export const toggleRootClassName = () => {
    var element = document.getElementsByTagName('body')[0];
    element.classList.toggle("open-sidebar");
}

export const formatDate = (value) => {
    return moment(value).format("YYYY-MM-DD hh:mm:ss a")
}

export const getPageName = (page) => {
  return (
    <h3 className="title">{page}</h3> 
  )
}
export function validateUploadSize(file) {
    if (!file) return false
    let FileSize = file.files[0].size / 1024 / 1024; // in MB
    console.log('fiel', FileSize)
    return FileSize <= 5
}
export const getFormErrorMessage = (errors, fieldArrayError, name) => {
    if (!name || !errors) return null;
  
    if (!fieldArrayError && name) {
      return errors[name];
    }
  
    return (
      !!errors[fieldArrayError.arrayName] &&
      errors[fieldArrayError.arrayName][fieldArrayError.index] &&
      typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
        fieldArrayError.fieldName
      ] !== 'undefined' &&
      errors[fieldArrayError.arrayName][fieldArrayError.index][
        fieldArrayError.fieldName
      ]
    );
};

export const handleTableCellData = (keyValue, defaultValue, className = '') => {
  return (
    <td className={`${className}`}>
      {ternaryCondition(keyValue, keyValue, defaultValue)}
    </td>
  );
};