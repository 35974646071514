import { CreateModel } from "components/Model"
import { logout } from "helpers/utils"
import { useState } from "react"
import { Button } from "reactstrap"

export const Logout = () => {
    const [showLogout, toggle] = useState(true)

    return (
        <CreateModel
            isOpen={showLogout}
            toggle={() => toggle(false)}
            title="Logout"
            className="mx-500"
        >
            <div >
                <h6>Are you sure you want to Logout</h6>
                <div className='delete_btns'>
                    <Button
                        color="grey border-none"
                        className="fw-600 mt-2"
                        onClick={() => toggle(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary border-none"
                        className="fw-600 mt-2"
                        onClick={() => logout()}
                    >
                        Confirm
                    </Button>
                </div>

            </div>
        </CreateModel>
    )
}