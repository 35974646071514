import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import { ORDER_STATUS } from 'helpers/variables.js'
import { CustomerOrdersHistoryColumns } from 'helpers/tableDataMapper'
import * as customer from "actions/customer";
import { SearchWrapper } from "components/Table/SearchWrapper";

import { CustomDatePicker } from 'components/inputs/Datepicker';
import FormInput from 'components/inputs/FormInput';
import { TableWrapper } from 'components/TableWrapper';
import { formatDate, handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';

const defaultPayload = {
    // search: '',
    startDate: '',
    endDate: '',
    page: 1,
    limit: 50
}

class OrderHistoryClass extends Component {
    state = {
        queryPayload: {
            // search: null,
            startDate: null,
            endDate: null,
            page: 1,
            limit: 50,
            _id: null
        }
    }

    componentDidMount() {
        let _id = this.props.match.params._id
        let { queryPayload } = this.state
        queryPayload["_id"] = _id;
        this.setState({ queryPayload }, () => this.getApplicationList(1))
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState(
            {
                queryPayload
            }
        );
    };

    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload }, () => this.getApplicationList(page));
    };

    getApplicationList = (page) => {
        let { queryPayload } = this.state
        const payload = {
            ...queryPayload,
            page: page
        }

        if (queryPayload.startDate) {
            payload["startDate"] = moment(queryPayload.startDate).format('YYYY-MM-DD')
        }

        if (queryPayload.startDate) {
            payload["endDate"] = moment(queryPayload.endDate).format('YYYY-MM-DD')
        }

        this.props.getCustomerHistory(payload)
    };

    clearFilters = () => {
        this.setState({
            queryPayload: { ...this.state.queryPayload, ...defaultPayload }
        }, () => this.getApplicationList(1))
    }

    render() {
        let { customers, loader } = this.props
        let { queryPayload } = this.state
        return (
            <div>
                <SearchWrapper
                    applyFilter={() => this.getApplicationList(1)}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="startDate"
                                label="Choose Date"
                                placeholder="Choose Start Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["startDate"]}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 custom-reselect col-12 mb-3">
                            <CustomDatePicker
                                name="endDate"
                                label="Choose End Date"
                                placeholder="Choose End Date"
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["endDate"]}
                            />
                        </div>
                        {/* <div className='col-lg-3 col-xl-3 col-md-4 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div> */}
                    </div>
                </SearchWrapper>
                <TableWrapper
                    headerDetails={CustomerOrdersHistoryColumns}
                    pageMeta={customers?.customerHistory?.pageMeta}
                    listLength={customers?.customerHistory?.list || []}
                    isLoading={loader.isTableDetailsFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {customers?.customerHistory?.list?.map((item, index) => {
                        const { itemTotal = "", orderItems = [], totalAmount = '', _id = "", createdAt = '', updatedAt = '', status = '', deliveryCharge = '' } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        customers?.customerHistory?.pageMeta?.page,
                                        customers?.customerHistory?.pageMeta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(_id.slice(_id.length - 6), '-', 'text-left')}
                                <td>
                                    {orderItems && orderItems.map(pr => (
                                        <p>{pr?.productName} x {pr?.quantity} </p>
                                    ))}
                                </td>
                                {handleTableCellData(itemTotal ? itemTotal.toFixed(2) : 0, '-', 'text-center')}
                                {handleTableCellData(deliveryCharge, '-', 'text-center')}
                                {handleTableCellData(totalAmount ? totalAmount.toFixed(2) : 0, '-', 'text-center')}
                                <td>
                                    {
                                        status === ORDER_STATUS.ORDER_CREATED ? <p className='table_order_status unapproved_item'>Order Created</p> :
                                            status === ORDER_STATUS.CONFIRMED ? <p className='table_order_status confirmed_item'>Confirmed</p> :
                                                status === ORDER_STATUS.PICKEDUP ? <p className='table_order_status picked_item'>Pickedup</p> :
                                                    status === ORDER_STATUS.DELIVERED ? <p className='table_order_status active_item'>Delivered</p> :
                                                        status === ORDER_STATUS.CANCELLED ? <p className='table_order_status cancelled_item'>Cancelled</p> :
                                                            <p>--</p>
                                    }
                                </td>
                                {handleTableCellData(formatDate(createdAt), '-', 'text-center')}
                                {handleTableCellData(formatDate(updatedAt), '-', 'text-center')}
                            </tr>
                        );
                    })}
                </TableWrapper>
            </div>
        );
    }
}

const mapStateToProps = ({ customers, profile, loader }) => {
    return {
        customers,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(customer, dispatch);
};

const OrderHistory = connect(mapStateToProps, mapDispatchToProps)(OrderHistoryClass);

export default OrderHistory