import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormGroup, Label } from 'reactstrap'
import './index.scss';

export const CustomDatePicker = (props) => {
    return (
        <FormGroup>
            {props.label &&
                <Label>
                    {props.label}
                </Label>
            }
            <DatePicker
                className='form-control'
                onChange={(date) => props.onChange(props.name, date)}
                selected={props.value}
                placeholderText={props.placeholder}
            />
            {(props.spanText !== '' && props.spanText !== undefined)? <label>{props.spanText}</label> : ''}
            <strong className="text-danger small">
                {props.error ? props.error[0] : ''}
            </strong>
        </FormGroup>

    );
}