import React, { Component } from 'react'
import { Container,Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as deliveryBoyAction from "actions/deliveryboy";
import "assets/css/main.scss";
import { ORDER_ASSIGNED_TABS  } from 'helpers/variables'
import  BasicTab  from "components/Tabs/BasicTab";
import OpenOrders from './statusTabs/OpenOrders';
import DeliveredOrders from './statusTabs/DeliveredOrders';
class AppListClass extends Component {
    state = {
        status: [1],
    }
    componentDidMount() {
        this.getOrdersList()
    }
    
    getOrdersList = () => {
        this.props.getOrdersDetails();
    };
    onChange = (name, value) => {
        let val = value[0]
        if(val === 1){
            this.getOrdersList()
        } else {
            this.props.getPastOrders()
        }
        this.setState({
            [name]: value
        })
    }
    
    getComponentBasedOnStatus = (status) => {
        let component = ''
        switch(status[0]){
            case 1: 
                component = OpenOrders
                break;
            case 3: 
                component = DeliveredOrders
                break;
        }
        return component
    }
    render() {
        let {deliveryBoy} = this.props
        let {status} = this.state
        let AddComponent = this.getComponentBasedOnStatus(status)
        return (
            <>
            <Container fluid className="p-0 mt-10">
                <BasicTab
                    tabs={ORDER_ASSIGNED_TABS}
                    name="status"
                    onChange={this.onChange}
                    activeTab={status}
                />
                <Row noGutters>
                    <Col>
                        {/* {loader.isOrdersListLoading ? 
                            <FormLoader/> 
                            : */}
                            <AddComponent {...this.state} {...this.props} updatestatus={this.updatestatus}/>
                        {/* } */}
                    </Col>
                </Row>
            </Container>
            </> 
        );
    }
}

const mapStateToProps = ({ bookings, profile,loader,deliveryBoy }) => {
    return {
        bookings,
        profile,
        loader,
        deliveryBoy
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(deliveryBoyAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppListClass);


let inputValidations = {
    selectedStatus: {
        presence: {
            allowEmpty: false,
            message: "^status can't be blank"
        }
    }
}