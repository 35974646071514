import { main } from '../service/actionType'

const initialState = {
    ordersList: {
        list : [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        }
    },
    orderDetail: null,
    selectedBookingId: null,
    orderDetailProductList: []
}

const Orders = (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.GET_ORDERS_LIST:
            return {
                ...state,
                ordersList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.GET_ORDER_DETAIL_PRODUCT_LIST:
            return {
                ...state,
                orderDetailProductList: payload.productsList,
            }
        case main.BOOKING_DETAIL_BY_ID: {
            return {
                ...state,
                orderDetail: payload
            }
        }
        case main.SELECTED_BOOKING_ID: {
            return {
                ...state,
                selectedBookingId: payload.orderId
            }
        }
        case main.RESET_SELECTED_BOOKINGID: {
            return {
                ...state,
                selectedBookingId: null
            }
        }
        
        // case main.GET_SHOP_CATEGORY_DETAIL:
        //     return {
        //         ...state,
        //         shopCategory: payload.shopCategory
        //     }
        // case main.GET_PRODUCT_DETAIL:
        //     return {
        //         ...state,
        //         shopProducts: {
        //             filters: payload.shopProducts.filters,
        //             products: payload.shopProducts.products
        //         }
        //     }
        default:
            return state
    }

}

export default Orders