import { main } from '../service/actionType'

const initialState = {
    cityTableList: {
        data: [],
        meta: {
            page: 1,
            totalPages: 0
        }
    },
    cityDetail: {
        isActive: false,
        _id: null,
        cityId: null,
        cityName: null,
        createdAt: null,
        updatedAt: null,
        cafeteria: [],
    },
    cityList: [],
    locationList:[]
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.CITY_TABLE_LIST:
            return {
                ...state,
                cityTableList: {
                    data:payload.data,
                    meta:payload.meta,
                } ,
            }
        
        case main.LOCATION_LIST:
            return {
                ...state,
                locationList:payload,
            }
        
        case main.CITY_LIST:
            return {
                ...state,
                cityList:payload
            }
        
        case main.LOCATION_FORM_RESET:
            return {
                ...state,
                locationDetail: {
                    isActive: false,
                    _id: null,
                    cityId: null,
                    cityName: null,
                    createdAt: null,
                    updatedAt: null,
                    cafeteria: [],
                }
            }
        
        case main.CITY_FORM_CREATE_OR_UPDATE:
            let cityDetail = payload.formatedDetail
            return {
                ...state,
                cityDetail: {
                    ...payload.formatedDetail,
                    cafeteria:[]
                }
                
            }
            
        
        default:
            return state
    }
}