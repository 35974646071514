import React from "react";
import { Button, Row, Col, Modal, ModalBody, ModalFooter,ModalHeader } from 'reactstrap';


const CreateModel = ({isOpen, toggle, title,children,className,...rest }) =>{
    return(
        <Modal isOpen={isOpen} toggle={toggle} className={className} centered={true}>
            <ModalHeader>
                <h2 className="fw-800">{title}</h2>
            </ModalHeader>
            <ModalBody className="py-3 px-4" >
            {children}
            </ModalBody>
            <ModalFooter>
            
            </ModalFooter>
        </Modal>
    )
}

export {CreateModel};
