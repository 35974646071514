
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from '../helpers/Toast'

export const getShopPaymentList = () => async (dispatch,getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true})
    let { GET_SHOP_PAYMENT_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_SHOP_PAYMENT_LIST_API
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.GET_SHOP_PAYMENT_LIST, payload: response.data.data })
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    }
} 

export const getCustomerPaymentList = (queryPayload) => async (dispatch,getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true})
    let { GET_CUSTOMER_PAYMENT_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_CUSTOMER_PAYMENT_LIST_API,
        params: {
            ...queryPayload
        }
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.GET_CUSTOMER_PAYMENT_LIST, payload: response.data.data.data })
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    }
} 


export const updateShopPaymentSentData = (payload) => async (dispatch,getState) => {
    let { UPDATE_PAYMENT_SENT_TOSHOP_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...UPDATE_PAYMENT_SENT_TOSHOP_API,
        data: {
            ...payload
        }
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        getShopPaymentList()(dispatch, getState)
        return {
            status: true
        }
    } else {
        Toast({ type: 'error', message: response.data.message})
        return {
            status: false
        }
    }
} 