
import { loaders, main } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

export const getNotificationsList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
        let { NOTIFICATION_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...NOTIFICATION_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.NOTIFICATION_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const createNotification = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_NOTIFICATION_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_NOTIFICATION_API,
            data: payload
        }
        let response = await API(requestPayload)
        return response

    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return null
    }
}

export const editNotification = (payload) => async (dispatch, getState) => {
    try {
        let { UDPATE_NOTIFICATION_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UDPATE_NOTIFICATION_API,
            data: payload
        }
        let response = await API(requestPayload)
        return response

    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return null
    }
}

export const sendNotification = (notificationPayload) => async (dispatch, getState) => {
    try {
        let { SEND_NOTIFICATION_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...SEND_NOTIFICATION_API,
            data: notificationPayload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: response.data?.message })
        } else {
            Toast({ type: 'error', message: response.data?.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}


export const getNotificationById = (_id) => async (dispatch, getState) => {
    try {
        let { GET_NOTIFICATION_DETAIL_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_NOTIFICATION_DETAIL_API,
            params: {
                _id: _id
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.GET_NOTIFICATION_DETAIL, payload: response.data.data })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}