import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Sidebar from 'components/Sidebar/StaffSidebar'
import Loader from '../components/loader/loader'
import * as ProfileActions from '../actions/login'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { history } from '../helpers'
import Topbar from 'components/topbar'
import useMediaQuery from 'hooks/useMediaQuery'
import useWindowSize from 'hooks/useWindowSize'
import { CustomNavbar } from 'components/Navbar'
import config from '../config';
import { io } from 'socket.io-client';

const AdminLayoutFunc = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [closeText, setCloseText] = useState('manual');
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        const socket = io(config.api.BASE_URL);
        socket.on('new-order', (orderData) => {
            const audio = new Audio('/audio/vendor_notify.wav');
            audio.play().catch((err) => console.error('Audio play error:', err));
        });

        return () => socket.disconnect();
    }, []);


    useEffect(() => {
        if(props.loader.showStaffDetails != showDetails) {
            setShowDetails(props.loader.showStaffDetails)
        }
    }, [props.loader.showStaffDetails, showDetails])
    
    const width = useWindowSize();

    const isMobile = useMediaQuery('(max-width: 768px)');

    const desktopWrapperClassName = isOpen ? '' : 'max-content-container';
    const mobileWrapperClassName = isMobile ? 'mobile-content-wrapper' : '';

    const contentWrapperClassName = isMobile
        ? mobileWrapperClassName
        : desktopWrapperClassName;

    const toggle = () => {
        setOpen(!isOpen);
    };

    useEffect(() => {
        if (width < 1150 && isOpen && closeText !== 'auto') {
            setOpen(false);
            setCloseText('auto');
        }
    }, [width, isOpen]);

    const getTogglerClassName = (role) => {
        let togglerName = ''
        if (role === "1") togglerName = 'show-details'
        else togglerName = 'show-details-md'
        return togglerName
    }


    if (props.loader.isFetchingProfileDetails) {
        return <Loader className="h-100" />
    } else {
        return (
            <div className={`staff-dashboard-container ${showDetails ? getTogglerClassName(props.profile.role) : ''} `}>
                <div className={`main-layout-wrapper ${isMobile ? 'mobile-layout' : ''}`}>
                    { isMobile && <CustomNavbar {...props}/>}
                    <div className={`layout_wrapper ${isMobile ? 'mobile-container' : ''} ${isOpen ? 'open-sidebar' : ''}`}>
                        {!isMobile && <Sidebar
                            profile={props.profile}
                            location={props.location}
                            history={props.history}
                            isOpen={isOpen}
                            isMobile={isMobile}
                            toggle={toggle}
                        />
                        }
                        <div className={`main_content ${contentWrapperClassName}`}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

let mapStateToProps = ({ profile, loader }) => {
    return {
        profile,
        loader,
    }
}

let mapDispatchToProps = (dispatch) => {
    return bindActionCreators(ProfileActions, dispatch)
}

let AdminLayout = connect(mapStateToProps, mapDispatchToProps)(AdminLayoutFunc)

export { AdminLayout }
