import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from '../helpers/Toast'

export const getBannerTableList = (queryPayload) => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  true })
    let { BANNER_TABLE_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...BANNER_TABLE_LIST_API,
        params: {
            ...queryPayload
        }
    }
    let response = await API(requestPayload)
    if(response.status == 200){
        dispatch({ type: main.BANNER_TABLE_LIST, payload: response})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    }
}

export const getBannerDropdownList = () => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
    let { BANNER_DROPDOWN_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...BANNER_DROPDOWN_LIST_API ,
    }
    let response = await API(requestPayload)
    if (response.status === 200) {
        dispatch({ type: main.BANNER_DROPDOWN_LIST, payload: response.data })
        dispatch({type:loaders.IS_TABLE_DETAIL_FETCHING,payload: false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:false})
    }
}

export const createBannerForm = (payload) => async (dispatch, getState) => {
    dispatch({type: loaders.IS_SHOP_DETAIL_FETCHING, payload:true})
    let { BANNER_CREATE_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...BANNER_CREATE_API,
        params: {
            type:payload.type.value  
        },
        data: {
            banners: payload.banners
        }
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        dispatch({type:main.BANNER_FORM_CREATE,payload:response.data})
        dispatch({type: loaders.IS_SHOP_DETAIL_FETCHING, payload:false})
        let queryPayload = {
            page: 1,
            limit: 10
        }
        getBannerTableList(queryPayload)(dispatch, getState)
        return {
            status: true
        }
    } else {
        Toast({ type: 'error', message: response.data.message})
    }
}
