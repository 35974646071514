import React, { Component,Fragment } from 'react'
import { Container,Row, Col } from "reactstrap";
import { BasicTable } from "components/Table/BasicTable";
import ApplicationFilter from 'components/Filter/ApplicationFilter'
import Pagination from "components/pagination";
import CreateApplication from 'components/buttons/createApplicationBtn'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { axios, API, API_BOOK } from "service";
import { ORDER } from 'helpers/variables.js'
import { CustomerPaymentTableList } from 'helpers/tableDataMapper'
import * as payment from "actions/payment";
import { SearchWrapper } from "components/Table/SearchWrapper";
import {FormGroup, Input, Button} from 'reactstrap'

class CustomerPaymentClass extends Component {
    state = {
        queryPayload: {
            filterName: null,
            filter: null,
            search: null,
            page: 1,
            limit: 50
        },
    }
    componentDidMount() {
        this.getApplicationList()
    }
    onChange = (name, value) => {
        let {queryPayload} = this.state
        if(name == "search"){
            queryPayload["search"] = value
        } else if(name == "filter"){
            queryPayload["filterName"] = value == null ? null : "placeId"
            queryPayload["filter"] = value?.value
            this.setState({
                selectedFilter: value
            })
        }
        this.setState({
            queryPayload
        }, this.getApplicationList)
    };
    onSelect = (name, value) => {
        this.setState(
            {
                [name]: value,
                page: 1,
            },
            this.getApplicationList
        );
    }
    nextpage = (page) => {
        let {queryPayload} = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload },this.getApplicationList)
    };
    
    getApplicationList = () => {
        let {queryPayload} = this.state
        this.props.getCustomerPaymentList(queryPayload);
    };
    
    toggleChecked = (cellContent,row, rowIndex) => {
        let data = {
            shopId: row.shopId,
            isActive: !row.isActive
        }
        this.props.toggleChecked(data)
    }
    getTableColumns = () => {
        let editObj = {
            text: "Edit",
            formatter: (cellContent, row, rowIndex) => {
                return <Button className="edit-btn" onClick={() => this.handleSelectRow(cellContent,row, rowIndex)}>Edit</Button>
            }
        }
        let selectObj = {
            text: "Is Shop Opened",
            dataField: "isActive",
            formatter: (cellContent, row, rowIndex) => {
                return (
                    <FormGroup>
                        <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="switch"
                            checked={cellContent}
                            onChange={() => this.toggleChecked(cellContent, row, rowIndex)}
                        />
                        <label className="custom-control-label" htmlFor="switch">
                            {cellContent ? 'ON' : 'OFF'}
                        </label>
                    </FormGroup>
                )
            }
        }
        let newtable = [editObj,selectObj, ...CustomerPaymentTableList]
         return newtable
    }
    render() {
        let {payment, loader} = this.props
        let {queryPayload} = this.state
        let tableData = CustomerPaymentTableList
        return (
                <div className="mx-1">
                    <Container fluid className="p-0">
                        <Row noGutters>
                            <Col md="12">
                                <SearchWrapper 
                                    rows = {payment.customerPaymentList?.data}
                                    columns = {tableData}
                                    isLoading={loader.isTableDetailsFetching}
                                    keyField = "customerPaymentId"
                                />
                                <div className="pt-3">
                                    <Pagination 
                                        pageCount={payment.customerPaymentList?.meta.totalPages} 
                                        page={queryPayload["page"]}
                                        onPageChange={this.nextpage}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
        );
    }
}

const mapStateToProps = ({ payment, profile,loader }) => {
    return {
        payment,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(payment, dispatch);
};

const CustomerPayment = connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentClass);

export {CustomerPayment}