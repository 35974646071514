import React from 'react'
import { Row, Col, Button } from "reactstrap";
import FormInput from 'components/inputs/FormInput'
import ReactSelect from 'components/inputs/ReactSelect'
import _ from 'lodash'

const OrderDetails = ({ loader, orderDetail, statusMaster, onSubmit, onChange }) => {
    return (
        <form onSubmit={onSubmit}>
            <Row>
                <Col className="col-lg-6 pt-1 col-12">
                    <FormInput
                        type="text"
                        label={`Order Id`}
                        name="orderId"
                        disabled={true}
                        value={orderDetail?._id}
                    />
                    <FormInput
                        type="text"
                        label={`Order Amount`}
                        name="itemTotal"
                        disabled={true}
                        value={orderDetail?.itemTotal?.toFixed(2)}
                    />
                    <FormInput
                        type="text"
                        label={`Gst`}
                        name="gst"
                        disabled={true}
                        value={orderDetail?.gst?.toFixed(2)}
                    />
                    <FormInput
                        type="text"
                        label={`Total Amount`}
                        name="totalAmount"
                        disabled={true}
                        value={orderDetail?.totalAmount?.toFixed(2)}
                    />
                    <FormInput
                        type="detail"
                        label={`Product Details`}
                        details={orderDetail?.orderDetails}
                    />
                    <ReactSelect
                        type="select"
                        label="status"
                        name="status"
                        options={statusMaster}
                        value={orderDetail?.status}
                        onChange={onChange}
                    />
                </Col>
                <Col className="col-lg-6 pt-1 col-12">
                    <FormInput
                        type="text"
                        label={`Shop Name`}
                        name="shopName"
                        disabled={true}
                        value={orderDetail?.shopDetail?.shopName}
                    />
                    <FormInput
                        type="textarea"
                        label={`Shop Address`}
                        name="shopAddress"
                        disabled={true}
                        value={`No. ${orderDetail?.shopDetail?.doorNo} - ${orderDetail?.shopDetail?.address}`}
                    />
                    <FormInput
                        type="text"
                        label={`Customer Name`}
                        name="customerName"
                        disabled={true}
                        value={orderDetail?.customerName}
                    />
                    <FormInput
                        type="text"
                        label={`Customer Mobile Number`}
                        name="customerMobileNumber"
                        disabled={true}
                        value={orderDetail?.customerMobileNumber}
                    />
                    <FormInput
                        type="textarea"
                        label={`Customer Address`}
                        name="address"
                        disabled={true}
                        value={orderDetail?.userAddress?.address}
                    />
                    <FormInput
                        type="textarea"
                        label={`Comments`}
                        name="comments"
                        disabled={true}
                        value={orderDetail?.comments}
                        onChange={onChange}
                    />
                </Col>
            </Row>
            <Row >
                <Col lg="6" md="6" sm="12">
                    <Button
                        color="primary w-100 border-none"
                        className="fw-600"
                        type="submit"
                        disabled={loader.isSelectedBookingLoading}
                    >
                        Confirm
                    </Button>
                </Col>
            </Row>
        </form>
    )
}


export default OrderDetails;
