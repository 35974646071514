import React, { Component,Fragment } from 'react'
import { Container,Row, Col } from "reactstrap";
import { BasicTable } from "components/Table/BasicTable";
import ApplicationFilter from 'components/Filter/ApplicationFilter'
import Pagination from "components/pagination";
import CreateApplication from 'components/buttons/createApplicationBtn'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { axios, API, API_BOOK } from "service";
import { ORDER } from 'helpers/variables.js'
import { ShopPaymentTableList } from 'helpers/tableDataMapper'
import * as payment from "actions/payment";
import { SearchWrapper } from "components/Table/SearchWrapper";
import { Button } from 'reactstrap'
import { CreateModel } from "components/Model"
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
class ShopPaymentClass extends Component {
    state = {
        search: "",
        list: [],
        applicationCount: {},
        selectedApplicationId:null,
        page: 1,
        total_pages: 1,
        sortBy: ORDER[1].value,
        limit: 10,
        btnLoading: false,
        rowData: null,
        errors: {
            amountSent: null
        },
        payout: {
            amountSent: null,
            fromDate: null,
            toDate: null
        }
    }
    componentDidMount() {
        this.getApplicationList()
    }
    onChange = (name, value) => {
        let { payout, errors } = this.state
        payout[name] = value
        errors[name] = undefined
        this.setState({ payout: payout, errors: errors })
    };
    onSelect = (name, value) => {
        this.setState(
            {
                [name]: value,
                page: 1,
            },
            this.getApplicationList
        );
    }
    nextpage = (page) => {
        this.setState({ page }, this.getApplicationList);
    };
    
    getApplicationList = () => {
        const {
            sortBy,
            search,
            page,
            limit,
        } = this.state;
        let data = {
            page,
            sortBy,
            search,
            limit
        };
        this.props.getShopPaymentList(data);
    };
   
    handlePopup = (row) => {
        this.setState({
            isPopupOpen: true,
            rowData: row
        })
    }
    toggle = () => {
        this.setState({
            isPopupOpen: !this.state.isPopupOpen,
        })
    }
    onSubmit = async (e) => {
        e.preventDefault()
        let {
            payout, rowData
        } = this.state
        let validation = {
            ...inputValidations
        }
        const notValid = validate(payout, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            let payload = {
                shopId: rowData.shopId,
                fromDate: payout.fromDate,
                toDate: payout.toDate,
                amountEntered: parseFloat(payout.amountSent),
                totalAmount: rowData.totalAmount,
                pendingAmountToBeSent: rowData.pendingAmountToBeSent,
                amountSent: parseFloat(rowData.amountSent) + parseFloat(payout.amountSent)
            }
            let res = await this.props.updateShopPaymentSentData(payload)
            if(res.status){
                this.setState({
                    isPopupOpen: false,
                    amountSent: null,
                    btnLoading: false,
                })
            }
        
        }
    }
    render() {
        let {payment, loader} = this.props
        let {search, isPopupOpen, amountSent, btnLoading, errors, rowData, payout} = this.state
        let tableData = ShopPaymentTableList
        tableData.rows = payment.shopPaymentList
        return (
                <>
                    <CreateModel 
                        isOpen={isPopupOpen} 
                        toggle={this.toggle} 
                        title="Update Status"
                        onSubmit={this.onSubmit}
                    >
                        <form onSubmit={this.onSubmit}>
                            <Row className=" no-gutters bg-white">
                                <Col className="col-lg-12">
                                    <Row>
                                        <Col className="col-lg-3">
                                            <FormInput
                                                type="date"
                                                label={`Choose From Date`}
                                                name="fromDate"
                                                onChange={this.onChange}
                                                value={payout["fromDate"]}
                                                error={errors["fromDate"]}
                                                // disabled={role === STAFF_ROLE_ID.PUBLIC}
                                            />
                                        </Col>
                                        <Col className="col-lg-3">
                                            <FormInput
                                                type="date"
                                                label={`Choose To Date`}
                                                name="toDate"
                                                onChange={this.onChange}
                                                value={payout["toDate"]}
                                                error={errors["toDate"]}
                                                // disabled={role === STAFF_ROLE_ID.PUBLIC}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-lg-4 col-md-6 col-12">
                                            <FormInput
                                                type="percent"
                                                label={`Amount Sent`}
                                                name="amountSent"
                                                value={payout["amountSent"]}
                                                error={errors["amountSent"]}
                                                onChange={(...params) => this.onChange(...params)}
                                                max={rowData?.pendingAmountToBeSent}
                                            />
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="col-lg-4 col-md-6 col-12" >
                                            <Button 
                                                    color="primary w-100 border-none" 
                                                    className="fw-600"
                                                    type="submit"
                                                    disabled={btnLoading}
                                                >
                                                update
                                            </Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </form>
                    </CreateModel>

                    <Container fluid className="p-0 mt-10">
                        <Row noGutters className="bg-white">
                            <Col md="12" className="px-3 mt-4">
                                <BasicTable
                                    data={tableData}
                                    // activeRow={SelectedApplicationIndex}
                                    onClick={this.handlePopup}
                                    isLoading={loader.isTableDetailsFetching}
                                    className="theme-light-grey with-dot"
                                />
                                {/* <div className="pt-3">
                                    <Pagination 
                                        pageCount={total_pages} 
                                        page={page}
                                        onPageChange={this.nextpage}
                                    />
                                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </>
        );
    }
}

const mapStateToProps = ({ payment, profile,loader }) => {
    return {
        payment,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(payment, dispatch);
};

const ShopPayment = connect(mapStateToProps, mapDispatchToProps)(ShopPaymentClass);

export {ShopPayment}

let inputValidations = {
    amountSent: {
        presence: {
            allowEmpty: false,
            message: "^Amount can't be blank"
        }
    }
}