import { main } from '../service/actionType'

const initialState = {
    revenueList: [],
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.REVENUE_LIST:
            return {
                ...state,
                revenueList: payload.data
            }
        default:
            return state
    }

}