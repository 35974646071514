import React, { useState } from 'react';
import { Collapse } from 'reactstrap'
import { FaSearch } from 'react-icons/fa';
import ButtonType1 from 'components/buttons/ButtonType1';


export const SearchWrapper = ({ children, applyFilter, clearFilters }) => {
    const [col1, setCol1] = useState(false);

    return (
        <>
            <div className='filter_container'>
                <div className='filter_head' onClick={() => setCol1(!col1)}>
                    <h5>Search...</h5>
                    <div className='search_ic'>
                        <FaSearch />
                    </div>
                </div>
            </div>
            <Collapse isOpen={col1} className="accordion-collapse">
                <div className="accordion-body">
                    <div className={`filters`} >
                        {children}
                        <div className='row align-items-center mt-2'>
                            <div className='col-xl-2 col-md-3 col-lg-2 col-6'>
                                <ButtonType1
                                    onClick={() => applyFilter()}
                                    btnName="FILTER"
                                    btnClassName="apply_filter"
                                    isLoading={false}
                                />
                            </div>
                            <div className='col-xl-2 col-md-3 col-lg-2 col-6'>
                                <ButtonType1
                                    onClick={() => clearFilters()}
                                    btnName="CLEAR FILTER"
                                    btnClassName="clear_filter"
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </>
    )
}