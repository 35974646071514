import { main } from '../service/actionType'

const initialState = {
    bannerTableList: {
        data: [],
        meta: {
            page: 1,
            totalPages: 0
        }
    },
    
    bannerDropdownList: [],
    bannerDetail:{},
}

export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.BANNER_TABLE_LIST:
            return {
                ...state,
                bannerTableList: {
                    data:payload.data,
                    meta:payload.meta,
                } ,
            }
        
        case main.BANNER_DROPDOWN_LIST:
            return {
                ...state,
                bannerDropdownList:payload,
            }
        
            
        case main.BANNER_FORM_CREATE:
            
            return {
                ...state,
                bannerDetail: {
                    ...payload.formatedDetail,
                }
            }
        
        default:
            return state
    }
}