import React, { Component,Fragment } from 'react'
import { Container,Row, Col, Button } from "reactstrap";
import ApplicationFilter from 'components/Filter/ApplicationFilter'

import CreateApplication from 'components/buttons/createApplicationBtn'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BannerTableList } from 'helpers/tableDataMapper'
import * as banner from "actions/banner";
import { BasicTable } from "components/Table/BasicTable";
import FormInput from 'components/inputs/FormInput'
import { CreateModel } from "components/Model"
import UploadMultipleFile from 'components/inputs/UploadMultipleFile'
import {Toast} from "helpers/Toast";
import validate from "helpers/validation";
import { axios, API, API_BOOK } from "service";
import { SearchWrapper } from "components/Table/SearchWrapper";
import ReactSelect from 'components/inputs/ReactSelect'
import Paginate from 'components/pagination';

class AddCategoryClass extends Component {

    state = {
        queryPayload: {
            search: null,
            filterName: null,
            filter: null,
            page: 1,
            limit: 30
        },
        activeTab: [1],
        search: "",
        bannerDetails: {
            banners: [],
            type:null,
        },
        errors: {
            categoryName: null,
            categoryImageUrl: null
        },
        init: true,
        selectedShop: null,
        openCreatePopup: false,
        btnLoading: false,
        isUploadingImage: false
    }
    componentDidMount() {
        this.initialize();
        this.getApplicationList()
    }
   
    getApplicationList = () => {
        let { queryPayload } = this.state
        this.props.getBannerTableList(queryPayload)
    }
        // this.props.getOfcProductDetails()
    initialize = () => {
        this.props.getBannerDropdownList()
    }

    onChange = (name, value) => {
        let { bannerDetails } = this.state
        if (name == "type") {
            bannerDetails["type"] = value
        } else {
            bannerDetails[name] = value
        }
        this.setState({ bannerDetails: bannerDetails })
    }

    onSearch = (name, value) => {
        let {queryPayload} = this.state
        queryPayload[name] = value
        queryPayload["page"] = 1
        this.setState(
            {
               queryPayload
            },
            this.getApplicationList
        );
    }; 

    nextpage = (page) => {
        let {queryPayload} = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload }, this.getApplicationList);
    };


    onSubmit = async (e) => {
        e.preventDefault()
        let {
            bannerDetails
        } = this.state
        let validation = {
            ...inputValidations
        }
        const notValid = validate(bannerDetails, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            let payload = {
                banners: bannerDetails.banners,
                type:bannerDetails.type
            }
            this.props.createBannerForm(payload)
            bannerDetails = []
            // categoryDetails.push({...this.defaultcategoryObject})
            this.setState({
                openCreatePopup: false,
                btnLoading: false,
                bannerDetails: bannerDetails
            })
            
        }
    }
    createNewShop = () => {
        this.setState({
            openCreatePopup: !this.state.openCreatePopup
        })
    }
    onUpload = async (name, files, id) => {
        let { errors, bannerDetails } = this.state;
            errors[name] = undefined
            let formData = new FormData()
            for (var i = 0; i < files.length; i++) {
                formData.append('multipleImages', files[i])
            }
            this.setState({isUploadingImage: true})
            const { UPLOAD_MULTIPLE_DOC_API} = API_BOOK.MAIN_APP
            let requestPayload = {
                ...UPLOAD_MULTIPLE_DOC_API,
                data: formData,
            };
        let response = await API(requestPayload);
            if (response.status === 200){
                let uploadedImages = response.data
                let images = []
                uploadedImages && uploadedImages.map(im => images.push(im.imageUrl))
                let newDetail = {
                    ...bannerDetails,
                    banners: images
                }
                this.setState({
                    bannerDetails: newDetail,
                    isUploadingImage: false
                })
            }else{
                // this.setState({uploadingIndexes})
                Toast({ type: 'error', message: response.data.message})
            }
            this.setState({ isUploadingImage: false })
       
    }
    toggleChecked = (cellContent,row, rowIndex) => {
        let data = {
            shopId: row.shopId,
            categoryId: row.categoryId,
            isActive: !row.isActive
        }
        this.props.toggleCategoryChecked(data)
    }
    toggle = () => {
        let categoryDetails = [] 
        // productDetails.push({...this.defaultproductObject})
        this.setState({
            ...this.state,
            openCreatePopup: !this.state.openCreatePopup,
            categoryDetails: categoryDetails
        })
    };
    handleSelectRow = (data) => {
        let {bannerDropdownList} = this.props.banner
        let selectedType = bannerDropdownList.find(bn => bn.value == data.type)
        let payload = {
           ...data,
           type:selectedType
        }
        this.setState({
            openCreatePopup: true,
            bannerDetails: payload
        })
    };
    getTableColumns = () => {
        let editObj = {
            text: "Edit",
            headerClasses: "columnHeader",
            formatter: (cellContent, row, rowIndex) => {
                return <img src={require('../../assets/images/editIcon.svg')} className="eye-icon" onClick={() => this.handleSelectRow(row)} />
            }
        }

        let eyeObj = {
            text: "View",
            headerClasses: "columnHeader",
            formatter: (cellContent, row, rowIndex) => {
                return <img src={require('../../assets/images/eyeIcon.svg')} className="eye-icon" onClick={() => this.handleSelectRow(row)} />
            }
        }
        let newtable = [editObj, ...BannerTableList,eyeObj]
         return newtable
    }
    render() {
        let {banner,loader} = this.props
        let {errors, bannerDetails,queryPayload, isUploadingImage} = this.state
        return (
            <>
            <CreateModel 
                isOpen={this.state.openCreatePopup} 
                toggle={this.toggle} 
                onSubmit={this.onSubmit}
                title="Banner Detail"
            >
                <form onSubmit={this.onSubmit}>
                    <Row className=" no-gutters bg-white">
                        <Col className="col-lg-12">
                            <Row>
                                <Col className="col-lg-5">
                                    <ReactSelect
                                        type="select"
                                        label="Choose Banner Type"
                                        options={banner?.bannerDropdownList}
                                        value={bannerDetails["type"]}
                                        isMulti={false}
                                        isClearable={true}
                                        name="type"
                                        onChange={this.onChange}
                                        placeholder='Select the type'
                                        error={errors["type"]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6" md="4" sm="5">
                                    <UploadMultipleFile
                                        label={"Upload Banner Image"}
                                        id={0}
                                        name="bannerImageUrl"
                                        uploadedImageUrls={bannerDetails['banners']}
                                        isUploading={isUploadingImage}
                                        onChange={this.onUpload}
                                        isMult={true}
                                        error={errors['imageUrl']}
                                    ></UploadMultipleFile>
                                    </Col>
                                
                            </Row>
                        </Col>
                        </Row>
                        
                    {/* <Row className="text-right">
                        <Col> <Button color="link" onClick={() => this.onAddOrRemoveActivity()}>+ Add Category</Button></Col>
                    </Row> */}
                    <Row className="w-100 d-flex justify-content-end align-items-center px-5 ">
                        <Col className="col-lg-6">
                            <Button 
                                color="primary w-100 border-none" 
                                className="fw-600"
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Col>
                        <Col className="col-lg-6">
                            <Button 
                                type="button"
                                outline 
                                color="lighGrey-2 w-100 border-primary" 
                                className="fw-600"
                                onClick={this.toggle}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </form>
            </CreateModel>
            <div className="mx-1">
                    <Container fluid className="p-0 mt-10">
                        {/* <Row noGutters>
                            <Col md="12">
                                <CreateApplication
                                    onCreateApplicationBtnClick={this.createNewShop}
                                    btnName="Add Banner"
                                    disableCreateApp={false}
                                />
                            </Col>
                        </Row> */}
                        <ApplicationFilter
                            onSearch={(value) => this.onSearch('search', value)}
                            search={queryPayload["search"]}
                            screenName="Banner"
                            isShowAddEntity = {true}
                            handleAddEntity = {()=>{this.createNewShop()}}
                            addEntityLabel = {"Add Banner"}
                            // order={sortBy}
                            showDatePicker = {false}
                            onOrderSelect={(value) => this.onSelect('sortBy', value)}
                        />
                        <Row noGutters className="bg-white">
                            <Col md="12" className="px-3 mt-3">
                                <SearchWrapper 
                                    rows = {banner?.bannerTableList?.data}
                                    columns = {this.getTableColumns()}
                                    isLoading={loader.isTableDetailsFetching}
                                    keyField = "categoryId"
                                />
                                 {/* <Paginate
                                    pageCount={banner?.bannerTableList?.meta.totalPages} 
                                    page={queryPayload.page}
                                    onPageChange={this.nextpage}
                                    /> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            
            
        </>
        );
    }
}

const mapStateToProps = ({ banner, profile,loader }) => {
    return {
        banner,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(banner, dispatch);
};

const bannerTemplate = connect(mapStateToProps, mapDispatchToProps)(AddCategoryClass);

export { bannerTemplate }

let inputValidations = {
    type: {
        presence: {
            allowEmpty: false,
            message: "^Banner Type can't be blank"
        },
    }
}