import React, { Component,Fragment } from 'react'
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as orders from "actions/orders";
import ApplicationList from './ApplicationList'
import ApplicationDetail from './ApplicationDetail'

class OrdersClass extends Component {
    state = {
        showDetails: false
    }
    static getDerivedStateFromProps(nextProps,prevState){
        if(nextProps.loader.showStaffDetails !== prevState.showDetails)
            return {
                showDetails : nextProps.loader.showStaffDetails,
            }
        return null
    }
    render() {
        let {showDetails} = this.state
        let {loader} = this.props
        return (
            <>
                <ApplicationList 
                    toggle={() => this.props.toggleStaffDetails()}
                    isDetailMode={ showDetails } {...this.props}
                />
                <ApplicationDetail
                    toggle={() => this.props.toggleStaffDetails()}
                    showDetails={showDetails}
                />
            </>
        );
    }
}

const mapStateToProps = ({ orders, profile,loader }) => {
    return {
        orders,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(orders, dispatch);
};

const Orders = connect(mapStateToProps, mapDispatchToProps)(OrdersClass);

export {Orders}