
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

export const getOrdersList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_ORDERS_LIST_FETCHING, payload: true })
        let { GET_ORDERS_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_ORDERS_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: main.GET_ORDERS_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_ORDERS_LIST_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_ORDERS_LIST_FETCHING, payload: false })
        }
    } catch (error) {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    }
}

export const toggleStaffDetails = () => async (dispatch, getState) => {
    dispatch({ type: loaders.TOGGLE_STAFF_DETAILS })
    dispatch({ type: main.RESET_SELECTED_BOOKINGID })
}

export const getBookingDetailsFromId = (data) => async (dispatch, getState) => {
    dispatch({ type: loaders.GET_SELECTED_BOOKING_LOADING, payload: true })
    let { GET_BOOKING_DETAIL_BY_ID_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_BOOKING_DETAIL_BY_ID_API,
        params: {
            _id: data._id
        }
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        dispatch({ type: main.BOOKING_DETAIL_BY_ID, payload: response.data.data })
        // dispatch({type: main.GET_ORDER_DETAIL_PRODUCT_LIST, payload: response.data.data})
        dispatch({ type: main.SELECTED_BOOKING_ID, payload: data })
        dispatch({ type: loaders.GET_SELECTED_BOOKING_LOADING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const updateBookingDetail = (payload) => async (dispatch, getState) => {
    let { UPDATE_BOOKING_DETAIL_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...UPDATE_BOOKING_DETAIL_API,
        data: payload
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        let queryPayload = {
            page: 1,
            limit: 50
        }
        getOrdersList(queryPayload)(dispatch, getState)
        // dispatch({type: main.BOOKING_DETAIL_BY_ID, payload: response.data.data})
        // return {
        //     bookingDetail: response.data.data.data
        // }
    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const updateComments = (payload) => async (dispatch, getState) => {
    let { UPDATE_ORDER_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...UPDATE_ORDER_API,
        data: payload
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        let queryPayload = {
            filterName: null,
            filter: null,
            search: null,
            page: 1,
            limit: 20
        }
        getOrdersList(queryPayload)(dispatch, getState)

    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const createOrder = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_ORDER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_ORDER_API,
            data: payload
        }
        const response = await API(requestPayload)
        return response
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return null
    }
}

export const editOrder = (payload) => async (dispatch, getState) => {
    try {
        let { EDIT_ORDER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...EDIT_ORDER_API,
            data: payload
        }
        const response = await API(requestPayload)
        return response
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return null
    }
}