
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

export const getShopList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
        let { SHOPS_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...SHOPS_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.SHOPS_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const getShopsDropDownList = () => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
    let { SHOPS_DROPDOWN_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...SHOPS_DROPDOWN_LIST_API
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        dispatch({ type: main.SHOPS_DROPDOWN_LIST, payload: response.data })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    }
}

export const getShopsDetailsFromId = (shopId) => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_SHOP_DETAIL_FETCHING, payload: true })
    let { SHOP_DETAIL_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...SHOP_DETAIL_API,
        params: {
            shopId: shopId
        }
    }
    let response = await API(requestPayload)
    if (response.status == 200) {
        let completeShopDetail = response.data.data.data
        let formatedDetail = {
            ...completeShopDetail,
            shopLongitude: completeShopDetail?.loc?.coordinates[0],
            shopLatitude: completeShopDetail?.loc?.coordinates[1],
        }
        let data = {
            formatedDetail: formatedDetail
        }
        dispatch({ type: main.GET_SHOP_CATEGORY_PRODUCT_DETAIL, payload: data })
        dispatch({ type: loaders.IS_SHOP_DETAIL_FETCHING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const createShop = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_SHOP_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_SHOP_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const updateShop = (payload) => async (dispatch, getState) => {
    try {
        let { UPDATE_SHOP_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_SHOP_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const deleteShop = (payload) => async (dispatch, getState) => {
    try {
        let { DELETE_SHOP_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...DELETE_SHOP_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: response?.data?.message })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

// CATEGORY
export const getCategoryDropDownList = () => async (dispatch, getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
    let { GET_CATEGORY_DROPDOWN_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...GET_CATEGORY_DROPDOWN_API
    }
    let response = await API(requestPayload)
    if (response.status === 200) {
        dispatch({ type: main.CATEGORY_DROPDOWN_LIST, payload: response.data.data })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    } else {
        Toast({ type: 'error', message: response.data.message })
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
    }
}

export const getCategoryList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
        let { CATEGORY_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CATEGORY_LIST_API,
            params: queryPayload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.CATEGORY_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const createCategory = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_CATEGORY_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_CATEGORY_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const updateCategory = (payload) => async (dispatch, getState) => {
    try {
        let { UPDATE_CATEGORY_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_CATEGORY_API,
            data: payload,
            params: {
                _id: payload._id
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const toggleCategoryStatus = (payload) => async (dispatch, getState) => {
    let { categoriesList } = getState().shops

    categoriesList.list.map(sp => { if(sp._id === payload._id){ sp.status = payload.status }})

    dispatch({ type: main.CATEGORY_LIST, payload: categoriesList })

    updateCategoryStatus(payload)(dispatch, getState)

}

export const updateCategoryStatus = (payload) => async (dispatch, getState) => {
    try {
        let { UDPATE_CATEGORY_STATUS_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UDPATE_CATEGORY_STATUS_API,
            data: payload,
            params: {
                _id: payload._id
            }
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            Toast({ type: 'success', message: 'success' })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const deleteCategory = (payload) => async (dispatch, getState) => {
    try {
        let { DELETE_CATEGORY_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...DELETE_CATEGORY_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            Toast({ type: 'success', message: response?.data?.message })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

// PRODUCT
export const getProductList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_PRODUCTS_LIST_FETCHING, payload: true })
        let { PRODUCT_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...PRODUCT_LIST_API,
            params: queryPayload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.PRODUCT_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_PRODUCTS_LIST_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_PRODUCTS_LIST_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        dispatch({ type: loaders.IS_PRODUCTS_LIST_FETCHING, payload: false })

    }
}

export const getProductsDropdown = (shop_Id) => async (dispatch, getState) => {
    try {
        let { GET_PRODUCTS_DROPDOWN_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...GET_PRODUCTS_DROPDOWN_API,
            params: {
                shop_Id: shop_Id
            }
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            dispatch({ type: main.PRODUCTS_DROPDOWN_LIST, payload: response.data.data })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const toggleProductStatus = (payload) => async (dispatch, getState) => {
    let { productsList } = getState().shops

    productsList.list.map(sp => { if(sp._id === payload._id){ sp.status = payload.status }})

    dispatch({ type: main.PRODUCT_LIST, payload: productsList })

    updateProductStatus(payload)(dispatch, getState)

}

export const createProduct = (payload) => async (dispatch, getState) => {
    try {
        let { CREATE_PRODUCT_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_PRODUCT_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const updateProduct = (payload) => async (dispatch, getState) => {
    try {
        let { productsList } = getState().shops

        let { UPDATE_PRODUCT_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_PRODUCT_API,
            data: payload,
            params: {
                _id: payload._id
            }
        }
        let response = await API(requestPayload)
        if (response.status === 200) {

            const indexToUpdate = productsList.list.findIndex((item) => item._id === payload._id);
            if (indexToUpdate !== -1) {
                productsList.list[indexToUpdate] = payload
                dispatch({ type: main.PRODUCT_LIST, payload: productsList })
            }
            Toast({ type: 'success', message: "Success" })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const updateProductStatus = (payload) => async (dispatch, getState) => {
    let { UDPATE_PRODUCT_STATUS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...UDPATE_PRODUCT_STATUS_API,
        data: payload,
        params: {
            _id: payload._id
        }
    }
    let response = await API(requestPayload)
    if (response.status === 200) {
        Toast({ type: 'success', message: 'success' })
    } else {
        Toast({ type: 'error', message: response.data.message })
    }
}

export const deleteProduct = (payload) => async (dispatch, getState) => {
    try {
        let { DELETE_PRODUCT_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...DELETE_PRODUCT_API,
            data: payload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: response?.data?.message })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

// product wise profit list
export const productWiseProfitList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_PRODUCT_PROFIT_LIST_FETCHING, payload: true })
        let { PRODUCTS_PROFIT_LIST } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...PRODUCTS_PROFIT_LIST,
            params: queryPayload
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.PRODUCT_PROFIT_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_PRODUCT_PROFIT_LIST_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_PRODUCT_PROFIT_LIST_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}