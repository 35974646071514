import React from 'react';

class BackBtn extends React.Component {
    state = {}
    goToPreviousPath = (history) => {
        history.goBack()
    }
    render() {
        let {history} = this.props
        return ( 
            <div >
                <img src={require('../../assets/images/left-arrow.png')} style={{width:"16px", cursor: 'pointer'}} onClick={() => this.goToPreviousPath(history)}/>
            </div>
        );
    }
}
 
export default BackBtn;