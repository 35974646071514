import React from 'react';
import { Spinner } from 'reactstrap';

export const FormSubmissionBtns = ({ onSubmit, onCancel, submitBtnName = 'Submit', isLoading, submitBtnClassName = '', cancelBtnName = 'Cancel' }) => {
    return (
        <div className='row align-items-center mt-2 justify-flex-end'>
            <div className='col-xl-2 col-md-2 col-lg-2 col-6'>
                <div
                    className={`buttonType1 cancel_btn`}
                    onClick={() => onCancel()}
                >
                    <span className="ml-2">{cancelBtnName}</span>
                </div>
            </div>
            <div className='col-xl-2 col-md-2 col-lg-2 col-6'>
                {isLoading
                    ?
                    <div className={`buttonType1 ${submitBtnClassName} apply_filter`}>
                        <Spinner
                            size= "sm"
                            color={"#ffffff"}
                        />
                    </div>
                    :
                    <div
                        className={`buttonType1 ${submitBtnClassName} apply_filter`}
                        onClick={() => onSubmit()}
                    >
                        <span className="ml-2">{submitBtnName}</span>
                    </div>
                }
            </div>
        </div>
    );
}