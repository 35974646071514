export const ORDER = [
    {
        label: 'Last First',
        value: 0
    },
    {
        label: 'Recent First',
        value: 1
    }
]
export const CUSTOMER_TABS = [
    {
        label: "Order History",
        value: [1],
        icon: 'icon-doc-color'
    },
]
export const BOOKING_TABS = [
    {
        label: 'Order Detail',
        value: [1],
        icon: 'icon-doc-color'
    }
    // {
    //     label: 'Edit Order',
    //     value: [2],
    //     icon: 'icon-doc-color'
    // },
]
export const SHOP_TABS = [
    {
        label: 'Shop',
        value: [1],
        icon: 'icon-doc-color'
    },
    {
        label: 'Category',
        value: [2]
    },
    {
        label: 'Product',
        value: [3]
    }
]
export const ORDER_ASSIGNED_TABS = [
    {
        label: 'Open',
        value: [1],
        icon: 'icon-doc-color'
    },
    {
        label: 'Delivered',
        value: [3]
    },
]
export const EMPLOYEE_TABS = [
    {
        label: 'Employee Details',
        value: [1],
        icon: 'icon-doc-color'
    },
    {
        label: 'Login',
        value: [2]
    },
    {
        label: 'Permission',
        value: [3]
    }
]

export const GENDER_OPTIONS = [
        {
            label: 'Male',
            value: "MALE"
        },
        {
            label: 'FEMALE',
            value: "FEMALE"
        }
]

export const LOCATION = [
    {
        label: 'Create Location',
        value: [1],
    }
]

export const COUPON = [
    {
        label: 'Create Coupon',
        value: [1],
    }
]

export const customersSearchByOptions = [
    {
        label: 'Customer Id',
        value: 'userId'
    },
    {
        label: 'Customer Name',
        value: 'name'
    },
    {
        label: 'Mobile Number',
        value: 'mobileNumber'
    },
    {
        label: 'Email Id',
        value: 'emailId'
    },
    
]

export const ordersSearchByOptions = [
    {
        label: 'Order Id',
        value: 'ORDER'
    },
    {
        label: 'Customer Detail',
        value: 'CUSTOMER'
    }
]

export const PAYMENT_METHODS = [
    {
        label: 'Cash',
        value: 'CASH'
    },
    {
        label: 'Online',
        value: 'ONLINE'
    }
]
export const ORDER_STATUS_LIST = [
    { value: 'ORDER_CREATED', label: 'Order Created' },
    { value: 'CONFIRMED', label: 'Confirmed' },
    { value: 'READY_TO_DELIVER', label: 'Ready To Deliver' },
    { value: 'PICKEDUP', label: 'Pickedup' },
    { value: 'DELIVERED', label: 'Delivered' },
    { value: 'CANCELLED', label: 'Cancelled' },
]

export const PAYMENT_STATUS = [
    {
        label: 'Paid',
        value: 1
    },
    {
        label: 'Not Paid',
        value: 2
    }
]

export const ORDER_STATUS = {
    ORDER_CREATED : "ORDER_CREATED",
    CONFIRMED : "CONFIRMED",
    READY_TO_DELIVER : "READY_TO_DELIVER",
    PICKEDUP : 'PICKEDUP',
    DELIVERED : "DELIVERED",
    CANCELLED : "CANCELLED" 
}

export const TABLE_PAYMENT_STATUS =  {
    PAYMENT_PENDING:"PENDING",
    PAYMENT_SUCCESS: "SUCCESS",
    PAYMENT_FAILED:"FAILED"
}

export const TABLE_PAYMENT_METHOD = {
    "CASH" : 'CASH',
    "ONLINE" : "ONLINE"
}

export const CUSTOMER_STATUS = {
    ACTIVE: 1,
    IN_ACTIVE: 2,
    ARCHIVE: 3,
    DELETED: 4,
    SUSPEND: 5,
    UN_APPROVED: 6
}

export const ROLES = {
    1 : "ADMIN",
    2 : "SHOP"
}

export const profileMenuDropdown = [
    {
      label: 'Profile',
      link: '/my-profile',
      type: 'link',
      title: 'Profile',
      icon: 'user',
    },
  ];