import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { API, API_BOOK } from "service";
import * as ordersAction from "actions/orders";
import * as shopAction from "actions/shops";
import { BOOKING_TABS, ORDER_STATUS } from 'helpers/variables'
import BasicTab from "components/Tabs/BasicTab";
import FormInput from 'components/inputs/FormInput'
import FormLoader from 'components/loader/formLoader'
import ReactSelect from 'components/inputs/ReactSelect'
import CloseBtn from 'components/Tabs/closeBtn';
import _ from 'lodash'
import OrderDetails from './orderDetail';


class AssessmentDetails extends Component {
    state = {
        selectedBookingId: null,
        orderDetail: {
            _id: null,
            totalItems: null,
            shops: null,
            status: null,
            totalAmount: null,
            userDetail: null,
            comments: ''
        },
        disableConfirm: false,
        statusMaster: [
            {
                label: "Order created",
                value: "ORDER_CREATED"
            },
            {
                label: "Order Confirmed",
                value: "CONFIRMED"
            },
            {
                label: "Ready To Deliver",
                value: "READY_TO_DELIVER"
            },
            {
                label: "Picked Up",
                value: "PICKEDUP"
            },
            {
                label: "Delivered",
                value: "DELIVERED"
            },
            {
                label: "Cancel",
                value: "CANCELLED"
            }
        ],
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.orders.selectedBookingId !== prevState.selectedBookingId) {
            return {
                orderDetail: {
                    ...nextProps.orders.orderDetail,
                    status: prevState.statusMaster.find(stat => stat.value === nextProps.orders.orderDetail.status),
                },
                selectedBookingId: nextProps.orders.selectedBookingId,
                activeTab: 1,
            }
        }
        return null
    }
    onChange = (name, value) => {
        let { orderDetail } = this.state
        orderDetail[name] = value

        this.setState({ orderDetail })
    };

    onSubmit = async (e) => {
        e.preventDefault()
        let { orderDetail } = this.state
        let payload = {
            _id: orderDetail._id,
            status: orderDetail.status?.value
        }

        await this.props.ordersAction.updateBookingDetail(payload)
        this.props.toggle()
    }

    updateComments = async () => {
        let { orderDetail } = this.state
        let payload = {
            comments: orderDetail?.comments,
            _id: orderDetail._id
        }
        await this.props.ordersAction.updateComments(payload)
        this.props.toggle()

    }

    render() {
        let { loader } = this.props
        let { activeTab } = this.state

        return (
            <div className="staffDetails assesment-card custom-scrollbar bx-shadow bg-white">
                <CloseBtn toggle={this.props.toggle} />
                <BasicTab
                    tabs={BOOKING_TABS}
                    name="activeTab"
                    onChange={this.onChangeTabs}
                    activeTab={[activeTab]}
                />
                {
                    loader.isSelectedBookingLoading ?
                        <FormLoader />
                        :
                        <OrderDetails
                            loader = {loader}
                            {...this.state}
                            onSubmit={this.onSubmit}
                            onChange={this.onChange}
                            updateComments={this.updateComments}
                        />
                }
            </div>
        )
    }
}


const mapStateToProps = ({ orders, profile, loader, deliveryBoy, shops }) => {
    return {
        orders,
        profile,
        loader,
        deliveryBoy,
        shops
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ordersAction: bindActionCreators(ordersAction, dispatch),
        shopAction: bindActionCreators(shopAction, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentDetails);
