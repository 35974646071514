
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import { API_BOOK } from 'service/endpoints'
import { Toast } from '../helpers/Toast'

export const getAdminsList = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
        let { ADMINS_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...ADMINS_LIST_API,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.GET_ADMINS_LIST, payload: response.data.data })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const getRolesDropdownList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true })
        let { ROLES_LIST_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...ROLES_LIST_API
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            dispatch({ type: main.ROLES_LIST, payload: response.data })
        } else {
            Toast({ type: 'error', message: response.data.message })
            dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: false })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}

export const createUser = (adminDetail) => async (dispatch, getState) => {
    try {
        let { CREATE_USER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...CREATE_USER_API,
            data: adminDetail
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: response.data.message })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const updateUser = (adminDetail, _id) => async (dispatch, getState) => {
    try {
        let { UPDATE_USER_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_USER_API,
            data: adminDetail,
            params: {
                _id: _id
            }
        }
        let response = await API(requestPayload)
        if (response.status == 200) {
            Toast({ type: 'success', message: response.data.message })
            return {
                status: true
            }
        } else {
            Toast({ type: 'error', message: response.data.message })
            return {
                status: false
            }
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
        return {
            status: false
        }
    }
}

export const deleteUserById = (payload) => async (dispatch, getState) => {
    try {
        let { UPDATE_USER_STATUS_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPDATE_USER_STATUS_API,
            params: payload
        }
        let response = await API(requestPayload)
        if (response.status === 200) {
            let { adminsList } = getState().admins
            adminsList.list.map(sp => sp.status = sp._id === payload._id ? payload.status : sp.status)

            dispatch({ type: main.GET_ADMINS_LIST, payload: adminsList })
            
            Toast({ type: 'success', message: response.data.message })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
    } catch (error) {
        Toast({ type: 'error', message: error?.data?.message })
    }
}