import AuthCard from 'components/cards/AuthCard';
import { NormalButton } from 'components/inputs/Button';
import { NormalInput } from 'components/inputs/NormalInput/normalInput';
import useValidationResolver from 'hooks/useValidationResolver';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ResetPassSchema } from 'helpers/validationSchema/auth.schema';

function ForgotPasswordFunc(props) {
  const resolver = useValidationResolver(ResetPassSchema);

  const methods = useForm({
    defaultValues: { emailId: '' },
    resolver
  });

  const onSubmit = (data) => {
    // const payload = {
    //   data: Encryption.encrypt(data)
    // };

    let pathname = '/auth/set-password'
    props.history.push({
      pathname
    })

  };

  return (
    <AuthCard heading={'Forgot Password'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <NormalInput 
            bgColor 
            placeholder="Email Id"
            name="emailId"  
          />

          <NormalButton
            variant="primary"
            className="text-black fw-500 w-100 mt-4 submit-btn btn-theme"
            type="submit"
          >
            Reset
          </NormalButton>
          
        </form>
        <div className="w-100 d-flex align-items-center justify-content-center mt-4">
            <Link
              to="/auth/login"
              className="text-black text-right forgot-link"
            >
              Have an Account? Login
            </Link>
          </div>
      </FormProvider>
    </AuthCard>
  );
}

export const ForgotPassword = ForgotPasswordFunc;
