import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as customers from "actions/customer";
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { Toast } from "helpers/Toast";
import { API, API_BOOK } from "service";
import Loader from 'components/loader/loader';
import BackBtn from "components/Tabs/backBtn"
import Topbar from 'components/topbar';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';

const defaultCustomerPayload = {
    _id: '',
    name: '',
    mobileNumber: '',
    emailId: '',
    street: '',
    landmark: ''
}
class CustomerDetailClass extends Component {
    state = {
        customerDetail: {
            _id: null,
            name: null,
            mobileNumber: null,
            emailId: null,
            street: null,
            landmark: null
        },
        isEdit: false,
        errors: {},
        btnLoading: false,
        isLoading: false
    }

    componentDidMount() {
        const { _id } = this.props.match.params

        if (_id != undefined) {
            this.getCustomer(_id)
        }
    }

    getCustomer = async (_id) => {
        try {
            this.setState({ loading: true, isEdit: true })
            const { CUSTOMER_DETAIL_API } = API_BOOK.MAIN_APP
            const requestPayload = {
                ...CUSTOMER_DETAIL_API,
                params: {
                    _id: _id
                }
            }
            const response = await API(requestPayload)
            if (response.status === 200) {
                let detail = response.data.data
                const updatedDetail = {
                    _id: detail._id,
                    name: detail?.name,
                    mobileNumber: detail?.mobileNumber,
                    emailId: detail?.emailId,
                    street: detail?.addressDetail?.street,
                    landmark: detail?.addressDetail?.landmark
                }
                this.setState({ customerDetail: updatedDetail, isLoading: false })
            } else {
                Toast("error", response?.data?.message)
                this.setState({ isLoading: false })
            }
        } catch (error) {
            Toast("error", error?.data?.message)
            this.setState({ isLoading: false })
        }
    }

    onChange = (name, value) => {
        let { customerDetail, errors } = this.state
        customerDetail[name] = value
        errors[name] = undefined
        this.setState({ customerDetail: customerDetail, errors: errors })
    };

    onSubmit = async () => {
        try {
            const { isEdit, customerDetail } = this.state
            let validation = {
                ...inputValidations
            }
            const notValid = validate(customerDetail, validation);
            if (notValid) {
                this.setState({
                    errors: notValid
                })
            } else {
                this.setState({
                    btnLoading: true
                })
                let payload = {
                    name: customerDetail.name,
                    mobileNumber: customerDetail.mobileNumber,
                    emailId: customerDetail.emailId,
                    street: customerDetail.street,
                    landmark: customerDetail.landmark,
                }
                let response;
                if (isEdit) {
                    payload["_id"] = customerDetail._id

                    response = await this.props.editCustomer(payload)
                } else {

                    response = await this.props.createCustomer(payload)
                }
                if (response.status === 200) {
                    this.setState({
                        btnLoading: false,
                        customerDetail: { ...defaultCustomerPayload }
                    })
                    Toast({ type: 'success', message: response?.data?.message })
                } else {
                    Toast({ type: 'error', message: response?.data?.message })
                    this.setState({
                        btnLoading: false
                    })
                }

            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
            this.setState({
                btnLoading: false
            })
        }
    }


    render() {
        let { customerDetail, errors, btnLoading, isLoading, isEdit } = this.state
        return (
            <>
                <Topbar
                    pageName={`${isEdit ? 'Edit' : 'Create'} Customer`}
                    showBtn={false}
                />
                {isLoading
                    ?
                    <Loader className="h-100" />
                    :
                    <div className='mt-3'>
                        <Card>
                            <CardBody>
                                <Row className="no-gutters mb-4">
                                    <Col className="col-12">
                                        <Row>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Customer Name`}
                                                    name="name"
                                                    onChange={this.onChange}
                                                    value={customerDetail["name"]}
                                                    error={errors["name"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="number"
                                                    label={`Customer Mobile Number`}
                                                    name="mobileNumber"
                                                    max={10}
                                                    onChange={this.onChange}
                                                    value={customerDetail["mobileNumber"]}
                                                    error={errors["mobileNumber"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Email ID`}
                                                    name="emailId"
                                                    onChange={this.onChange}
                                                    value={customerDetail["emailId"]}
                                                    error={errors["emailId"]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Sub Locality`}
                                                    name="landmark"
                                                    onChange={this.onChange}
                                                    value={customerDetail["landmark"]}
                                                    error={errors["landmark"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="textarea"
                                                    label={`Customer Address`}
                                                    name="street"
                                                    onChange={this.onChange}
                                                    value={customerDetail['street']}
                                                    error={errors["street"]}
                                                />
                                            </Col>
                                        </Row>
                                        <FormSubmissionBtns
                                            onSubmit={this.onSubmit}
                                            onCancel={() => this.props.history.goBack()}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = ({ customers, profile, loader }) => {
    return {
        customers,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(customers, dispatch);
};

const CustomerDetail = connect(mapStateToProps, mapDispatchToProps)(CustomerDetailClass);

export { CustomerDetail }


let inputValidations = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^Customer Name can't be blank"
        }
    },
    mobileNumber: {
        presence: {
            allowEmpty: false,
            message: "^Customer Mobile Number can't be blank"
        },
        numericality: {
            onlyInteger: true
        },
    },
    landmark: {
        presence: {
            allowEmpty: false,
            message: "^landmark can't be blank"
        }
    },
    street: {
        presence: {
            allowEmpty: false,
            message: "^Customer Address can't be blank"
        }
    }
}