import React, { Component,Fragment } from 'react'
import { Container,Row, Col } from "reactstrap";
import { BasicTable } from "components/Table/BasicTable";
import ApplicationFilter from 'components/Filter/ApplicationFilter'
import Pagination from "components/pagination";
import CreateApplication from 'components/buttons/createApplicationBtn'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { axios, API, API_BOOK } from "service";
import { ORDER } from 'helpers/variables.js'
import { RevenueListTable } from 'helpers/tableDataMapper'
import * as revenue from "actions/revenue";
import { SearchWrapper } from 'components/Table/SearchWrapper';

class revenueClass extends Component {
    state = {
        search: "",
        list: [],
        applicationCount: {},
        selectedApplicationId:null,
        page: 1,
        total_pages: 1,
        sortBy: ORDER[1].value,
        limit: 10,
        searchByOptions: []
    }
    componentDidMount() {
        this.getApplicationList()
    }
    onChange = (name, value) => {
        this.setState(
            {
                [name]: value,
                page: 1,
            },
            this.getApplicationList
        );
    };
    onSelect = (name, value) => {
        this.setState(
            {
                [name]: value,
                page: 1,
            },
            this.getApplicationList
        );
    }
    nextpage = (page) => {
        this.setState({ page }, this.getApplicationList);
    };
    
    getApplicationList = () => {
        const {
            sortBy,
            search,
            page,
            limit,
        } = this.state;
        let data = {
            page,
            sortBy,
            search,
            limit
        };
        this.props.getRevenueList();
    };
    toggleChecked = (cellContent,row, rowIndex) => {
        let data = {
            shopId: row.shopId,
            isActive: !row.isActive
        }
        this.props.toggleChecked(data)
    }
   
    triggerApi = (qPayload) => {
        this.props.getRevenueList();
    }

    render() {
        let {revenue, loader} = this.props
        let state = this.state
        return (
            <Container fluid>
                <Row noGutters className="bg-white">
                    <Col md="12">
                        <div className="list_container">
                            <SearchWrapper 
                                rows = {revenue.revenueList}
                                columns = {RevenueListTable}
                                searchByOptions = {state.searchByOptions}
                                isLoading={loader.isTableDetailsFetching}
                                onClick={null}
                                triggerApi = {this.triggerApi}
                                keyField = "customerId"
                                showFilter={false}
                                showDateFilter={false}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ revenue, profile,loader }) => {
    return {
        revenue,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(revenue, dispatch);
};

const Revenue = connect(mapStateToProps, mapDispatchToProps)(revenueClass);

export {Revenue}