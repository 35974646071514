
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as customer from "actions/customer";
import BasicTab from "components/Tabs/BasicTab";
import OrderHistory from "./orderHistory"
import { CUSTOMER_TABS } from 'helpers/variables'
import FormLoader from 'components/loader/formLoader'
import BackBtn from "components/Tabs/backBtn"
import Topbar from 'components/topbar';


class CustomerHistoryClass extends Component {
    state = {
        queryPayload: {
            search: null,
            filterName: null,
            filter: null,
            page: 1,
            limit: 10
        },
        list: [],
        applicationCount: {},
        selectedApplicationId: null,
        activeTab: [1],
        loading: false,
        applicationLoading: false,
    }
    componentDidMount() {


    }
    onChange = (name, value) => {
        this.setState(
            {
                [name]: value,
            }
        );
    };

    getComponentBasedOnStatus = (activeTab) => {
        let component = ''
        switch (activeTab[0]) {
            case 1:
                component = OrderHistory
                break;
            default :
                component = OrderHistory
                break;
        
        }
        return component
    }
    render() {
        let { activeTab, loading, applicationLoading } = this.state
        let AddComponent = this.getComponentBasedOnStatus(activeTab)
        return (
            <div>
                <Topbar
                    pageName="Customer Order History"
                    showBtn={false}
                    createBtnLabel=""
                />
                <Row className="mb-2">
                    <Col>
                        <BackBtn history={this.props.history} />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col md="12">
                        <BasicTab
                            tabs={CUSTOMER_TABS}
                            name="activeTab"
                            onChange={this.onChange}
                            activeTab={activeTab}
                        />
                    </Col>
                    <Col>
                        {loading || applicationLoading ?
                            <FormLoader />
                            :
                            <AddComponent {...this.state} {...this.props} />
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = ({ customer, locations, profile, loader }) => {
    return {
        customer,
        locations,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customers: bindActionCreators(customer, dispatch)
    }
};

const CustomerHistory = connect(mapStateToProps, mapDispatchToProps)(CustomerHistoryClass);

export { CustomerHistory }