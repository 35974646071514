import { combineReducers } from 'redux'
import loader from './loader'
import profile from './profile'
import shops from './shops'
import orders from './orders'
import employees from './employees'
import deliveryBoy from './deliveryboy'
import payment from './payment'
import revenue from './revenue'
import admins from './admins'
import customers from './customers'
import notification from './notification'
import locations from './locations'
import banner from "./banner"
import coupons from "./coupons"
import dashboard from "./dashboard"

export const reducers =  combineReducers({
    loader,
    profile,
    dashboard,
    shops,
    orders,
    employees,
    deliveryBoy,
    payment,
    revenue,
    admins,
    customers,
    notification,
    locations,
    banner,
    coupons
})
