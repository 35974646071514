import { main } from '../service/actionType'

const initialState = {
    employeeList: [],
    master: {

    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.GET_EMPLOYEES_LIST:
            return {
                ...state,
                employeeList: payload.data
            }
        case main.EMPLOYEE_MASTER_DETAIL:
            return {
                ...state,
                master: payload.data
            }
        case main.GET_EMPLOYEE_DETAIL:
            return {
                ...state,
                employeeDetail: payload.data
            }
        // case main.GET_SHOP_CATEGORY_DETAIL:
        //     return {
        //         ...state,
        //         shopCategory: payload.shopCategory
        //     }
        // case main.GET_PRODUCT_DETAIL:
        //     return {
        //         ...state,
        //         shopProducts: {
        //             filters: payload.shopProducts.filters,
        //             products: payload.shopProducts.products
        //         }
        //     }
        default:
            return state
    }

}