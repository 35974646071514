import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shops from "actions/shops";
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { Toast } from "helpers/Toast";
import { API, API_BOOK } from "service";
import Uploadfile from 'components/inputs/Uploadfile'
import Loader from 'components/loader/loader';
import Topbar from 'components/topbar';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';

class AddShopsClass extends Component {
    state = {
        activeTab: [1],
        shopDetail: {
            _id: null,
            shopName: null,
            contactNumber: null,
            address: null,
            landmark: null,
            pincode: null,
            shopImage: null,
            shopOpeningTime: null,
            shopClosingTime: null,
            location: null
        },
        isUploadingImage: false,
        errors: {},
        isEdit: false,
        btnLoading: false,
        isLoading: false
    }
    componentDidMount() {
        let _id = this.props.match.params.shopId
        if (_id !== undefined) {
            this.getShopFromId(_id)
        }
    }

    getShopFromId = async (_id) => {
        try {
            let { SHOP_DETAIL_API } = API_BOOK.MAIN_APP
            this.setState({ isLoading: true })
            let requestPayload = {
                ...SHOP_DETAIL_API,
                params: {
                    _id: _id
                }
            }
            let response = await API(requestPayload)
            if (response.status === 200) {
                let completeShopDetail = response.data.data
                let formatedDetail = {
                    ...completeShopDetail,
                    shopLongitude: completeShopDetail?.location?.coordinates[0],
                    shopLatitude: completeShopDetail?.location?.coordinates[1],
                }
                this.setState({ shopDetail: formatedDetail, isLoading: false, isEdit: true })
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
            Toast({ type: 'error', message: error?.data?.message })
        }
    }
    onChange = (name, value) => {
        let { shopDetail, errors } = this.state
        shopDetail[name] = value
        errors[name] = undefined
        this.setState({ shopDetail: shopDetail, errors: errors })
    };

    onSubmit = async () => {
        const {
            shopDetail,
            isEdit
        } = this.state
        const validation = {
            ...inputValidations
        }
        const notValid = validate(shopDetail, validation);
        if (notValid) {
            this.setState({
                errors: notValid
            })
        } else {
            this.setState({
                btnLoading: true
            })
            let splittedOpeningTime = shopDetail.shopOpeningTime.split(':')
            let splittedClosingTime = shopDetail.shopClosingTime.split(':')
            const shopOpeningTimeInMinutes = parseInt(splittedOpeningTime[0]) * 60 + parseInt(splittedOpeningTime[1])
            const shopClosingTimeInMinutes = parseInt(splittedClosingTime[0]) * 60 + parseInt(splittedClosingTime[1])
            const payload = {
                _id: shopDetail._id,
                shopName: shopDetail.shopName,
                shopImage: shopDetail.shopImage,
                contactNumber: shopDetail.contactNumber,
                address: shopDetail.address,
                itemsAvailable: shopDetail.itemsAvailable,
                shopOpeningTime: shopDetail.shopOpeningTime,
                shopClosingTime: shopDetail.shopClosingTime,
                shopClosingTimeInMinutes: shopClosingTimeInMinutes,
                shopOpeningTimeInMinutes: shopOpeningTimeInMinutes,
                landmark: shopDetail.landmark,
                pincode: shopDetail.pincode,
                location: {
                    coordinates: [shopDetail.shopLongitude, shopDetail.shopLatitude],
                    type: 'Point'
                }
            }
            if (isEdit) {
                await this.props.updateShop(payload)
            } else {
                await this.props.createShop(payload)
            }
            this.setState({
                btnLoading: false
            })
        }
    }
    onUpload = async (name, files) => {
        let { errors, shopDetail } = this.state;
        errors[name] = undefined
        let formData = new FormData()
        formData.append('image', files[0])
        this.setState({ isUploadingImage: true })
        const { UPLOAD_DOC_API } = API_BOOK.MAIN_APP
        let requestPayload = {
            ...UPLOAD_DOC_API,
            data: formData,
        };
        let response = await API(requestPayload);
        if (response.status === 200) {
            let newDetail = {
                ...shopDetail,
                shopImage: response.data.data.imageUrl,
                shopImageFileName: response.data.data.imagekey
            }
            this.setState({
                shopDetail: newDetail,
                isUploadingImage: false
            })
        } else {
            Toast({ type: 'error', message: response.data.message })
        }
        this.setState({ isUploadingImage: false })
    }

    render() {
        let { shopDetail, errors, btnLoading, isUploadingImage, isLoading } = this.state
        return (
            <>
                <Topbar
                    pageName={'Shop Detail'}
                    showBtn={false}
                />
                {isLoading
                    ?
                    <Loader className="h-100" />
                    :
                    <div className='mt-3'>
                        <Card>
                            <CardBody>
                                <Row className=" no-gutters bg-white">
                                    <Col className="col-12">
                                        <Row>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Shop Name`}
                                                    name="shopName"
                                                    onChange={this.onChange}
                                                    value={shopDetail["shopName"]}
                                                    error={errors["shopName"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="mobile"
                                                    label={`Shop Mobile Number`}
                                                    name="contactNumber"
                                                    onChange={this.onChange}
                                                    value={shopDetail["contactNumber"]}
                                                    error={errors["contactNumber"]}
                                                    max={10}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="time"
                                                    label={`Shop Opening Time`}
                                                    name="shopOpeningTime"
                                                    onChange={this.onChange}
                                                    value={shopDetail['shopOpeningTime']}
                                                    error={errors["shopOpeningTime"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="time"
                                                    label={`Shop Closing Time`}
                                                    name="shopClosingTime"
                                                    onChange={this.onChange}
                                                    value={shopDetail['shopClosingTime']}
                                                    error={errors["shopClosingTime"]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="decimal"
                                                    label={`Shop Latitude`}
                                                    name="shopLatitude"
                                                    onChange={this.onChange}
                                                    value={shopDetail['shopLatitude']}
                                                    error={errors["shopLatitude"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3">
                                                <FormInput
                                                    type="decimal"
                                                    label={`Shop Longitude`}
                                                    name="shopLongitude"
                                                    onChange={this.onChange}
                                                    value={shopDetail['shopLongitude']}
                                                    error={errors["shopLongitude"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="textarea"
                                                    label={`Shop Address`}
                                                    name="address"
                                                    onChange={this.onChange}
                                                    value={shopDetail['address']}
                                                    error={errors["address"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Landmark`}
                                                    name="landmark"
                                                    onChange={this.onChange}
                                                    value={shopDetail["landmark"]}
                                                    error={errors["landmark"]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-lg-3 col-12">
                                                <FormInput
                                                    type="text"
                                                    label={`Pincode`}
                                                    name="pincode"
                                                    onChange={this.onChange}
                                                    value={shopDetail["pincode"]}
                                                    error={errors["pincode"]}
                                                />
                                            </Col>
                                            <Col className="col-lg-4 mt-4">
                                                <Uploadfile
                                                    label={"shop Image"}
                                                    id="shopImage"
                                                    name="shopImage"
                                                    uploadedFileName={""}
                                                    uploadedImageUrl={shopDetail['shopImage']}
                                                    isUploading={isUploadingImage}
                                                    onChange={this.onUpload}
                                                    isMult={false}
                                                    error={errors['shopImage']}
                                                ></Uploadfile>
                                            </Col>
                                        </Row>
                                        <FormSubmissionBtns
                                            onSubmit={this.onSubmit}
                                            isLoading={btnLoading}
                                            onCancel={() => this.props.history.goBack()}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = ({ shops, profile, loader }) => {
    return {
        shops,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(shops, dispatch);
};

const AddShop = connect(mapStateToProps, mapDispatchToProps)(AddShopsClass);

export { AddShop }


let inputValidations = {
    shopName: {
        presence: {
            allowEmpty: false,
            message: "^Shop Name can't be blank"
        }
    },
    shopImage: {
        presence: {
            allowEmpty: false,
            message: "^Image can't be blank"
        }
    },
    contactNumber: {
        presence: {
            allowEmpty: false,
            message: "^Mobile Number can't be blank"
        },
        numericality: {
            onlyInteger: true
        },
    },
    shopLatitude: {
        presence: {
            allowEmpty: false,
            message: "^Latitude can't be blank"
        }
    },
    shopLongitude: {
        presence: {
            allowEmpty: false,
            message: "^Longitude can't be blank"
        }
    },
    landmark: {
        presence: {
            allowEmpty: false,
            message: "^Landmark can't be blank"
        }
    },
    pincode: {
        presence: {
            allowEmpty: false,
            message: "^Pincode can't be blank"
        }
    },
    address: {
        presence: {
            allowEmpty: false,
            message: "^Address can't be blank"
        }
    },
    shopOpeningTime: {
        presence: {
            allowEmpty: false,
            message: "^shop Opening Time can't be blank"
        }
    },
    shopClosingTime: {
        presence: {
            allowEmpty: false,
            message: "^shop Closing Time can't be blank"
        }
    },

}