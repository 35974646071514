import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as admins from "actions/admins";
import FormInput from 'components/inputs/FormInput'
import validate from "helpers/validation";
import { API, API_BOOK } from "service";
import ReactSelect from 'components/inputs/ReactSelect'
import { history } from 'helpers';
import Topbar from 'components/topbar';
import { FormSubmissionBtns } from 'components/buttons/formSubmissionBtns';
import { Toast } from 'helpers/Toast'
import Loader from 'components/loader/loader';

class SendNotificationClass extends Component {

    state = {
        queryPayload: {
            search: null,
            startDate: null,
            endDate: null,
            page: 1,
            limit: 50
        },
    }
    async componentDidMount() {
        const _id = this.props.match.params.userMasterId
        if (_id != undefined) {
            this.getUserById(_id)
        }
    }

    getRolesList = async () => {
        try {
            const { ROLES_LIST_API } = API_BOOK.MAIN_APP
            const requestPayload = {
                ...ROLES_LIST_API
            }
            const response = await API(requestPayload)
            if (response.status == 200) {
                this.setState({
                    rolesList: response.data.data
                })
            }
        } catch (error) {
            Toast({ type: 'error', message: error?.data?.message })
        }
    }

  
    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState({ queryPayload })
    };

    onSubmit = async () => {
        
    }


    render() {
        let { queryPayload } = this.state
        return (
            <>
                <Topbar
                    pageName={`Send Notification`}
                    showBtn={false}
                    createBtnLabel="Create User"
                    onPressCreateBtn={() => null}
                />
            </>
        );
    }
}

const mapStateToProps = ({ notification, customers, loader }) => {
    return {
        notification,
        customers,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(admins, dispatch);
};

const SendNotification = connect(mapStateToProps, mapDispatchToProps)(SendNotificationClass);

export { SendNotification }