import { report } from 'service/actionType';
import { API } from 'service/api';
import { API_BOOK } from 'service/endpoints';
import { Toast } from 'helpers/Toast';
import _ from 'lodash'
import moment from 'moment';
import * as XLSX from 'xlsx';

let {
    GET_REPORTS_MASTER_LIST_API,
    DOWNLOAD_REPORT_API
} = API_BOOK.REPORTS;
let {
    IS_SELECTED_REPORT_LOADING,
} = report;

export const getReportsList = () => async (dispatch, getState) => {
    try {
        let requestPayload = {
            ...GET_REPORTS_MASTER_LIST_API,
        }
        let response = await API(requestPayload)
        console.log('response',response)

        if(response.status === 200){
            return response.data.data
        }
    } catch (error) {
        console.log('error', error)
        Toast('error', error?.response?.data?.message);
    }
};

export const downloadReport = (queryPayload) => async (dispatch, getState) => {
    try {
        dispatch({ type: IS_SELECTED_REPORT_LOADING, payload: true });
        let requestPayload = {
            ...DOWNLOAD_REPORT_API,
            url: `${DOWNLOAD_REPORT_API.url}/${queryPayload.reportType}`,
            params: {
                ...queryPayload
            }
        }
        let response = await API(requestPayload)
        const ws = XLSX.utils.json_to_sheet(response.data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        var a = window.document.createElement('a');
        var blobData = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blobData, `${queryPayload.reportType}(${moment().format('DD/MM/YYYY hh:mm')}).xlsx`);

        } else {
            a.href = window.URL.createObjectURL(blobData);

            // supply your own fileName here...
            a.download = `${queryPayload.reportType}(${moment().format('DD/MM/YYYY hh:mm')}).xlsx`;

            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }

        dispatch({ type: IS_SELECTED_REPORT_LOADING, payload: false });

    } catch (error) {
        console.log('error', error)
        Toast('error', error?.response?.data?.message);
    }
};