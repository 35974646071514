import React, { Component } from 'react'
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { API, API_BOOK } from "service";
import { NotificationTableColumns } from 'helpers/tableDataMapper'
import * as notification from "actions/notification";
import { SearchWrapper } from "components/Table/SearchWrapper";
import { Button } from 'reactstrap'
import { CreateModel } from "components/Model"
import ReactSelect from 'components/inputs/ReactSelect'
import Topbar from 'components/topbar';
import FormInput from 'components/inputs/FormInput';
import { handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';
import { TableActionbtns } from 'components/TableWrapper/tableActionBtns';
import { TableWrapper } from 'components/TableWrapper';
import ButtonType1 from 'components/buttons/ButtonType1';

const defaultPayload = {
    search: '',
    page: 1,
    limit: 50
}

class NotificationClass extends Component {
    state = {
        queryPayload: {
            search: null,
            page: 1,
            limit: 50
        },
        showDeletePopup: false,
        deleteData: null,
        selectedRow: null,
    }
    componentDidMount() {
        this.getApplicationList()
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState({
            queryPayload
        })
    };
    
    clearFilters = () => {
        this.setState({
            queryPayload: { ...defaultPayload }
        }, this.getApplicationList)
    }

    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload }, this.getApplicationList)
    };

    getApplicationList = () => {
        let { queryPayload } = this.state
        this.props.getNotificationsList(queryPayload);
    };

    deleteRow = (row) => {
        let data = {
            _id: row._id,
            status: 3
        }
        this.setState({
            showDeletePopup: true,
            deleteData: data
        })
    }
 
    triggerPopup = (row) => {
        let data = {
            _id: row._id,
            status: 2
        }
        this.setState({
            showTriggerNotificationPopup: true,
            selectedRow: data
        })
    }

    onSubmit = () => {
        let { deleteData } = this.state
        this.props.updateNotificationStatus(deleteData)
        this.setState({ showDeletePopup: false })
    }

    triggerNotification = () => {
        let { selectedRow } = this.state
        this.props.sendNotification(selectedRow)
        this.setState({ showTriggerNotificationPopup: false })
    }

    togglePopup = (popupname) => {
        this.setState({
            [popupname]: false
        })
    }

    render() {
        let { notification, loader } = this.props
        let { queryPayload, showDeletePopup, showTriggerNotificationPopup, selectedCustomer, customerDropdownList } = this.state
        return (
            <>
                <Topbar
                    pageName="Notification"
                    showBtn={true}
                    createBtnLabel="Create Notification"
                    onPressCreateBtn={() => this.props.history.push('/staff/notification/detail')}
                />
                <SearchWrapper
                    applyFilter={() => this.getApplicationList()}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-3 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div>
                    </div>
                </SearchWrapper>
                <TableWrapper
                    headerDetails={NotificationTableColumns}
                    pageMeta={notification.notificationList?.pageMeta}
                    listLength={notification.notificationList?.list || []}
                    isLoading={loader.isTableDetailsFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {notification.notificationList?.list?.map((item, index) => {
                        const { title = '', _id = "", description = "", imageUrl = '', status = '', createdAt = '' } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        notification.notificationList?.pageMeta?.page,
                                        notification.notificationList?.pageMeta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(title, '-', 'text-center')}
                                {handleTableCellData(description, '-', 'text-center')}
                                <td>
                                    <img src={imageUrl} style={{ width: '75px', height: '75px', borderRadius: 5 }} />
                                </td>
                                <td>
                                    <div className='notification_trigger_btn'>
                                        <ButtonType1
                                            onClick={() => this.triggerPopup(item)}
                                            btnName="Send"
                                            btnClassName="apply_filter"
                                            isLoading={false}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <TableActionbtns 
                                        item = {item}
                                        deleteRow = {this.deleteRow}
                                        showDelete = {false}
                                        handleSelectRow = {() => this.props.history.push(`/staff/notification/detail/${_id}`)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </TableWrapper>
                {/* delete popup */}
                <CreateModel
                    isOpen={showDeletePopup}
                    toggle={() => this.togglePopup('showDeletePopup')}
                    title={`Delete Notification`}
                    className="mx-500"
                >
                    <div>
                        <h6>Are you sure you want to delete</h6>
                        <div className='delete_btns'>
                            <Button
                                color="grey border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.togglePopup('showDeletePopup')}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.onSubmit()}
                            >
                                Confirm
                            </Button>
                        </div>

                    </div>
                </CreateModel>

                {/* trigger notification popup */}
                <CreateModel
                    isOpen={showTriggerNotificationPopup}
                    toggle={() => this.togglePopup('showTriggerNotificationPopup')}
                    title="TRIGGER NOTIFICATION"
                    className="mx-500"
                >
                    <div>
                        <h6>Are you sure you want to Send Notification</h6>
                        <div className='delete_btns'>
                            <Button
                                color="grey border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.togglePopup('showTriggerNotificationPopup')}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.triggerNotification()}
                            >
                                Confirm
                            </Button>
                        </div>

                    </div>
                </CreateModel>
            </>
        );
    }
}

const mapStateToProps = ({ notification, profile, loader }) => {
    return {
        notification,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(notification, dispatch);
};

const viewNotification = connect(mapStateToProps, mapDispatchToProps)(NotificationClass);

export { viewNotification }