import React, { Component, Fragment } from 'react'
import { Container, Row, Col, CustomInput } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ORDER, ROLES } from 'helpers/variables.js'
import { AdminsTableList, UserManagementTableColumns } from 'helpers/tableDataMapper'
import * as admins from "actions/admins";
import { SearchWrapper } from "components/Table/SearchWrapper";
import { Button, FormGroup, Input } from 'reactstrap'
import { CreateModel } from "components/Model"
import Topbar from 'components/topbar';
import FormInput from 'components/inputs/FormInput';
import { formatDate, handleTableCellData } from 'helpers';
import { getSNo } from 'helpers/utils';
import { TableActionbtns } from 'components/TableWrapper/tableActionBtns';
import { TableWrapper } from 'components/TableWrapper';

const defaultPayload = {
    search: '',
    startDate: '',
    page: 1,
    limit: 50
}

class viewAdminsClass extends Component {
    state = {
        queryPayload: {
            search: null,
            page: 1,
            limit: 100
        },
        list: [],
        applicationCount: {},
        selectedApplicationId: null,
        showDeletePopup: false,
        deleteData: null
    }

    componentDidMount() {
        this.getApplicationList()
    }

    getApplicationList = () => {
        let { queryPayload } = this.state
        this.props.getAdminsList(queryPayload);
    };

    clearFilters = () => {
        this.setState({
            queryPayload: { ...defaultPayload }
        }, this.getApplicationList)
    }

    toggleDelete = () => {
        this.setState({
            showDeletePopup: false
        })
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value
        this.setState({
            queryPayload
        })
    };


    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload }, this.getApplicationList);
    };


    toggleUserStatus = async (row) => {
        let data = {
            _id: row._id,
            status: row.status === 1 ? 2 : 1
        }
        await this.props.deleteUserById(data)
    }

    deleteRow = (row) => {
        let data = {
            _id: row._id,
            name: row.name
        }
        this.setState({
            showDeletePopup: true,
            deleteData: data
        })
    }

    onSubmit = async () => {
        let { deleteData } = this.state
        await this.props.deleteUserById(deleteData)
        this.setState({ showDeletePopup: false }, this.getApplicationList)
    }

    render() {
        let { admins, loader } = this.props
        let { showDeletePopup, queryPayload, deleteData } = this.state
        return (
            <>
                <Topbar
                    pageName="User Management"
                    showBtn={true}
                    createBtnLabel="Create User"
                    onPressCreateBtn={() => this.props.history.push("/staff/users/create")}
                />
                <SearchWrapper
                    applyFilter={() => this.getApplicationList()}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-4 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div>
                    </div>
                </SearchWrapper>
                <TableWrapper
                    headerDetails={UserManagementTableColumns}
                    pageMeta={admins?.adminsList?.pageMeta}
                    listLength={admins?.adminsList?.list || []}
                    isLoading={loader.isTableDetailsFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {admins?.adminsList?.list?.map((item, index) => {
                        const { userName = '', _id = "", mobileNumber = "", roleName = '', status = '', createdAt = '' } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        admins?.adminsList?.pageMeta?.page,
                                        admins?.adminsList?.pageMeta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(_id.slice(_id.length - 6), '-', 'text-center')}
                                {handleTableCellData(userName, '-', 'text-center')}
                                {handleTableCellData(mobileNumber, '-', 'text-center')}
                                {handleTableCellData(roleName, '-', 'text-center')}
                                <td>
                                    <CustomInput
                                        type="switch"
                                        id={_id}
                                        name="status"
                                        inline={true}
                                        label={status === 1 ? 'Active' : 'Inactive'}
                                        checked={status === 1 ? true : false}
                                        onChange={() => this.toggleUserStatus(item)}
                                    />
                                </td>
                                <td>
                                    <TableActionbtns
                                        item={item}
                                        showDelete={false}
                                        handleSelectRow={() => this.props.history.push(`/staff/users/create/${_id}`)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </TableWrapper>
                <CreateModel
                    isOpen={showDeletePopup}
                    toggle={this.toggleDelete}
                    title="DELETE USER"
                    className="mx-500"
                >
                    <div >
                        <h6>Are you sure you want to Delete {deleteData?.name}</h6>
                        <div className='delete_btns'>
                            <Button
                                color="grey border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.toggleDelete()}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.onSubmit()}
                            >
                                Confirm
                            </Button>
                        </div>

                    </div>
                </CreateModel>

            </>
        );
    }
}

const mapStateToProps = ({ admins, profile, loader }) => {
    return {
        admins,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(admins, dispatch);
};

const viewAdmins = connect(mapStateToProps, mapDispatchToProps)(viewAdminsClass);

export { viewAdmins }