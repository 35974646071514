import { main } from '../service/actionType'

const initialState = {
    shopsList: {
        list: [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        },
    },
    shopDetail: {
        shopId: null,
        shopName: null,
        shopMobileNumber: null,
        shopAddress: null,
        shopImageUrl:null,
        shopImageFileName:null,
        shopAddressLine2: null,
        itemsAvailable: null,
        shopOpeningTime: null,
        shopClosingTime:  null,
        shopLatitude: null,
        shopLongitude: null,
        
    },
    categoriesList: {
        list: [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        },
    },
    productsList: {
        list: [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        },
    },
    productWiseProfitList: {
        list: [],
        pageMeta: {
            count: 0,
            limit: 10,
            page: 1,
            totalPages: 0
        },
    },
    shopsDropdownList: [],
    categoryDropdownList: [],
    productDropdownList: []

}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.SHOPS_LIST:
            return {
                ...state,
                shopsList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.SHOPS_DROPDOWN_LIST:
            return {
                ...state,
                shopsDropdownList: payload.data
            }
        case main.CATEGORY_DROPDOWN_LIST:
            return {
                ...state,
                categoryDropdownList: payload.data
            }
        case main.CATEGORY_LIST:
            return {
                ...state,
                categoriesList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.PRODUCT_LIST:
            return {
                ...state,
                productsList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.PRODUCT_PROFIT_LIST:
            return {
                ...state,
                productWiseProfitList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        case main.PRODUCTS_DROPDOWN_LIST:
            return {
                ...state,
                productDropdownList: payload
            }
        case main.SHOP_FORM_CREATE_OR_UPDATE:
            return {
                ...state,
                shopDetail: { 
                    ...payload.formatedDetail,
                    categories: [],
                    products: []
                }
            }
        case main.SHOP_FORM_RESET:
            return {
                ...state,
                shopDetail: {
                        shopId: null,
                        shopName: null,
                        shopMobileNumber: null,
                        shopAddress: null,
                        shopAddressLine2: null,
                        itemsAvailable: null,
                        shopOpeningTime: null,
                        shopClosingTime:  null,
                        shopLatitude: null,
                        shopLongitude: null,
                        categories: [],
                        products: []
                }
            }
        default:
            return state
    }

}