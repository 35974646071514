import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, CustomInput } from 'reactstrap'

import * as shops from "actions/shops";
import { getSNo } from 'helpers/utils';
import { handleTableCellData } from 'helpers';
import { ShopsTableColumns } from 'helpers/tableDataMapper'

import { SearchWrapper } from "components/Table/SearchWrapper";
import { CreateModel } from "components/Model"
import Topbar from 'components/topbar';
import { TableWrapper } from 'components/TableWrapper';
import FormInput from 'components/inputs/FormInput';
import { TableActionbtns } from 'components/TableWrapper/tableActionBtns';

const defaultPayload = {
    search: '',
    startDate: '',
    page: 1,
    limit: 50
}

class ShopsClass extends Component {
    state = {
        queryPayload: {
            search: null,
            page: 1,
            limit: 10
        },
        showDeletePopup: false,
        deleteData: null
    }
    componentDidMount() {
        this.getApplicationList()
    }

    onChange = (name, value) => {
        let { queryPayload } = this.state
        queryPayload[name] = value

        this.setState({
            queryPayload
        })
    };

    nextpage = (page) => {
        let { queryPayload } = this.state
        queryPayload["page"] = page
        this.setState({ queryPayload }, this.getApplicationList)
    };

    getApplicationList = () => {
        const { queryPayload } = this.state
        this.props.getShopList(queryPayload);
    };

    toggleChecked = (shop) => {
        let data = {
            _id: shop._id,
            isActive: !shop.isActive
        }
        this.props.toggleShopStatus(data)
    }

    getShopOpenstatus = (row) => {
        const date = new Date()
        const minutes = date.getHours() * 60 + + date.getMinutes();
        let isShopOpen = false
        if (row.isActive === null && (minutes >= row.shopOpeningTimeInMinutes && minutes < row.shopClosingTimeInMinutes)) {
            isShopOpen = true
        } else if (row.isActive) {
            isShopOpen = true
        } else {
            isShopOpen = false
        }
        return (
            <CustomInput
                type="switch"
                id={row._id}
                name="isActive"
                inline={true}
                label={isShopOpen ? 'ON' : 'OFF'}
                checked={isShopOpen ? true : false}
                onChange={() => this.toggleChecked(row)}
            />
        )
    }

    setShopDelete = (row) => {
        const data = {
            _id: row._id,
            shopName: row.shopName
        }
        this.setState({
            showDeletePopup: true,
            deleteData: data
        })
    }


    onSubmit = () => {
        let { deleteData } = this.state
        this.props.deleteShop(deleteData)
        this.setState({ showDeletePopup: false }, this.getApplicationList)
    }

    toggleDelete = () => {
        this.setState({
            showDeletePopup: false
        })
    }

    clearFilters = () => {
        this.setState({
            queryPayload: { ...defaultPayload }
        }, this.getApplicationList)
    }

    render() {
        let { shops, loader } = this.props
        let { queryPayload, showDeletePopup, deleteData } = this.state

        return (
            <>
                <Topbar
                    pageName="Shops"
                    showBtn={true}
                    createBtnLabel="Create Shop"
                    onPressCreateBtn={() => this.props.history.push('/staff/shop/createshop')}
                />
                <SearchWrapper
                    applyFilter={() => this.getApplicationList()}
                    clearFilters={() => this.clearFilters()}
                >
                    <div className='row'>
                        <div className='col-lg-3 col-xl-3 col-md-3 col-12  mb-3'>
                            <FormInput
                                type="text"
                                name="search"
                                label='Search'
                                placeholder="Search.."
                                onChange={(...params) => this.onChange(...params)}
                                value={queryPayload["search"]}
                            />
                        </div>
                    </div>
                </SearchWrapper>
                <TableWrapper
                    headerDetails={ShopsTableColumns}
                    pageMeta={shops.shopsList?.pageMeta}
                    listLength={shops.shopsList?.list || []}
                    isLoading={loader.isTableDetailsFetching}
                    handlePageChange={this.nextpage}
                    highlightRows
                    tableClassName='mt-3'
                >
                    {shops.shopsList?.list?.map((item, index) => {
                        const { shopName = '', address = "", contactNumber = "", shopClosingTime = '', shopOpeningTime = '', _id = "", createdAt = '', shopImage = '', isActive = null } = item;
                        return (
                            <tr>
                                {handleTableCellData(
                                    getSNo(
                                        index,
                                        shops.shopsList?.meta?.page,
                                        shops.shopsList?.meta?.limit
                                    ),
                                    '-',
                                    'text-center'
                                )}
                                {handleTableCellData(_id.slice(_id.length - 6), '-', 'text-center')}
                                {handleTableCellData(shopName, '-', 'text-center')}
                                {handleTableCellData(address, '-', 'text-center')}
                                {handleTableCellData(`${shopOpeningTime} - ${shopClosingTime}`, '-', 'text-center')}
                                <td>
                                    <img src={shopImage} style={{ width: '75px', height: '75px', borderRadius: 5 }} alt='shop' />
                                </td>
                                {handleTableCellData(contactNumber, '-', 'text-center')}
                                {/* <td>
                                    {this.getShopOpenstatus(item)}
                                </td> */}
                                <td>
                                    <TableActionbtns
                                        item={item}
                                        deleteRow={this.setShopDelete}
                                        showView = {false}
                                        viewItem = {null}
                                        handleSelectRow={() => this.props.history.push(`/staff/shop/createshop/${_id}`)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </TableWrapper>
                <CreateModel
                    isOpen={showDeletePopup}
                    toggle={this.toggleDelete}
                    title={`Delete ${deleteData?.shopName}`}
                    className="mx-500"
                >
                    <div>
                        <h6>Are you sure you want to delete <strong>{deleteData?.shopName}</strong></h6>
                        <div className='delete_btns'>
                            <Button
                                color="grey border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.toggleDelete()}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary border-none"
                                className="fw-600 mt-2"
                                onClick={() => this.onSubmit()}
                            >
                                Confirm
                            </Button>
                        </div>

                    </div>
                </CreateModel>
            </>
        );
    }
}

const mapStateToProps = ({ shops, profile, loader }) => {
    return {
        shops,
        profile,
        loader
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(shops, dispatch);
};

const viewShop = connect(mapStateToProps, mapDispatchToProps)(ShopsClass);

export { viewShop }