import PropTypes from 'prop-types';

import styles from "./avatar.module.scss";

export const Avatar = ({ firstName, lastName, profileUrl, isSmall, isBig }) => {
    const firstLetterValue = firstName.toUpperCase().charCodeAt(0);
    const secondLetterValue = lastName.toUpperCase().charCodeAt(0);

    const firstLetter = firstName?.charAt(0);
    const secondLetter = lastName?.charAt(0);

    const WHITE = 0xFFFFFF;

    const floorValue = lastName ? firstLetterValue + secondLetterValue : firstLetterValue
    const hex = Math.floor((floorValue) / 1000 * WHITE);

    const backgroundColor = `#${hex.toString(16)}`;

    const smallSizeName = isSmall ? styles.Small_Avatar : "";
    const bigSizeName = isBig ? styles.Big_Avatar : "";

    const sizeClassName = smallSizeName || bigSizeName || "";

    return (
        <div className={`${styles.Avatar_Container} ${sizeClassName}`}>
            {profileUrl ? (
                <img src={profileUrl} alt={`${firstName}`} />
            ) : (
                <div style={{
                    background: backgroundColor
                }} className={styles.Avatar}>
                    {firstLetter}{secondLetter}
                </div>
            )}
        </div>
    );
};

Avatar.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profileUrl: PropTypes.string,
    isSmall: PropTypes.bool,
    isBig: PropTypes.bool
};
Avatar.defaultProps = {
    firstName: '',
    lastName: '',
    profileUrl: '',
    isSmall: false, isBig: false
};
