import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useState} from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const options = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: [ "#f1b44c","#556ee6"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },

  markers: {
    size: 3,
    strokeWidth: 3,

    hover: {
      size: 4,
      sizeOffset: 2,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
  },
}

export const OverallOrdersGraph = props => {
  const [value, setYear] = useState(new Date())
  const [revenue] = useState(props?.ordersStats?.revenue)
    
  const onChange = (value) => {
    setYear(value)
    props.onYearChange(moment(value).format('yyyy'))
  }
    return (
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Overall Orders Stats</h5>
              <div className="ml-auto">
                <div className="toolbar d-flex flex-wrap gap-2 text-end">
                  <DatePicker 
                    type="string"
                    showYearPicker={true}
                    onChange={onChange} 
                    selected={value} 
                    dateFormat="yyyy"
                    placeholderText="select year"
                  />
                </div>
              </div>
            </div>
            <div id="area-chart" dir="ltr" className="mt-2">
              <ReactApexChart
                options={options}
                series={[
                  {
                    name: "No Of Orders",
                    data: props?.ordersStats?.orders,
                  },
                  {
                    name: "Revenue",
                    data: props?.ordersStats?.revenue,
                  },
                ]}
                type="area"
                height={350}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
    )
}