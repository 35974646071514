import { main } from '../service/actionType'

const initialState = {
    notificationList: {
        list: [],
        pageMeta: {
            count: 0,
            limit: 100,
            page: 1,
            totalPages: 0
        },
    }
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case main.NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: {
                    list: payload.list,
                    pageMeta: payload.pageMeta
                }
            }
        default:
            return state
    }

}