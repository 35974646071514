
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from '../helpers/Toast'

export const getRevenueList = () => async (dispatch,getState) => {
    dispatch({ type: loaders.IS_TABLE_DETAIL_FETCHING, payload: true})
    let { REVENUE_LIST_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...REVENUE_LIST_API
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.REVENUE_LIST, payload: response.data.data })
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.IS_TABLE_DETAIL_FETCHING, payload:  false})
    }
} 



