import { Navbar } from 'react-bootstrap';
import useMediaQuery from 'hooks/useMediaQuery';
import { useState } from 'react';
import Sidebar from 'components/Sidebar/StaffSidebar'
import styles from './customnavbar.module.scss';
import { MdMenu } from 'react-icons/md';
import { ProfileDropdown } from '../dropdowns/profileDropdown';

export const CustomNavbar = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [show, setShow] = useState(false);

  const [isOpen, setOpen] = useState(true);

  const toggle = () => setOpen(!isOpen);
  const handleShow = () => setShow(!show);

  const mobileClassName = isMobile
    ? 'mobile-navbar'
    : 'ps-0 pe-4';
  return (
    <>
      <Navbar
        bg={isMobile ? 'white' : 'light'}
        variant="light"
        className={`${styles.Navbar_Contaninter} custom-navbar ${mobileClassName}`}
        sticky="top"
      >
        {isMobile && (
          <>
            <div>
              <MdMenu icon="menu" className="fs-20" onClick={handleShow} />
            </div>
            <div className={`d-flex align-items-center ml-auto ${isMobile ? `${styles.Navbar_Action_Large}` : `${styles.Navbar_Action_Small}`}`}>
              <ProfileDropdown {...props}/>
            </div>
          </>
        )}
        {show && isMobile && (
          <Sidebar
            {...props}
            toggle={toggle}
            isOpen={isOpen}
            isMobile
            handleClose={handleShow}
          />
        )}
      </Navbar>
    </>
  );
};
