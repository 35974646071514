import React from 'react';

const closeBtn = ({toggle}) => {
    return ( 
        <div className="pb-2 cursor-pointer" onClick={toggle}>
            <img src={require('../../assets/images/left-arrow.png')} style={{width:"16px"}}/>
        </div>
     );
}
 
export default closeBtn;