import * as yup from 'yup';

// const phoneRegExp = /^\d{10}$/;

export const loginSchema = yup.object({
  emailId: yup
    .string()
    // .matches(phoneRegExp, {message: "Please enter valid number.", excludeEmptyString: false})
    .email()
    .required('Valid Email is required'),
  password: yup.string().required('Password is required')
});

export const ResetPassSchema = yup.object({
  emailId: yup
    .string()
    .required('Email Id is required')
});

export const SetPasswordSchema = yup.object({
  password: yup
    .string()
    .required('Password is required'),
    confirmPassword: yup
    .string()
    .required('Confirm Password is required'),
});