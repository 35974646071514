import AuthCard from 'components/cards/AuthCard';
import { NormalButton } from 'components/inputs/Button';
import { NormalInput } from 'components/inputs/NormalInput/normalInput';
import { PasswordInput } from 'components/inputs/PasswordInput/passwordInput';
import useValidationResolver from 'hooks/useValidationResolver';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SetPasswordSchema } from 'helpers/validationSchema/auth.schema';

function SetPasswordFunc(props) {
  const resolver = useValidationResolver(SetPasswordSchema);

  const methods = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver
  });

  const onSubmit = (data) => {
    // const payload = {
    //   data: Encryption.encrypt(data)
    // };

    // console.log(payload);
    console.log('data', data);
    let pathname = '/auth/login'
    props.history.push({
      pathname
    })
  };

  return (
    <AuthCard heading={'Forgot Password'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PasswordInput label="New password" name="password" bgColor />
          <PasswordInput
            label="Confirm password"
            name="confirmPassword"
            bgColor
          />

          <NormalButton
            variant="primary"
            className="text-black fw-500 w-100 mt-3 submit-btn btn-theme"
            type="submit"
          >
            Reset Password
          </NormalButton>
          <div className="w-100 d-flex align-items-center justify-content-center mt-4">
            <Link
              to="/auth/login"
              className="text-black text-right forgot-link"
            >
              Have an Account? Login
            </Link>
          </div>
        </form>
      </FormProvider>
    </AuthCard>
  );
}

export const SetPassword = SetPasswordFunc;
