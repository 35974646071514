import React from 'react';
import { FaPlus } from 'react-icons/fa';
import {
    Row,
    Col,
    Button
  } from "reactstrap";

const createApplicationBtn = (props) => {
    const { btnName, onCreateApplicationBtnClick, disableCreateApp,  } = props
    return (
        <Row className="align-items-center no-gutters justify-content-end">
            <Col md="auto" className="d-lg-block">
                <div>
                    <Button
                        color="primary"
                        size="md"
                        className={`btn-overlay`}
                        outline
                        onClick={onCreateApplicationBtnClick}
                        disabled = {disableCreateApp}
                    >   
                        <FaPlus />
                        <span className="ml-2">{btnName}</span>
                    </Button>
                </div>
            </Col>
        </Row>
     );
}

export default createApplicationBtn;

createApplicationBtn.defaultProps = {
    onCreateApplicationBtnClick : () => {}
}