import React from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    FormGroup,
    Label
  } from "reactstrap";
import ReactSelect from 'components/inputs/ReactSelect';
import { ORDER } from 'helpers/variables.js'
import SortIcon from "assets/images/SortIcon.svg"

const ApplicationFilter = (props) => {
    const { screenName,onOrderSelect,onSearch,search,order,disableCreateApp } = props
    return (
        <Row className="align-items-center no-gutters mt-4">
            {/* APPLICATION SEARCH */}
            <Col className="col-12 col-lg-6">
                <Row className="pl-3">
                    <Col className="col-lg-6">
                        <h1 className="m-0 fw-500 text-darkGrey-2">{screenName}</h1>
                    </Col>
                </Row>
            </Col>
            <Col className="mt-3 mb-3">
                <Row className="no-gutters justify-content-even align-items-center pl-3 pr-3">
                    <Col className=" col-lg-5 col-md-4 mb-3">
                        <div className="pl-4 ">
                            <div className="search-input bg-white">
                                <i className="icon-search"></i>
                                <Input
                                    type="text"
                                    placeholder={props.searchPlaceHolder}
                                    name="search"
                                    value={search}
                                    onChange={({ target }) => onSearch(target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    { props.showFilter &&
                        <Col className="col-lg-4 col-md-4 custom-reselect">
                            <ReactSelect
                                onChange={(...params) => props.onChange(...params)}
                                options={props.options}
                                name={props.filterName}
                                value={props.value}
                                isMulti={props.isMulti}
                                isClearable={props.isClearable}
                                placeholder={"Filter"}
                            />
                        </Col>
                    }
                </Row>
            </Col>


            
        </Row>
     );
}

export default ApplicationFilter;

ApplicationFilter.defaultProps = {
    onCreateApplicationBtnClick : () => {},
    onOrderSelect : () => {},
    onSearch : () => {},
    isDetailMode : false,
    order : null
}