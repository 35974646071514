import { MdDelete, MdEdit,MdOutlineRemoveRedEye } from "react-icons/md";


export const TableActionbtns = ({viewItem, handleSelectRow, deleteRow, item, showDelete = true, showView = false}) => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            {showView &&
                <div 
                    className='table_action_ics ic_view'
                    onClick={() => viewItem(item)}
                >
                    <MdOutlineRemoveRedEye
                        size={14}
                        
                    />
                </div>
            }
            <div 
                className="table_action_ics ic_edit mx-1"
                onClick={() => handleSelectRow(item)}
            >
                <MdEdit
                    size={14}
                    
                />
            </div>
                {showDelete &&
                    <div 
                        className="table_action_ics ic_delete"
                        onClick={() => deleteRow(item)}
                    >
                        <MdDelete
                            size={14}
                            
                        />
                    </div>
                }
        </div>
    )
}