import { login, profileActionType } from '../service/actionType'

const initialState = {
    "userMasterId": null,
    "role": localStorage.getItem("role"),
    "name": null,
    "mobileNumber": null
}
export default (state = Object.assign({}, initialState), { type, payload }) => {
    switch (type) {
        case profileActionType.UPDATE_PROFILE_DETAILS:
            return {
                ...initialState,
                "userMasterId": payload.userMasterId,
                "role": localStorage.getItem("role"),
                "name": payload.name,
                "mobileNumber": payload.mobileNumber
            }
        case login.ADMIN_LOGIN:
            return {
                ...initialState,
                "userMasterId": payload.userMasterId,
                "role": localStorage.getItem("role"),
                "name": payload.name,
                "mobileNumber": payload.mobileNumber
            }
        default:
            return state
    }

}