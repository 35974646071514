
import { loaders, onboard, profileActionType } from '../service/actionType'
import { API } from '../service/api'
import {API_BOOK} from '../service/endpoints'
import {Toast} from '../helpers/Toast'

export const updateAdminProfile = () => async (dispatch,getState) => {
    dispatch({ type : loaders.IS_FETCHING_PROFILE_DETAILS,payload:true })
    let { VERIFY_TOKEN_API } = API_BOOK.ADMIN_MANAGEMENT.SESSION_API
    let role = localStorage.getItem('role')
    let requestPayload = {
        ...VERIFY_TOKEN_API,
        params: {
            role:role
        }
    }
    
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({
            type : profileActionType.UPDATE_PROFILE_DETAILS,
            payload : response.data.data.data
        })
    dispatch({ type : loaders.IS_FETCHING_PROFILE_DETAILS,payload:false })
    }else 
        Toast({ type: 'error', message: response.data.message})
}