
import { loaders, onboard, main } from 'service/actionType'
import { API } from 'service/api'
import {API_BOOK} from 'service/endpoints'
import {Toast} from '../helpers/Toast'

export const getDashboardCounts = () => async (dispatch,getState) => {
    dispatch({ type: loaders.DASHBOARD_COUNTS_FETCHING, payload: true})
    let { DASHBOARD_COUNTS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...DASHBOARD_COUNTS_API
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.DASHBOARD_COUNT, payload: response.data.data })
        dispatch({type: loaders.DASHBOARD_COUNTS_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.DASHBOARD_COUNTS_FETCHING, payload:  false})
    }
} 

export const getYearlyOrderStats = (year) => async (dispatch,getState) => {
    dispatch({ type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload: true})
    let { ORDERS_STATS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...ORDERS_STATS_API,
        params: {
            year: year
        }
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.ORDER_STATS, payload: response.data.data.graphData })
        dispatch({type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload:  false})
    }
}
 
export const getMonthlyOrderStats = (startDate) => async (dispatch,getState) => {
    dispatch({ type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload: true})
    let { MONTHLY_ORDERS_STATS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...MONTHLY_ORDERS_STATS_API,
        params: {
            startDate: startDate
        }
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.MONTHLY_ORDER_STATS, payload: response.data.data })
        dispatch({type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.DASHBOARD_ORDER_STATS_FETCHING, payload:  false})
    }
}
export const shopAnalytics = () => async (dispatch,getState) => {
    dispatch({ type: loaders.DASHBOARD_SHOP_ANALYTICS_FETCHING, payload: true})
    let { SHOP_ANALYTICS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...SHOP_ANALYTICS_API
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.SHOP_ANALYTICS, payload: response.data.data.shopanalytics })
        dispatch({type: loaders.DASHBOARD_SHOP_ANALYTICS_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.DASHBOARD_SHOP_ANALYTICS_FETCHING, payload:  false})
    }
} 
export const topCustomers = () => async (dispatch,getState) => {
    dispatch({ type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload: true})
    let { TOP_CUSTOMERS_API } = API_BOOK.MAIN_APP
    let requestPayload = {
        ...TOP_CUSTOMERS_API
    }
    let response = await API(requestPayload)
    if(response.status === 200){
        dispatch({ type: main.TOP_CUSTOMERS, payload: response.data.data.topCustomers })
        dispatch({type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload:  false})
    } else {
        Toast({ type: 'error', message: response.data.message})
        dispatch({type: loaders.DASHBOARD_TOP_CUSTOMERS_FETCHING, payload:  false})
    }
} 
