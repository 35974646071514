import React, { useState } from 'react';
import { Collapse } from 'reactstrap'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import ButtonType1 from 'components/buttons/ButtonType1';

export const ReportWrapper = ({ reportTitle, children, download }) => {
    const [col1, setCol1] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const downloadtheReport =  async () => {
        setIsLoading(true)
        await download()
        setIsLoading(false)
    }
    return (
        <>
            <div className='report_wrapper'>
                <div className='report_heading' onClick={() => setCol1(!col1)}>
                    <h5>{reportTitle}</h5>
                    <div className='search_ic'>
                        { col1 ?
                            <FaAngleUp />
                            :
                            <FaAngleDown />
                        }
                    </div>
                </div>
            </div>
            <Collapse isOpen={col1} className="accordion-collapse">
                <div className="accordion-body">
                    <div className={`filters`} >
                        {children}
                        <div className='row align-items-center mt-2'>
                            <div className='col-xl-2 col-md-3 col-lg-2 col-6'>
                                <ButtonType1
                                    onClick={() => downloadtheReport()}
                                    btnName="Download"
                                    btnClassName="apply_filter"
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </>
    )
}